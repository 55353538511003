import React from "react";
import Plyr from "plyr-react";
import "plyr-react/plyr.css";
import RawHTML from "./../../../component/RawHTML";
import AxiosClient from "../../../client/AxiosClient";
import { API_MARK_LESSON_AS_COMPLETE } from "../../../ApiEndPoints";
import { useRef } from "react";
import { useEffect } from "react";

import "./../styles/ShowLesson.style.css";
import { FilledPrimaryBtnLink } from "../../../component/FilledBtn";
import ReactPlayer from "react-player";

export default function ShowLesson({ lesson, setReloadCourseContent }) {
  let host = lesson.host.toLowerCase();
  if (host === "self") {
    return (
      <ShowVideoLesson
        video_src={lesson.video_url}
        setReloadCourseContent={setReloadCourseContent}
        {...lesson}
      />
    );
  } else if (host === "pdf") {
    return (
      <ShowPdfLesson
        pdf_src={lesson.video_url}
        setReloadCourseContent={setReloadCourseContent}
        {...lesson}
      />
    );
  } else if (host === "audio") {
    return (
      <ShowAudioLesson
        audio_src={lesson.video_url}
        setReloadCourseContent={setReloadCourseContent}
        {...lesson}
      />
    );
  } else if (host == "link" || host == "url") {
    return (
      <ShowExternalLink
        link={lesson.video_url}
        setReloadCourseContent={setReloadCourseContent}
        {...lesson}
      />
    );
  } else if (host == "youtube") {
    return (
      <ShowYoutubeVideoLesson
        video_src={lesson.video_url}
        setReloadCourseContent={setReloadCourseContent}
        {...lesson}
      />
    );
  } else {
    return <ShowQuizzLesson lesson={lesson} />;
  }
}

function ShowVideoLesson({ video_src, course_id, id, setReloadCourseContent }) {
  const markAsCompleted = () => {
    AxiosClient.post(API_MARK_LESSON_AS_COMPLETE, {
      course_id: course_id,
      lesson_id: id,
    })
      .then((response) => {
        setReloadCourseContent();
      })
      .catch((err) => {
        console.error("Getting error while marking lesson as complete", err);
      });
  };

  return (
    <div className="show-lesson">
      <ReactPlayer
        url={video_src} // Self-hosted video URL
        controls
        width="100%"
        height="auto"
        onEnded={markAsCompleted}
      />
    </div>
  );
}

function ShowPdfLesson({ pdf_src, course_id, id, setReloadCourseContent }) {
  const markAsCompleted = () => {
    AxiosClient.post(API_MARK_LESSON_AS_COMPLETE, {
      course_id: course_id,
      lesson_id: id,
    })
      .then((response) => {
        setReloadCourseContent();
      })
      .catch((err) => {
        console.error("Getting error while marking lesson as complete", err);
      });
  };

  useEffect(() => {
    markAsCompleted();
  });

  return (
    <div className="show-pdf-lesson p-3">
      <embed src={pdf_src} width="800" height="600" type="application/pdf" />
      <p>
        <a href={pdf_src} target="_blank">
          Download File
        </a>
      </p>
    </div>
  );
}

function ShowAudioLesson({ audio_src, course_id, id, setReloadCourseContent }) {
  const markAsCompleted = () => {
    AxiosClient.post(API_MARK_LESSON_AS_COMPLETE, {
      course_id: course_id,
      lesson_id: id,
    })
      .then((response) => {
        setReloadCourseContent();
      })
      .catch((err) => {
        console.error("Getting error while marking lesson as complete", err);
      });
  };

  useEffect(() => {
    markAsCompleted();
  });

  return (
    <div className="show-audio-lesson d-flex align-items-center justify-content-center p-3">
      <audio controls>
        <source src={audio_src} />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
}

function ShowExternalLink({ link, course_id, id, setReloadCourseContent }) {
  const markAsCompleted = () => {
    AxiosClient.post(API_MARK_LESSON_AS_COMPLETE, {
      course_id: course_id,
      lesson_id: id,
    })
      .then((response) => {
        setReloadCourseContent();
      })
      .catch((err) => {
        console.error("Getting error while marking lesson as complete", err);
      });
  };

  useEffect(() => {
    markAsCompleted();
  });

  return (
    <div className="show-external-link p-3 py-5 ">
      <h5>Please open below link to get this lesson contents</h5>
      <a href={link} target="_blank">
        Visit
      </a>
    </div>
  );
}

function ShowQuizzLesson({ lesson }) {
  let quizz =
    typeof lesson.quiz_for_api[0] != "undefined"
      ? lesson.quiz_for_api[0]
      : lesson.quiz_for_api;

  console.log("Quiz detials: ", lesson.completed);
  return (
    <div className="show-quizz-lesson py-5 p-3">
      <span className="bg-primary p-2 text-white rounded-1 mb-2 d-inline-block">
        Quizz
      </span>
      <h5>{quizz.title}</h5>
      <p className="lead">
        {lesson.completed == null ? (
          <>
            <strong>Instructions: </strong>
            <RawHTML
              rawHTML={`<p className="lead">${quizz.instruction} </p>`}
            ></RawHTML>
          </>
        ) : (
          <div>
            <strong>You have already taken this quizz</strong>
          </div>
        )}

        {lesson.completed == null ? (
          <FilledPrimaryBtnLink
            label={"Start the Quizz"}
            link={`/my-course/take-quizz/${lesson.course_id}/${quizz.id}`}
          />
        ) : (
          <FilledPrimaryBtnLink
            label={"Show result"}
            link={`/my-course/quizz-result/${lesson.course_id}/${lesson.completed.id}`}
            classes={`mt-3`}
          />
        )}
      </p>
    </div>
  );
}

function ShowYoutubeVideoLesson({ video_src, course_id, id }) {
  const markAsCompleted = () => {
    AxiosClient.post(API_MARK_LESSON_AS_COMPLETE, {
      course_id: course_id,
      lesson_id: id,
    })
      .then((response) => {})
      .catch((err) => {
        console.error("Getting error while marking lesson as complete", err);
      });
  };

  return (
    <div className="show-lesson">
      <ReactPlayer
        url={video_src} // Self-hosted video URL
        controls
        width="100%"
        height="500px"
        onEnded={markAsCompleted}
        config={{
          youtube: {
            playerVars: {
              modestbranding: 0, // Removes YouTube logo from controls
              rel: 0, // Disables related videos at the end
              showinfo: 0, // Hides title info (deprecated)
              fs: 1, // Allows fullscreen
            },
          },
        }}
      />
    </div>
  );
}
