import { useState } from "react";

export default function NativeLazyImage({ src = "", alt = "", classes = "" }) {
  const [imgSrc, setImgSrc] = useState(src);

  return (
    <img
      src={imgSrc}
      loading="lazy"
      className={`${classes}`}
      alt={alt}
      style={{ width: "100%" }}
      onError={() => {
        setImgSrc("/img/fallback/fallback-as-logo.png");
      }}
    />
  );
}
