import { Helmet } from "react-helmet";
import BreadCrumb from "../component/BreadCrumb";
import NativeLazyImage from "../component/NativeLazyImage";
import "./styles/Pages.style.css";

export default function UpscSyllabusPage() {
  return (
    <main className="ct-pages">
      <Helmet>
        <title>UPSC Syllabus | {process.env.REACT_APP_NAME}</title>
        <meta
          name="keywords"
          content="online law courses, online law courses in india, law courses in india, management course, law course, civil services, finance course, cyber security course, earn free certificate, online job oriented courses, best online law courses, best online law courses in india"
        />
        <meta
          name="description"
          content="Take online management and law courses from top institution. Learn about business law to advance your education and career with IALM Academy Today!"
        />
      </Helmet>

      <BreadCrumb title="UPSC Syllabus" desc="Prelims" />

      <section className="py-0 px-2">
        <div className="container">
          <section>
            <div className="row flex-column flex-column-reverse flex-md-row ">
              <div className="col-md-6 d-flex align-items-center mt-4">
                <p className="lead">
                  <h3>General Studies Paper (Prelims Paper I)</h3>
                  <ul className="list ">
                    <li className="mt-2">
                      Current events of national and international importance.
                    </li>
                    <li className="mt-2">
                      History of India and Indian National Movement.
                    </li>
                    <li className="mt-2">
                      Indian and World Geography-Physical, Social, Economic
                      Geography of India and the World.
                    </li>
                    <li className="mt-2">
                      Indian Polity and Governance – Constitution, Political
                      System, Panchayati Raj, Public Policy, Rights Issues, etc.
                    </li>
                    <li className="mt-2">
                      Economic and Social Development – Sustainable Development,
                      Poverty, Inclusion, Demographics, Social Sector
                      initiatives, etc
                    </li>
                    <li>
                      General issues on Environmental Ecology, Biodiversity, and
                      Climate Change – that do not require subject
                      specialisation.
                    </li>
                    <li>General Science</li>
                  </ul>
                </p>
              </div>
              <div className="col-md-6 d-flex align-items-center ">
                <p className="lead p-3 mt-4">
                  <h3>
                    CSAT (Civil Services Aptitude Test) Paper (Prelims Paper II)
                  </h3>
                  <ul className="list ">
                    <li className="mt-2">Comprehension</li>
                    <li className="mt-2">
                      Interpersonal skills including communication skills
                    </li>
                    <li className="mt-2">
                      Logical reasoning and analytical ability
                    </li>
                    <li className="mt-2">
                      Decision-making and problem solving
                    </li>
                    <li className="mt-2">General mental ability</li>
                    <li className="mt-2">
                      Basic numeracy (numbers and their relations, orders of
                      magnitude, etc.) (Class X level), Data interpretation
                      (charts, graphs, tables, data sufficiency, etc. – Class X
                      level)
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </section>

          <section>
            <div className="row flex-column flex-column-reverse flex-md-row ">
              <div className="col-md-12 text-center mt-4">
                <h3>Exam Pattern</h3>
              </div>
              <div className="col-md-12 ">
                <table class="table table-bordered" style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td
                        valign="top"
                        class="margin-left-1024-reset margin-right-1024-reset"
                      >
                        <b>Name of the Paper</b>
                      </td>
                      <td valign="top">
                        <b>No. of Questions</b>
                      </td>
                      <td valign="top">
                        <b>Marks Allotted</b>
                      </td>
                      <td valign="top">
                        <b>Time Allotted</b>
                      </td>
                      <td valign="top">
                        <b>Nature of Exam</b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div id="1669799843747">
                          Paper I: General Studies&nbsp;
                          <span>(Objective-type)</span>
                        </div>
                      </td>
                      <td valign="top">100</td>
                      <td valign="top">200</td>
                      <td valign="top">2 Hours</td>
                      <td>The score will be considered for Cut-off</td>
                    </tr>
                    <tr>
                      <td>
                        <div id="1669799874953">
                          Paper-II: General Studies-II (CSAT)&nbsp;
                          <span>(Objective-Type)</span>
                        </div>
                      </td>
                      <td valign="top">80</td>
                      <td valign="top">200</td>
                      <td valign="top">2 Hours</td>
                      <td>
                        Qualifying Nature- Candidates will have to score 33% to
                        qualify for CSAT.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-12 ">
                <div className="text-center mt-4">
                  <h3>Mains General Studies-I</h3>
                </div>
                <ul>
                  <li>
                    Indian culture will cover the salient aspects of Art Forms,
                    literature and Architecture from ancient to modern times.
                  </li>
                  <li>
                    Modern Indian history from about the middle of the
                    eighteenth century until the present – significant events,
                    personalities, and issues.
                  </li>
                  <li>
                    The Freedom Struggle — its various stages and important
                    contributors/contributions from different parts of the
                    country.
                  </li>
                  <li>
                    Post-independence consolidation and reorganization within
                    the country.
                  </li>
                  <li>
                    History of the world will include events from the 18th
                    century such as the industrial revolution, world wars,
                    redrawing of national boundaries, colonization,
                    decolonization, political philosophies like communism,
                    capitalism, socialism etc.— their forms and effect on
                    society.
                  </li>
                  <li>
                    Salient features of Indian Society, Diversity of India.
                  </li>
                  <li>
                    Role of women and women’s organization, population and
                    associated issues, poverty and developmental issues,
                    urbanization, their problems and their remedies.
                  </li>
                  <li>Effects of globalization on Indian society.</li>
                  <li>
                    Social empowerment, communalism, regionalism &amp;
                    secularism.
                  </li>
                  <li>Salient features of the world’s physical geography.</li>
                  <li>
                    Distribution of key natural resources across the world
                    (including South Asia and the Indian sub-continent); factors
                    responsible for the location of primary, secondary, and
                    tertiary sector industries in various parts of the world
                    (including India).
                  </li>
                  <li>
                    Important Geophysical phenomena such as earthquakes,
                    tsunamis, Volcanic activity, and cyclones. etc.,
                    geographical features and their location changes in critical
                    geographical features (including water-bodies and ice-caps)
                    and in flora and fauna and the effects of such changes.
                  </li>
                </ul>
              </div>
              <div className="col-md-12 ">
                <div className="text-center mt-4">
                  <h3>Mains General Studies-II</h3>
                </div>
                <ul>
                  <li>
                    Constitution of India —historical underpinnings, evolution,
                    features, amendments, significant provisions and basic
                    structure.
                  </li>
                  <li>
                    Functions and responsibilities of the Union and the States,
                    issues and challenges pertaining to the federal structure,
                    devolution of powers and finances up to local levels and
                    challenges therein.
                  </li>
                  <li>
                    Separation of powers between various organs disputes
                    redressal mechanisms and institutions.
                  </li>
                  <li>
                    Comparison of the Indian constitutional scheme with that of
                    other countries.
                  </li>
                  <li>
                    Parliament and State legislatures—structure, functioning,
                    the conduct of business, powers &amp; privileges and issues
                    arising out of these.
                  </li>
                  <li>
                    Structure, organization and functioning of the Executive and
                    the Judiciary—Ministries and Departments of the Government;
                    pressure groups and formal/informal associations and their
                    role in the Polity.
                  </li>
                  <li>
                    Salient features of the Representation of People’s Act.
                  </li>
                  <li>
                    Appointment to various Constitutional posts, powers,
                    functions and responsibilities of various Constitutional
                    Bodies.
                  </li>
                  <li>
                    Statutory, regulatory and various quasi-judicial bodies.
                  </li>
                  <li>
                    Government policies and interventions for development in
                    various sectors and issues arising out of their design and
                    implementation.
                  </li>
                  <li>
                    Development processes and the development industry —the role
                    of NGOs, SHGs, various groups and associations, donors,
                    charities, institutional and other stakeholders.
                  </li>
                  <li>
                    Welfare schemes for vulnerable sections of the population by
                    the Centre and States and the performance of these schemes;
                    mechanisms, laws, institutions and Bodies constituted for
                    the protection and betterment of these vulnerable sections.
                  </li>
                  <li>
                    Issues relating to the development and management of Social
                    Sector/Services relating to Health, Education, and Human
                    Resources.
                  </li>
                  <li>Issues relating to poverty and hunger.</li>
                  <li>
                    Important aspects of governance, transparency and
                    accountability, e-governance applications, models,
                    successes, limitations, and potential; citizens charters,
                    transparency &amp; accountability and institutional and
                    other measures.{" "}
                  </li>
                  <li>Role of civil services in a democracy.</li>
                  <li>India and its neighbourhood- relations.</li>
                  <li>
                    Bilateral, regional and global groupings and agreements
                    involving India and/or affecting India’s interests.
                  </li>
                  <li>
                    Effect of policies and politics of developed and developing
                    countries on India’s interests, Indian diaspora.
                  </li>
                  <li>
                    Important International institutions, agencies and fora-
                    their structure, mandate.
                  </li>
                </ul>
              </div>
              <div className="col-md-12 ">
                <div className="text-center mt-4">
                  <h3>Mains General Studies-III</h3>
                </div>
                <ul>
                  <li>
                    Indian Economy and issues relating to planning,
                    mobilization, of resources, growth, development and
                    employment.
                  </li>
                  <li>Inclusive growth and issues arising from it.</li>
                  <li>Government Budgeting.</li>
                  <li>
                    Major crop-cropping patterns in various parts of the
                    country, – different types of irrigation and irrigation
                    systems storage, transport and marketing of agricultural
                    produce and issues and related constraints; e-technology in
                    the aid of farmers.
                  </li>
                  <li>
                    Issues related to direct and indirect farm subsidies and
                    minimum support prices; Public Distribution System-
                    objectives, functioning, limitations, revamping; issues of
                    buffer stocks and food security; Technology missions;
                    economics of animal-rearing.
                  </li>
                  <li>
                    Food processing and related industries in India- scope’ and
                    significance, location, upstream and downstream
                    requirements, supply chain management.
                  </li>
                  <li>Land reforms in India.</li>
                  <li>
                    Effects of liberalization on the economy, changes in
                    industrial policy and their effects on industrial growth.{" "}
                  </li>
                  <li>
                    Infrastructure: Energy, Ports, Roads, Airports, Railways
                    etc.
                  </li>
                  <li>Investment models.</li>
                  <li>
                    Science and Technology- developments and their applications
                    and effects in everyday life.
                  </li>
                  <li>
                    Achievements of Indians in science &amp; technology;
                    indigenization of technology and development of new
                    technology.
                  </li>
                  <li>
                    Awareness in the fields of IT, Space, Computers, robotics,
                    nano-technology, bio-technology and issues relating to
                    intellectual property rights.
                  </li>
                  <li>
                    Conservation, environmental pollution and degradation,
                    environmental impact assessment.
                  </li>
                  <li>Disaster and disaster management.</li>
                  <li>Linkages between development and spread of extremism.</li>
                  <li>
                    Role of external state and non-state actors in creating
                    challenges to internal security.
                  </li>
                  <li>
                    Challenges to internal security through communication
                    networks, the role of media and social networking sites in
                    internal security challenges, basics of cyber security;
                    money laundering and its prevention.{" "}
                  </li>
                  <li>
                    Security challenges and their management in border areas –
                    linkages of organized crime with terrorism.
                  </li>
                  <li>
                    Various Security forces and agencies and their mandate.
                  </li>
                </ul>
              </div>
              <div className="col-md-12 ">
                <div className="text-center mt-4">
                  <h3>Mains General Studies-IV</h3>
                </div>
                <ul>
                  <li>
                    Ethics and Human Interface: Essence, determinants and
                    consequences of Ethics in human actions; dimensions of
                    ethics; ethics – in private and public relationships. Human
                    Values – lessons from the lives and teachings of great
                    leaders, reformers and administrators; the role of family
                    society and educational institutions in inculcating values.
                  </li>
                  <li>
                    Attitude: content, structure, function; its influence and
                    relation with thought and behaviour; moral and political
                    attitudes; social influence and persuasion.
                  </li>
                  <li>
                    Aptitude and foundational values for Civil Service,
                    integrity, impartiality and non-partisanship, objectivity,
                    dedication to public service, empathy, tolerance and
                    compassion towards the weaker sections.
                  </li>
                  <li>
                    Emotional intelligence concepts, and their utilities and
                    application in administration and governance.
                  </li>
                  <li>
                    Contributions of moral thinkers and philosophers from India
                    and the world.
                  </li>
                  <li>
                    Public/Civil service values and Ethics in Public
                    administration: Status and problems; ethical concerns and
                    dilemmas in government and private institutions; laws,
                    rules, regulations and conscience as sources of ethical
                    guidance; accountability and ethical governance;
                    strengthening of ethical and moral values in governance;
                    ethical issues in international relations and funding;
                    corporate governance.
                  </li>
                  <li>
                    Probity in Governance: Concept of public service;
                    Philosophical basis of governance and probity; Information
                    sharing and transparency in government, Right to
                    Information, Codes of Ethics, Codes of Conduct, Citizen’s
                    Charters, Work culture, Quality of service delivery,
                    Utilization of public funds, challenges of corruption.
                  </li>
                  <li>Case Studies on the above issues.</li>
                </ul>
              </div>

              <div className="col-md-12 text-center mt-4">
                <h3>Exam Pattern</h3>
              </div>
              <div className="col-md-12 ">
                <table class="table table-bordered" style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td>
                        <b>Subject</b>
                      </td>
                      <td valign="top">
                        <b>Total marks</b>
                      </td>
                      <td valign="top">
                        <b>Time Allotted</b>
                      </td>
                      <td valign="top">
                        <b>Nature of Paper</b>
                      </td>
                      <td>
                        <b>Type of Paper</b>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">General Studies I</td>
                      <td valign="top">250</td>
                      <td valign="top">3 Hours</td>
                      <td valign="top">Merit</td>
                      <td valign="top">Descriptive</td>
                    </tr>
                    <tr>
                      <td valign="top">General Studies II</td>
                      <td valign="top">250</td>
                      <td valign="top">3 Hours</td>
                      <td valign="top">Merit</td>
                      <td valign="top">Descriptive</td>
                    </tr>
                    <tr>
                      <td valign="top">General Studies III</td>
                      <td valign="top">250</td>
                      <td valign="top">3 Hours</td>
                      <td valign="top">Merit</td>
                      <td valign="top">Descriptive</td>
                    </tr>
                    <tr>
                      <td>General Studies IV</td>
                      <td valign="top">250</td>
                      <td valign="top">3 Hours</td>
                      <td valign="top">Merit</td>
                      <td>Descriptive</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-12 ">
                <div className="text-center mt-4">
                  <h3>Language and Essay Papers</h3>
                </div>
              </div>
              <ul>
                <li>
                  <b>Essay Paper – It is the Paper-I of UPSC Mains</b>
                </li>
              </ul>
              <p>
                Candidates may be required to write essays on multiple topics.
                They will be expected to keep close to the subject of the essay
                to arrange their ideas in an orderly fashion and to write
                concisely. Credit will be given for effective and exact
                expression.
              </p>
              <ul>
                <li>
                  <b>Qualifying Papers on Indian Languages and English</b>
                </li>
              </ul>
              <p>
                <b>Structure of the language papers:&nbsp;</b>The types of
                questions asked are –
              </p>
              <ol>
                <li>Essay – 100 marks</li>
                <li>Reading comprehension – 60 marks</li>
                <li>Precis Writing – 60 marks</li>
                <li>
                  Translation:
                  <ul>
                    <li>
                      English to compulsory language (e.g. Hindi) – 20 marks
                    </li>
                    <li>Compulsory language to English – 20 marks</li>
                  </ul>
                </li>
                <li>Grammar and basic language usage – 40 marks</li>
              </ol>
              <p>
                The rest of the seven papers can be written in any of the
                languages mentioned under the Eighth Schedule of the
                Constitution of India or in English. The aim of the papers is to
                test the candidates’ ability to read and understand serious
                discursive prose and to express ideas clearly and correctly, in
                the English and Indian languages concerned. The pattern of
                questions would broadly be as follows : (i) Comprehension of
                given passages. <br />
                (ii) Precis Writing.
                <br />
                (iii) Usage and Vocabulary.
                <br />
                (iv) Short Essays.
                <br />
                Indian Languages:-
                <br />
                (i) comprehension of given passages.
                <br />
                (ii) Precis Writing.
                <br />
                (iii) Usage and Vocabulary.
                <br />
                (iv) Short Essays.
                <br />
                (v) Translation from English to the Indian Language and
                vice-versa.
                <br />
              </p>
              <div className="col-md-12 text-center mt-4">
                <h3>Exam Pattern</h3>
              </div>
              <div className="col-md-12 ">
                <table class="table table-bordered" style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td valign="top">
                        <b>Subject</b>
                      </td>
                      <td valign="top">
                        <b>Total marks</b>
                      </td>
                      <td valign="top">
                        <b>Time Allotted</b>
                      </td>
                      <td valign="top">
                        <b>Nature of Paper</b>
                      </td>
                      <td valign="top">
                        <b>Type of Paper</b>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">Compulsory Indian language</td>
                      <td valign="top">300</td>
                      <td valign="top">3 Hours</td>
                      <td valign="top">Qualifying</td>
                      <td valign="top">Descriptive</td>
                    </tr>
                    <tr>
                      <td>English</td>
                      <td>300</td>
                      <td valign="top">3 Hours</td>
                      <td valign="top">Qualifying</td>
                      <td valign="top">Descriptive</td>
                    </tr>
                    <tr>
                      <td>Essay</td>
                      <td>250</td>
                      <td valign="top">3 Hours</td>
                      <td valign="top">Merit</td>
                      <td valign="top">Descriptive</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-12 text-center mt-4">
                <h3>Optional Subjects</h3>
              </div>
              <p>
                The UPSC Mains syllabus gives a list of 48 Optional Subjects
                which include Literature in different languages. Candidates need
                to choose any one of the ‘Optional Subjects’ from the list of
                subjects given below:
              </p>
              <div className="col-md-6">
                <span class="size-21">
                  Agriculture
                  <br />
                  Animal Husbandary &amp; Veterinary Science
                  <br />
                  Anthropology
                  <br />
                  Botany
                  <br />
                  Chemistry
                  <br />
                  Civil Engineering
                  <br />
                  Commerce &amp; Accountancy
                  <br />
                  Economics
                  <br />
                  Electrical Engineering
                  <br />
                  Geography
                  <br />
                  Geology
                  <br />
                  History
                  <br />
                  Law
                  <br />
                  Management
                  <br />
                  Mathematics
                  <br />
                  Mechanical Engineering
                  <br />
                  Medical Science
                  <br />
                  Philosophy
                  <br />
                  Political Science &amp; International Relations
                  <br />
                  Sociology
                  <br />
                  Statistics
                  <br />
                </span>
              </div>
              <div className="col-md-6">
                <p>
                  Literature Of Assamese Language
                  <br />
                  Literature Of Bengali Language
                  <br />
                  Literature Of English Language
                  <br />
                  Literature Of Gujarati Language
                  <br />
                  Literature Of Hindi Language
                  <br />
                  Literature Of Kannada Language
                  <br />
                  Literature Of Maithili Language
                  <br />
                  Literature Of Malayalam Language
                  <br />
                  Literature Of Manipuri Language
                  <br />
                  Literature Of Marathi Language
                  <br />
                  Literature Of Oriya Language
                  <br />
                  Literature Of Punjabi Language
                  <br />
                  Literature Of Sanskrit Language
                  <br />
                  Literature Of Sindhi(Devanagari) Language
                  <br />
                  Literature Of Tamil Language
                  <br />
                  Literature Of Telugu Language
                  <br />
                  Literature Of Urdu Language
                  <br />
                  Physics
                  <br />
                  Psychology
                  <br />
                  Public Administration
                  <br />
                  Zoology
                </p>
              </div>
              <div className="col-md-12 text-center mt-4">
                <h3>Exam Pattern</h3>
              </div>
              <div className="col-md-12 ">
                <table class="table table-bordered" style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td valign="top">
                        <b>Subject</b>
                      </td>
                      <td valign="top">
                        <b>Total marks</b>
                      </td>
                      <td valign="top">
                        <b>Time Allotted</b>
                      </td>
                      <td valign="top">
                        <b>Nature of Paper</b>
                      </td>
                      <td valign="top">
                        <b>Type of Paper</b>
                      </td>
                    </tr>
                    <tr>
                      <td>Optional I</td>
                      <td>250</td>
                      <td valign="top">3 Hours</td>
                      <td valign="top">Merit</td>
                      <td valign="top">Descriptive</td>
                    </tr>
                    <tr>
                      <td>Optional II</td>
                      <td>250</td>
                      <td valign="top">3 Hours</td>
                      <td valign="top">Merit</td>
                      <td valign="top">Descriptive</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-12 text-center mt-4">
                <h3>IAS Interview</h3>
              </div>
              <p>
                Known as Personality Test, UPSC Interview is the last stage of
                the examination. There is no defined UPSC syllabus for the
                interview. Questions are asked about wide-ranging issues.
              </p>
              <ul>
                <li>
                  Candidates who qualify for the UPSC Mains Exam will be called
                  for the ‘Personality Test/Interview’. These candidates will be
                  interviewed by a Board appointed by the UPSC.
                </li>
                <li>
                  The objective of the interview is to assess the personal
                  suitability of the candidate for a career in civil services by
                  a board of competent and unbiased observers.
                </li>
                <li>
                  The interview is more of a purposive conversation intended to
                  explore the mental qualities and analytical ability of the
                  candidate.
                </li>
                <li>
                  The Interview test will be 275 marks and the total marks for
                  the written examination is 1750. This sums up to a Grand Total
                  of 2025 Marks, based on which the final merit list will be
                  prepared.
                </li>
              </ul>
            </div>
          </section>
        </div>
      </section>
    </main>
  );
}
