import { Helmet } from "react-helmet";

import BreadCrumb from "../component/BreadCrumb";
import { useEffect, useState } from "react";
import AxiosClient from "../client/AxiosClient";
import { API_CART_ITEM_REMOVE, API_SYNC_CART } from "../ApiEndPoints";
import { useDispatch, useSelector } from "react-redux";
import DismissableAlert from "../component/DismissableAlert";
import Loader from "../component/Loader";
import NativeLazyImage from "../component/NativeLazyImage";

import "./../styles/cart_page.style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndianRupee, faXmark } from "@fortawesome/free-solid-svg-icons";
import { removeFromCart } from "../redux/slice/CartSlice";
import ApplyCouponCode from "../component/ApplyCouponCode";
import CheckoutWithRazorPay from "../component/CheckoutWithRazorPay";

export default function CartPage() {
  const [loading, setLoading] = useState(true);
  // const [cartItems, setCartItems] = useState([]);
  const [error, SetError] = useState({ alert: false, title: "", msg: "" });

  let cartCourse = useSelector((state) => state.cart);

  const dispatch = useDispatch();

  useEffect(() => {
    const courseIds = cartCourse.map((item) => {
      return item.id;
    });

    AxiosClient.post(API_SYNC_CART, { course_ids: courseIds })
      .then((response) => {
        console.log(response.data);
        if (response.data.success === true) {
        } else {
          SetError({
            alert: true,
            title: "Empty Cart!",
            msg: response.data.message,
          });
        }
      })
      .catch(() => {
        SetError({
          alert: true,
          title: "Error",
          msg: "Something went wrong! Please try again",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // useEffect(() => {
  //   console.log("error from useffect: ", error);
  // }, [error]);

  const [itemLoading, setItemLoading] = useState({});

  console.log("cart data", cartCourse);

  function removeItemFromCart(id) {
    setItemLoading({ ...itemLoading, [id]: true });

    AxiosClient.get(API_CART_ITEM_REMOVE + id)
      .then((response) => {
        if (response.data.success === true) {
          dispatch(removeFromCart(id));

          console.log("updated cart course: ", cartCourse);
          // setCartItems(cartCourse);
        } else {
          SetError({
            alert: true,
            title: "Error",
            msg: "Something went wrong! Please try again",
          });
        }
      })
      .catch((err) => {})
      .finally(() => {
        setItemLoading({ ...itemLoading, [id]: false });
      });
  }

  const [paybleAmount, setPaybleAmount] = useState(0);

  useEffect(() => {
    let tempAmount = cartCourse.map((item) => {
      return item.discountPrice === null ||
        item.discountPrice === "" ||
        item.discountPrice !== 0
        ? item.discountPrice
        : item.mrpPrice;
    }, 0);

    setPaybleAmount(tempAmount);
  }, [cartCourse]);

  return (
    <main>
      <Helmet>
        <title>
          Cart
          {process.env.REACT_APP_NAME}
        </title>
        <meta
          name="keywords"
          content="online law courses, online law courses in india, law courses in india, management course, law course, civil services, finance course, cyber security course, earn free certificate, online job oriented courses, best online law courses, best online law courses in india"
        />
        <meta
          name="description"
          content="Take online management and law courses from top institution. Learn about business law to advance your education and career with IALM Academy Today!"
        />
      </Helmet>

      <BreadCrumb
        title="Cart"
        desc="Thanks to start/enhance your career with us. Take a look at your cart and proceed to checkout"
      />

      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              {loading && (
                <div className="text-center">
                  <Loader />
                </div>
              )}

              {error.alert === true && (
                <DismissableAlert
                  varient="warning"
                  title={error.title}
                  desc={error.msg}
                  classes=""
                />
              )}

              <div className="cart-page-section">
                <h4>Total Course: {cartCourse.length}</h4>
                <hr />
                <div className="row">
                  {!loading &&
                    cartCourse.length > 0 &&
                    cartCourse.map((item, index) => {
                      // paybleAmount +=
                      //   item.discountPrice === null || item.discountPrice === ""
                      //     ? item.mrpPrice
                      //     : item.discountPrice;

                      return (
                        <div className="cart-page-item col-12 py-2 bg-light mb-2 rounded-2 mx-2 md-md-0">
                          <div className="row">
                            <div className="col-12 col-md-2">
                              <span className="_img_wrap rounded-1 overflow-hidden me-2">
                                <NativeLazyImage src={item.img} />
                              </span>
                            </div>
                            <div className="col-9 col-md-8">
                              <div className="_content">
                                <p className="lead mb-0 fw-500">{item.name}</p>
                                <p className="text-muted mb-0">
                                  Instructor name: {item.instructorName}
                                </p>
                                <p className="price mb-0">
                                  <FontAwesomeIcon
                                    icon={faIndianRupee}
                                    fontSize={15}
                                    className="me-1"
                                  />

                                  <strong className="me-1">
                                    {item.discountPrice == null ||
                                    item.discountPrice === "" ||
                                    item.discountPrice == 0
                                      ? item.mrpPrice
                                      : item.discountPrice}
                                  </strong>
                                  {item.discountPrice != null &&
                                    item.discountPrice != 0 &&
                                    item.mrpPrice != null &&
                                    item.mrpPrice != "" && (
                                      <del>{item.mrpPrice}</del>
                                    )}
                                </p>
                              </div>
                            </div>
                            <div className="col-3 col-md-2 d-flex align-items-center justify-content-center">
                              {itemLoading[item.id] == true ? (
                                <Loader />
                              ) : (
                                <button
                                  className=" btn btn-sm btn-danger"
                                  onClick={() => removeItemFromCart(item.id)}
                                >
                                  <FontAwesomeIcon
                                    icon={faXmark}
                                    color="#fffff"
                                    fontSize={18}
                                  />
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <h4 className="payble-totals">
                <span className="text-primary me-2">Total:</span>
                <FontAwesomeIcon
                  icon={faIndianRupee}
                  fontSize={25}
                  className="me-1"
                />
                {paybleAmount}
              </h4>

              <div className="row overflow-x-hidden">
                <ApplyCouponCode changeTotal={setPaybleAmount} />
              </div>

              {cartCourse.length > 0 && <CheckoutWithRazorPay />}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
