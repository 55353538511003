import { Helmet } from "react-helmet";
import BreadCrumb from "../component/BreadCrumb";
import NativeLazyImage from "../component/NativeLazyImage";
import "./styles/AboutUsPage.style.css";

export default function AboutUsPage() {
  return (
    <main>
      <Helmet>
        <title>About us | {process.env.REACT_APP_NAME}</title>
        <meta
          name="keywords"
          content="online law courses, online law courses in india, law courses in india, management course, law course, civil services, finance course, cyber security course, earn free certificate, online job oriented courses, best online law courses, best online law courses in india"
        />
        <meta
          name="description"
          content="Take online management and law courses from top institution. Learn about business law to advance your education and career with IALM Academy Today!"
        />
      </Helmet>

      <BreadCrumb
        title="About us"
        desc="Indian Academy of Law and Management (IALM) is a unit of The Indian Legal Foundation (TILF)"
      />

      <section>
        <div className="container">
          <section className="py-0">
            <div className="row">
              <div className="col-lg-6">
                <NativeLazyImage src={"/img/about/about-us.jpg"} />
              </div>
              <div className="col-lg-6 d-flex align-items-center px-2 px-lg-5">
                <p className="lead bg-white left-overlap p-3 p-md-5 border border-primary border-3 shadow">
                  <h3>About us</h3>
                  Indian Academy of Law and Management (“IALM”) is a unit of The
                  Indian Legal Foundation (“TILF”), a not-for-profit and a
                  non-Governmental Organization in the year 2012. Since it’s
                  incorporation, the focus of IALM has been to provide for
                  quality online courses, webinars and workshops that enhance
                  career opportunities, skill development and knowledge to
                  supplement academic studies. IALM also provides it’s online
                  teaching community with opportunities to exhibit their
                  specialities and reach out globally to the student community.
                </p>
              </div>
            </div>
          </section>

          <section>
            <div className="row flex-column flex-column-reverse flex-lg-row ">
              <div className="col-lg-6 d-flex align-items-center ">
                <p className="lead p-3">
                  <h3>Out Student - Our Pride</h3>
                  Our impressive students’ list comprises of Corporate Heads,
                  CXOs, Directors, General Counsels, Professionals, Government
                  Employees, R&D workforce, Entrepreneurs, Institutional,
                  College & School Students, Defence Officers. Our students are
                  required to run and manage the modern-day Corporations,
                  Government and Administration of Justice. Today, IALM can
                  proudly claim to have touched every organization and
                  institution in a positive manner. Our students get better
                  placed and they have more opportunities to work with
                  Multinational Corporations, Government Departments, Public
                  Sector Units, Law Firms, Indian Business Houses, Start-Ups and
                  R&D Organizations.
                </p>
              </div>

              <div className="col-lg-6 d-flex align-items-center">
                <NativeLazyImage
                  src={"/img/about/our-student-our-pride.jpg"}
                  classes=""
                />
              </div>
            </div>
          </section>

          <section className="py-0">
            <div className="row">
              <div className="col-lg-6">
                <NativeLazyImage src={"/img/about/faculty.jpg"} />
              </div>
              <div className="col-lg-6 d-flex align-items-center px-2 px-lg-5">
                <p className="lead bg-white left-overlap p-3 p-md-5 border border-primary border-3 shadow">
                  <h3>Learn From The World Class Faculty</h3>
                  IALM is proud of its faculty who bring varied and global
                  experiences to its platform. Many teachers are drawn from the
                  industry and/ or practice independently. They not only teach
                  you about Academics but also something that is required by
                  industry and businesses for being a global workforce.
                </p>
              </div>
            </div>
          </section>
        </div>
      </section>
    </main>
  );
}
