export const API_REGISTER = "/signup";
export const API_EMAIL_VERIFICATION = "/email-verification";

export const API_LOGIN = "/login";
export const API_REGISTER_FACULTY = "/faculty/register";
export const API_POPULAR_COURSES = "/get-popular-courses";
export const API_RECOMMENDED_COURSES = "/get-recommended-courses";
export const API_ALL_CATEGORIES = "/get-all-categories";
export const API_SUBSCRIBE_NEWSLETTER = "/newsletter-subcribe";
export const API_TESTIMONIALS = "/get-testimonials";
export const API_SPONSORS = "/get-sponsors";
export const API_SEARCH_COURSE = "/search-course";
export const API_ALL_COURSES_WITH_CATEGORIES = "/get-categories-with-courses";
export const API_GET_COURSE = "/get-course/";
export const API_ALL_INSTRUCTORS = "/get-instructors";
export const API_GET_INSTRUCTOR = "/get-instructor/";
export const API_GET_CATEGORY = "/get-category/";
export const API_GET_CATEGORY_COURSES = "/category/";
export const API_SYNC_CART = "/sync-cart";
export const API_CART_ITEM_REMOVE = "/cart/remove-item/";
export const API_APPLY_COUPON = "/apply-coupon";
export const API_INIT_ORDER = "/init-order";
export const API_CONFIRM_ORDER = "/confirm-order/";
export const API_COMPLETED_COURSE = "/my-courses?type=completed";
export const API_INCOMPLETED_COURSE = "/my-courses?type=incompleted";

export const API_FOOTER_QUICK_LINKS = "/get-footer-links";
export const API_SECTION_COURSES = "/get-section-courses";
export const API_INSTRUCTOR_FOR_HOME = "/get-instructors-for-home";

// reset password or password recover
export const API_PASSWORD_RESET_OTP = "/send-otp";
export const API_PASSWORD_RESET = "/reset";

// wishlist
export const API_WISHLISTED_COURSE = "/wishlist";
export const API_ADD_TO_WISHLIST = "/wishlist/add";
export const API_REMOVE_FROM_WISHLIST = "/wishlist/remove";

// Course
export const API_WATCH_COURESE = "/watch-course/";
export const API_GET_LESSON = "/get-lesson/";
export const API_MARK_LESSON_AS_COMPLETE = "/lesson-complete";
export const API_GET_MODULES = "/get-modules/";
export const API_GET_META_DATA = "/watch-course/meta-data";

// course quizz
export const API_GET_QUIZZ_TO_TAKE = "/get-quizz/";
export const API_INIT_QUIZZ = "/quiz-start/";
export const API_SAVE_SINGLE_QUIZZ_ANS = "/quiz-single-submit";
export const API_FINAL_SUBMIT_QUIZZ = "/quiz-final-submit";
export const API_GET_QUIZZ_RESULT = "/quiz-test-result/";

// blog
export const API_GET_BLOG_CATEGORIES = "/get-blog-categories";
export const API_GET_RECENT_BLOGS = "/get-recent-blogs";
export const API_GET_CATEGORY_BLOGS = "/get-category-blog/";
export const API_GET_SINGLE_BLOG = "/get-blog/";

export const API_LOGIN_HISTORY = "login-devices";
export const API_PURCHASES = "my-purchases";

// profile management
export const API_GET_PROFILE = "/user";
export const API_UPDATE_PROFILE = "/update-profile";
export const API_CHANGE_PASSWORD = "/change-password";

export const API_WRITE_REVIEW = "/submit-review";

export const API_GENERATE_CERTIFICATE = "/get-course-certificate/";
