import React, { useEffect, useState } from "react";
import {
  API_COMPLETED_COURSE,
  API_GENERATE_CERTIFICATE,
} from "../../../ApiEndPoints";
import AxiosClient from "../../../client/AxiosClient";
import Loader from "../../../component/Loader";
import { FilledPrimaryBtn } from "../../../component/FilledBtn";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function CompletedCoursesTable() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [certificateDownloadsBtn, setCertificateDownloadsBtn] = useState([]);

  const [certificateResponse, setCertificateResponse] = useState(null);

  useEffect(() => {
    AxiosClient.get(API_COMPLETED_COURSE)
      .then((response) => {
        if (response.data.success == true) {
          setData(response.data.data);

          let btns = [].fill(0, 0, response.data.data.length);
          setCertificateDownloadsBtn(btns);
        }
      })
      .catch((err) => {
        console.error("Error while getting completed courses: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  function downloadCertificate(course_id, btnIndex) {
    let temp = [...certificateDownloadsBtn];
    temp[btnIndex] = 1;
    setCertificateDownloadsBtn(temp);

    AxiosClient.get(`${API_GENERATE_CERTIFICATE}${course_id}`)
      .then((response) => {
        const certificateData = response.data;

        setCertificateResponse(certificateData);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        let temp = [...certificateDownloadsBtn];
        temp[btnIndex] = 0;
        setCertificateDownloadsBtn(temp);
      });
  }

  return (
    <div className="completed-courses card rounded-1">
      <div className="card-header bg-primary text-white">
        <h5 className="card-title mb-0">Completed Courses</h5>
      </div>
      <div className="card-body table-responsive">
        <table className="table table-striped w-100">
          <thead>
            <tr>
              <th>Course No.</th>
              <th>Course Title</th>
              <th>Faculty</th>
              <th>Duration</th>
              <th>Certification</th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td className="text-center" colSpan="5">
                  <Loader />
                </td>
              </tr>
            )}

            {!loading &&
              data.map((item, index) => {
                return (
                  <tr>
                    <td>{item.unique_id}</td>
                    <td>{item.title.en}</td>
                    <td>{item.user.name}</td>
                    <td>{item.access_limit} days</td>
                    <td>
                      <FilledPrimaryBtn
                        label={"Download Certificate"}
                        clickHandler={() => {
                          downloadCertificate(item.id, index);
                        }}
                        isLoading={certificateDownloadsBtn[index]}
                        classes={"btn-sm white-space-nowrap"}
                      />
                    </td>
                  </tr>
                );
              })}

            {!loading && data.length === 0 && (
              <tr>
                <td className="text-center" colSpan="5">
                  No course found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {certificateResponse != null && (
        <CertificateResponseModel
          show={certificateResponse != null ? true : false}
          onHide={() => {
            setCertificateResponse(null);
          }}
          response={certificateResponse}
        />
      )}
    </div>
  );
}

function CertificateResponseModel({ show, onHide, response }) {
  const navigateTo = useNavigate();

  return (
    <Modal className="login-modal" show={show} onHide={onHide} centered>
      <Modal.Header className="border-0 pt-4 text-center" closeButton>
        <Modal.Title className="w-100">Download Certificate</Modal.Title>
      </Modal.Header>

      <Modal.Body className="py-4">
        {response.success == true ? (
          <>
            <h4 className="mb-2 text-center">
              Hey, Congrats for your certification 🎉
            </h4>
            <p className="mb-2 text-center">
              Please click below link to download the certificate
            </p>
          </>
        ) : (
          <>
            <h3 className="mb-2 text-center">Incomplete course</h3>
            <p className="mb-2 text-center">{response.message}</p>
          </>
        )}

        <div className="d-flex justify-content-center">
          <FilledPrimaryBtn
            label={response.success ? "Download Certificate" : "Back to course"}
            clickHandler={() => {
              if (response.success) {
                window.location.href = response.download_url;
              } else {
                navigateTo("/dashboard");
              }
            }}
            classes={`mt-4`}
          />
        </div>
      </Modal.Body>
      {/* <Modal.Footer className="pb-4">
    <Button variant="secondary" onClick={handleClose}>
      Close
    </Button>
    <Button variant="primary" onClick={handleClose}>
      Save Changes
    </Button>
  </Modal.Footer> */}
    </Modal>
  );
}
