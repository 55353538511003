import NativeLazyImage from "../component/NativeLazyImage";
import "./../styles/SampleCertificate.style.css";

export default function SampleCertificate() {
  return (
    <section className="bg-light sample-certificate-sec">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-6 d-flex align-items-center">
            <div className="title">
              <h2>
                Earn A{" "}
                <span className="text-primary">Prestigious Certification</span>{" "}
                from
                <span className="strong fw-500">
                  {" "}
                  {process.env.REACT_APP_ABBR_NAME}
                </span>
              </h2>

              <p>
                Advance your legal career with a professional certification from
                IALM Academy. Our industry-recognized certificates validate your
                expertise, enhance your credibility, and open doors to new
                opportunities in the legal and management sectors. Whether
                you’re a student, legal professional, or corporate executive,
                our certifications help you stay ahead in a competitive world.
              </p>
              <p>
                Upon successful course completion, you’ll receive a prestigious
                certificate, showcasing your commitment to excellence. Stand out
                in the legal industry and take your career to the next level
                with IALM Academy.
              </p>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 ">
            <a href="/img/sample-certificate.jpg" target="_blank">
              <NativeLazyImage
                alt="certificate sample"
                src="/img/sample-certificate.jpg"
                classes="certificate-img shadow-lg"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
