import React from "react";
import BlogCard from "../component/BlogCard";
import UnderlineTitle from "../component/UnderlineTitle";

export default function BlogGridSection({ blogs, title = "" }) {
  console.log("blogs data: ", blogs);
  return (
    <div className="blog-grid-section">
      <div className="mb-3">
        <UnderlineTitle title={title} />
      </div>

      <div className="row">
        {blogs.map((item, index) => {
          return (
            <div className="col-md-6 col-lg-4">
              <BlogCard
                date={item.authored_date}
                excerpt={""}
                instructor={item.user.first_name}
                link={`/blogs/${item.slug}`}
                thumbnail={item.thumbnail}
                title={item.title}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
