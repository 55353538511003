import { Helmet } from "react-helmet";
import BreadCrumb from "../component/BreadCrumb";
import NativeLazyImage from "../component/NativeLazyImage";
import "./styles/AboutUsPage.style.css";
import HorizontalIconBox from "../component/HorizontalIconBox";

export default function ContactUsPage() {
  return (
    <main>
      <Helmet>
        <title>Contact Us | {process.env.REACT_APP_NAME}</title>
        <meta
          name="keywords"
          content="online law courses, online law courses in india, law courses in india, management course, law course, civil services, finance course, cyber security course, earn free certificate, online job oriented courses, best online law courses, best online law courses in india"
        />
        <meta
          name="description"
          content="Take online management and law courses from top institution. Learn about business law to advance your education and career with IALM Academy Today!"
        />
      </Helmet>

      <BreadCrumb
        title="Contact Us"
        desc="Facing any issue? contact to our support"
      />

      <section class=" d-flex align-items-center py-5">
        <div class="container">
          <div class="row">
            <div class="col-md-6 px-lg-5 d-flex align-items-center">
              <h2 class="h1 fw-500">Learner Support</h2>
            </div>
            <div class="col-md-6 px-lg-5">
              <h4 class="mb-4">
                If you are a learner and need help, please contact our Learner
                Support team.
              </h4>

              <a
                href="tel:18002586884"
                class="mt-4 text-decoration-none d-block"
              >
                Call us at: 1800-258-6884 (Toll-Free)
              </a>

              <a
                href="mailto:registrar@ialm.academy"
                class="mt-4 text-decoration-none d-block"
              >
                Mail us at: registrar@ialm.academy
              </a>

              <a href="/terms" class="mt-4 text-decoration-none d-block">
                Terms of use
              </a>
              <a
                href="/privacy-policy"
                class="mt-4 text-decoration-none d-block"
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </section>

      <section class="bg-lightgrey py-5 d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-md-6 px-lg-5 d-flex align-items-center">
              <div>
                <h2 class="h1 fw-500">Contact us</h2>
              </div>
            </div>
            <div class="col-md-6 d-flex align-items-center justify-content-center px-lg-5">
              <div className="row">
                <div className="col-12 mb-2">
                  <HorizontalIconBox
                    iconSrc={`/icons/location.svg`}
                    title={"Address"}
                    desc={`81, National Park, Lajpat Nagar-IV, New Delhi, Delhi 110024, India`}
                  />
                </div>
                <div className="col-12 mb-2">
                  <HorizontalIconBox
                    iconSrc={`/icons/email.svg`}
                    title={"Email"}
                    desc={`registrar@ialm.academy`}
                  />
                </div>
                <div className="col-12">
                  <HorizontalIconBox
                    iconSrc={`/icons/call.svg`}
                    title={"Contact"}
                    desc={`+91 7840 060 043`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="py-5 d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-md-6 d-flex align-items-center px-lg-5">
              <h2 class="h1 fw-500">Refund Policy</h2>
            </div>
            <div class="col-md-6 d-flex align-items-center justify-content-center px-lg-5">
              <p class="lead fw-500">
                If you have questions about our refund policy. Please check{" "}
                <a href="/refund-policy" target="_blank">
                  this
                </a>{" "}
                link or you may directly contact to us on learner suppport.
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
