import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import DismissableAlert from "../../../component/DismissableAlert";
import { SERVER_ERR_MSG } from "./../../../DefaultMsg";
import AxiosClient from "../../../client/AxiosClient";
import { API_LOGIN, API_WRITE_REVIEW } from "../../../ApiEndPoints";
import Loader from "../../../component/Loader";
import { Rating } from "react-simple-star-rating";

export default function WriteReviewModel(props) {
  const [formValues, setFormValues] = useState({
    review: "",
    rating: 0,
    course_id: props.course_id,
  });

  const [formErrors, setFormErrors] = useState({
    review: "",
    rating: "",
  });

  const [showLoader, setShowLoader] = useState(false);
  const [showAlert, setshowAlert] = useState({
    show: false,
    type: "",
    title: "",
    desc: "",
  });

  const [rating, setRating] = useState(0);

  // Catch Rating value
  const handleRating = (rate) => {
    setFormValues({ ...formValues, rating: rate });

    // other logic
  };

  function handleSubmit(event) {
    event.preventDefault();

    setShowLoader(true);

    AxiosClient.post(API_WRITE_REVIEW, formValues)
      .then((response) => {
        const { data: responseData } = response;

        if (responseData.success === true) {
          setFormErrors({
            rating: "",
            review: "",
          });

          setshowAlert({
            show: true,
            type: "success",
            title: "",
            desc: responseData.message,
          });

          props.triggerReloadReview();

          setTimeout(() => {
            props.onHide();
          }, 2000);
        } else {
          setFormErrors({
            rating:
              responseData.errors?.rating != null
                ? responseData.errors.rating
                : "",

            review:
              responseData.errors?.review != null
                ? responseData.errors.review
                : "",
          });

          setshowAlert({
            show: true,
            type: "danger",
            title: "",
            desc: responseData.message,
          });
        }
      })
      .catch((err) => {
        console.error(err);

        setshowAlert({
          show: true,
          type: "danger",
          title: "",
          desc: SERVER_ERR_MSG,
        });

        setShowLoader(false);
      })
      .finally(() => {
        setShowLoader(false);
      });

    console.log({ ...formValues });
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Write review
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group
            className="mb-3 text-center"
            controlId="exampleForm.ControlInput1"
          >
            <Rating onClick={handleRating} allowFraction={false} />
            <span className="validation-err text-danger">
              {formErrors.rating}
            </span>
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>
              Review <span class="text-danger">*</span>
            </Form.Label>

            <Form.Control
              as="textarea"
              rows={12}
              style={{ height: "100px" }}
              defaultValue={formValues.review}
              onChange={(event) => {
                setFormValues({
                  ...formValues,
                  review: event.target.value,
                });
              }}
            />

            {/*  <Form.Control
              type="text"
              placeholder=""
              value={formValues.email_or_phone}
            />
            */}
            <span className="validation-err text-danger">
              {formErrors.review}
            </span>
          </Form.Group>

          {showAlert.show === true && (
            <DismissableAlert
              varient={showAlert.type}
              title={showAlert.title}
              desc={showAlert.desc}
            />
          )}

          <Button
            type="submit"
            className="btn btn-primary theme-btn bg-primary d-block w-100 mt-4"
            disabled={showLoader}
          >
            <span className="me-2">Submit review</span>
            {showLoader && <Loader classes="text-white" />}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
