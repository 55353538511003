import React from "react";
import ImageCard from "./ImageCard";
import { useState } from "react";
import { useEffect } from "react";
import AxiosClient from "../client/AxiosClient";
import { API_INSTRUCTOR_FOR_HOME } from "../ApiEndPoints";
import Loader from "./Loader";
import { Link } from "react-router-dom";

export default function Faculties() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AxiosClient.get(API_INSTRUCTOR_FOR_HOME)
      .then((response) => {
        setData(response.data.data);
      })
      .catch((err) => {
        console.error("Error while fetching faculties: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <section className="bg-lightaccent">
      <div className="container">
        <h2 className="sec-title text-center">Our Experienced Teachers</h2>
        <p className="sec-short-desc text-center">
          Learn from highly skilled and experienced teachers dedicated to your
          success.
        </p>

        <div className="row">
          {loading && (
            <div className="col-12 text-center">
              <Loader classes="text-primary" />
            </div>
          )}

          {!loading &&
            data.map((row, index) => {
              return (
                <div
                  className="col-md-6 col-lg-4 px-4 mb-3"
                  key={`faculties-${index}`}
                >
                  <ImageCard
                    thumbnail={row.image}
                    title={row.instructor_name}
                    institute={row.job_title == null ? "-" : row.job_title}
                    link={`/faculties/${row.username}`}
                  />
                </div>
              );
            })}

          <div className="col-12 text-center mt-3">
            <Link to="/faculties/" className="btn btn-primary theme-btn">
              View All Teachers
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
