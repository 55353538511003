import React from "react";
import NativeLazyImage from "./NativeLazyImage";
import "./../styles/HorizontalIconBox.style.css";

export default function HorizontalIconBox({ iconSrc, title, desc }) {
  return (
    <div className="d-flex align-items-center hr-iconbox bg-white rounded-2 p-3">
      <span className="_icon px-2 pe-3">
        <NativeLazyImage src={iconSrc} />
      </span>
      <div>
        <h4>{title}</h4>
        <p className="mb-0 lead">{desc}</p>
      </div>
    </div>
  );
}
