import { Helmet } from "react-helmet";
import BreadCrumb from "../component/BreadCrumb";
import NativeLazyImage from "../component/NativeLazyImage";
import "./styles/Pages.style.css";

export default function IalmhonouredPage() {
  return (
    <main className="ct-pages">
      <Helmet>
        <title>UPSC Article | {process.env.REACT_APP_NAME}</title>
        <meta
          name="keywords"
          content="online law courses, online law courses in india, law courses in india, management course, law course, civil services, finance course, cyber security course, earn free certificate, online job oriented courses, best online law courses, best online law courses in india"
        />
        <meta
          name="description"
          content="Take online management and law courses from top institution. Learn about business law to advance your education and career with IALM Academy Today!"
        />
      </Helmet>

      <BreadCrumb
        title="IALM is honoured with National Pride Educational Award 2024"
        desc=""
      />

      <section>
        <div className="">
          <section style={{ background: "#F7F7F7" }}>
            <div className="container">
              <div className="row flex-column flex-column-reverse flex-md-row ">
                <div className="col-md-6 d-flex align-items-center p-15">
                  <img
                    src={
                      "/img/staticpages/9bZjWwhatsappimage20240119at11.07.1676ff6cce.jpg"
                    }
                    classes=""
                    style={{
                      float: "left",
                      border: "30px solid rgb(169, 34, 34)",
                      borderRadius: "20px",
                      overflow: "hidden",
                    }}
                    alt=""
                  />
                  ;
                </div>
                <div className="col-md-6 d-flex align-items-center ">
                  <p
                    className="p-4"
                    style={{
                      fontSize: "1.13rem",
                      lineHeight: "3em !important",
                      fontWeight: "300",
                      letterSpacing: "0px",
                      textTransform: "none",
                      fontstyle: "normal",
                    }}
                  >
                    <i class="size-18">
                      Ms Prerna Rawat, Vice President of the Indian Academy of
                      Law and Management (IALM), went on stage to receive the
                      prestigious National Pride Educational Award 2024 in the
                      category of BEST ONLINE E-LEARNING PLATFORM. The coveted
                      awards were distributed by Mr&nbsp;
                      <i class="size-18">Karan Singh Grover</i>, an Indian model
                      and actor. This is an incredible recognition for our
                      Students, Faculty and IT team that brings out excellent
                      content on highly adaptable IT platforms.
                    </i>
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="row flex-column flex-column-reverse flex-md-row ">
                <div className="col-md-6 d-flex align-items-center">
                  <NativeLazyImage
                    src={"/img/staticpages/693bctycoonaward.jpg"}
                    classes=""
                  />
                </div>
                <div className="col-md-6 d-flex align-items-center">
                  <NativeLazyImage
                    src={"/img/staticpages/SRMBSialmnewaward.jpg"}
                    classes=""
                  />
                </div>
                <div className="col-md-12 d-flex align-items-center ">
                  <p className="lead p-3" style={{ fontWeight: "bold" }}>
                    Indian Academy of Law and Management (IALM) is honoured with
                    the National Pride Educational Award 2024. IALM is a prime
                    example. Our continuous efforts towards excellence and
                    perfection have made us grab the National Pride Educational
                    Award 2024 in the category of BEST ONLINE E-LEARNING
                    PLATFORM.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="row flex-column flex-column-reverse flex-md-row ">
              <div
                className="col-md-5 d-flex align-items-center"
                style={{
                  background: "rgb(255, 241, 225)",
                }}
              >
                <img
                  src={
                    "/img/staticpages/gASUmwhatsappimage20240119at11.07.17036b1427.jpg"
                  }
                  classes=""
                  style={{
                    width: "60%",
                  }}
                  alt=""
                />

                <h3>
                  Ms Prerna Rawat, Vice President of IALM, says, "We take a
                  pledge to keep working hard and keep making our students
                  attain greater heights of success and knowledge"
                </h3>
              </div>
              <div className="col-md-7 d-flex align-items-center ">
                <p
                  className="p-4"
                  style={{
                    fontSize: "1.13rem",
                    lineHeight: "3em !important",
                    fontWeight: "300",
                    letterSpacing: "0px",
                    textTransform: "none",
                    fontstyle: "normal",
                  }}
                >
                  The Awards are amongst the most prestigious ones accorded to
                  Government colleges, UGC-affiliated Institutions, renowned
                  Schools, as well as online education providers. In the last
                  few years, our world-class digital team has worked tirelessly
                  to enhance students' learning experiences remarkably well. Day
                  and night, they attend to even the smallest details that could
                  impact a student's time here. The Award ceremony was attended
                  by representatives from more than seventy educational
                  institutes across the country. We were honoured to be
                  recognised as the best institute in the nominated category in
                  front of government dignitaries, senior bureaucrats, and media
                  professionals. The National Awards including this one
                  maintains us motivated and inspires to work towards our
                  mission of presenting skills, education and jobs to lakhs of
                  students across the country. Like always, we would like to
                  take this opportunity to thank our cherished students, without
                  them, this would not had been possible. But for us, our
                  student’s effective learning with a better future is the
                  excellent Award. Today we are here because of our student’s
                  support. Also, IALM is extremely thankful to all our faculty
                  members, course councillors, corporates, campus chairs,
                  partner institutions, Government officials and ministers,
                  advertising partners and our unsung heroes. Without them
                  bringing excellence in IALM Academy would not have been
                  possible.
                </p>
              </div>
            </div>
          </section>
        </div>
      </section>
    </main>
  );
}
