import {
  faArrowRight,
  faGraduationCap,
  faRupee,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AxiosClient from "../client/AxiosClient";
import { API_POPULAR_COURSES } from "../ApiEndPoints";
import CourseCardTile from "../component/CourseCardTile";

import Loader from "../component/Loader";
import { FilledPrimaryBtn } from "../component/FilledBtn";
import {
  PrimaryIconOutlineBtnLink,
  PrimaryOutlineBtnLink,
} from "../component/OutlineBtn";

export default function CoursesGrid1() {
  const [loading, setLoading] = useState(true);
  const [popularCourses, setPopularCourses] = useState([]);
  const serverUrl = process.env.REACT_APP_SERVER_BASE_URL;

  const [showMore, toggleShowMore] = useState(false);

  useEffect(() => {
    AxiosClient.get(API_POPULAR_COURSES)
      .then((response) => {
        const listData = response.data.data;
        console.log(listData);
        setPopularCourses(listData);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <section className="">
      <div className="container">
        <p className="sec-sub-title">
          India's Best Industry Recognized Online Certification
        </p>
        <h2 className="sec-title">
          Most Popular Online Law/Management Courses
        </h2>
        <p className="sec-short-desc">
          Explore top-rated online courses in law and management to advance your
          career
        </p>

        <div>
          <div className="row">
            {loading && (
              <div className="col-12 text-center">
                <Loader classes="text-primary" />
              </div>
            )}

            {!loading &&
              popularCourses.map((row, index) => {
                return (
                  <div
                    className="col-sm-6 col-md-6 col-lg-3"
                    key={`course-grid-sec${index}`}
                  >
                    <CourseCardTile
                      title={row.title}
                      category={row.category_name}
                      thumbnail={serverUrl + row.thumbnail}
                      price={row.price}
                      instructorName={row.instructor_name}
                      discountPrice={row.discount_price}
                      link={`/courses/${row.slug}`}
                    />
                  </div>
                );
              })}

            <div className="col-12 d-flex align-items-center justify-content-center">
              {!loading && (
                <PrimaryIconOutlineBtnLink
                  label={"View all"}
                  link={"/courses"}
                  classes="d-block d-md-inline-block "
                  fontAwesomeIcon={faArrowRight}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
