import React, { useEffect, useState } from "react";
import AxiosClient from "../../../client/AxiosClient";
import {
  API_INCOMPLETED_COURSE,
  API_LOGIN_HISTORY,
} from "../../../ApiEndPoints";
import Loader from "../../../component/Loader";
import { Link } from "react-router-dom";

export default function LoginHistoryTable() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AxiosClient.get(API_LOGIN_HISTORY)
      .then((response) => {
        if (response.data.success == true) {
          setData(response.data.data);
        }
      })
      .catch((err) => {
        console.error("Error while getting incompleted courses: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="card rounded-1">
      <div className="card-header bg-primary text-white">
        <h5 className="card-title mb-0">Login history</h5>
      </div>
      <div className="card-body table-responsive">
        <table className="table table-striped w-100">
          <thead>
            <tr>
              <th>S. no.</th>
              <th>IP Address</th>
              <th>OS</th>
              <th>Browser</th>
              <th>Login At</th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colspan="5" className="text-center">
                  <Loader />
                </td>
              </tr>
            )}

            {!loading &&
              data.map((item, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item.ip ?? "-"}</td>
                    <td>{item.os ?? "-"}</td>
                    <td>{item.browser ?? "-"}</td>
                    <td>{item.login_at}</td>
                  </tr>
                );
              })}

            {!loading && data.length === 0 && (
              <tr>
                <td className="text-center" colSpan="5">
                  No history found found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
