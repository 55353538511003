import React from "react";
import "./../styles/HorizonalCheckLine.style.css";

export default function HorizonalCheckLine({ list }) {
  return (
    <div>
      <ul className="hr-check-line type-none px-0">
        {list.map((item, index) => {
          return (
            <li key={`hr-check-line-${index}`}>
              <span className="_icon">
                <img
                  src="/icons/check-round.svg"
                  className="img-fluid"
                  alt="right-icon"
                />
                {item}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
