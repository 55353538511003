import React, { useEffect, useState } from "react";
import AxiosClient from "../../../client/AxiosClient";
import {
  API_INCOMPLETED_COURSE,
  API_WISHLISTED_COURSE,
} from "../../../ApiEndPoints";
import Loader from "../../../component/Loader";
import { Link, useNavigate } from "react-router-dom";
import {
  FilledPrimaryBtn,
  IconFilledPrimaryBtn,
} from "./../../../component/FilledBtn";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../redux/slice/CartSlice";

export default function StudyWishlistTable() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AxiosClient.get(API_WISHLISTED_COURSE)
      .then((response) => {
        if (response.data.success === true) {
          setData(response.data.data);
        }
      })
      .catch((err) => {
        console.error("Error while getting wishlist courses: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const dispatch = useDispatch();
  const navigateTo = useNavigate();

  return (
    <div className="completed-courses card rounded-1">
      <div className="card-header bg-primary text-white">
        <h5 className="card-title mb-0">Wishlist Courses</h5>
      </div>
      <div className="card-body table-responsive">
        <table className="table table-striped w-100">
          <thead>
            <tr>
              <th>Course No.</th>
              <th>Course Title</th>
              <th>Faculty</th>
              <th>Duration</th>
              <th className="text-center">Sample Certificate</th>
              <th>Certification</th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td className="text-center" colspan="6">
                  <Loader />
                </td>
              </tr>
            )}

            {!loading && data.length === 0 && (
              <tr>
                <td colspan="6" className="text-center">
                  No wishlist course found
                </td>
              </tr>
            )}

            {!loading &&
              data.map((item, index) => {
                return (
                  <tr>
                    <td className="vertical-middle">{item.course.unique_id}</td>
                    <td className="vertical-middle">{item.course.title.en}</td>
                    <td className="vertical-middle">{item.course.user.name}</td>
                    <td className="vertical-middle">
                      {item.course.access_limit} days
                    </td>
                    <td className="vertical-middle text-center">
                      <Link to={`/courses/${item.course.slug}`}>View</Link>
                    </td>
                    <td className="vertical-middle">
                      <IconFilledPrimaryBtn
                        label={"Make Payment"}
                        clickHandler={() => {
                          dispatch(
                            addToCart({
                              id: item.course.id,
                              name: item.course.title.en,
                              instructorName: item.course.user.name,
                              mrpPrice:
                                item.course.price === null ||
                                item.course.price === ""
                                  ? 0
                                  : item.course.price,
                              discountPrice:
                                item.course.discount_price === null ||
                                item.course.discount_price === ""
                                  ? 0
                                  : item.course.discount_price,
                              img: item.course.image,
                            })
                          );

                          navigateTo("/cart");
                        }}
                        isLoading={false}
                        className="vertical-middle white-space-nowrap"
                        type="button"
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
