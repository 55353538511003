import { Helmet } from "react-helmet";
import BreadCrumb from "../component/BreadCrumb";
import NativeLazyImage from "../component/NativeLazyImage";
import "./styles/Pages.style.css";

export default function IprandinnovationcellPage() {
  return (
    <main className="ct-pages">
      <Helmet>
        <title>IPR and INNOVATION CELL | {process.env.REACT_APP_NAME}</title>
        <meta
          name="keywords"
          content="online law courses, online law courses in india, law courses in india, management course, law course, civil services, finance course, cyber security course, earn free certificate, online job oriented courses, best online law courses, best online law courses in india"
        />
        <meta
          name="description"
          content="Take online management and law courses from top institution. Learn about business law to advance your education and career with IALM Academy Today!"
        />
      </Helmet>

      <BreadCrumb
        title="Intellectual Property Cell"
        desc="innovation program ialm faculty and founders are responsible for the creation of a patent program for several&nbsp;<br>MNCs &amp; Indian r&amp;d centres, UGC-approved colleges &amp; institutions, and private corporates"
      />

      <div>
        <div className="">
          <div>
            <div className="row flex-column flex-column-reverse flex-md-row ">
              <div
                className="col-md-6 d-flex align-items-center"
                style={{
                  backgroundImage:
                    "linear-gradient(0deg, rgb(255, 170, 170), rgb(255, 255, 200))",
                }}
              >
                <p className="lead" style={{ padding: "8rem" }}>
                  <h3>
                    AN IP CELL HELPS TECHNOLOGY AND ENGINEERING INSTITUTES TO:
                  </h3>
                  <ul className="list pb-5">
                    <li className="mt-2">
                      1. Get brighter students during admission based on the
                      number of patents filed by the institute.
                    </li>
                    <li className="mt-2">
                      2. Availing of Government Grants for Incubation, Start-ups
                      and UGC Grants.
                    </li>
                    <li className="mt-2">
                      3. Commercialization of IPRs in the form of license fees
                      and assignment to industry.
                    </li>
                    <li className="mt-2">
                      4. Closer institutions and industry collaborations.
                    </li>
                    <li className="mt-2">
                      5. Opportunities for collaboration with a foreign
                      university
                    </li>
                  </ul>
                </p>
              </div>

              <div
                className="col-md-6 d-flex align-items-center "
                style={{
                  backgroundColor: "#D89E14",
                }}
              >
                <p className="lead" style={{ padding: "8rem" }}>
                  <h3>IALM Supports Institutions in the following ways:</h3>
                  <ul className="list pb-5">
                    <li className="mt-2">
                      1. Take IP Awareness sessions with Faculty and student.
                    </li>
                    <li className="mt-2">
                      2. Create IP Policy for the institutes.
                    </li>
                    <li> 3. Create Programs for receiving innovative ideas.</li>
                    <li className="mt-2">
                      {" "}
                      4. Filtering process of filing commercially viable
                      patents.
                    </li>
                    <li className="mt-2">
                      5. Prior art search to check the novelty of the invention.
                    </li>
                    <li className="mt-2">
                      6. Filing of the patent application at the Indian patent
                      office.
                    </li>
                    <li className="mt-2">
                      7. Following up the application till the grant of the
                      patent.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundPosition: "50% 50%",
            transform: "translateY(-25.8693px) scale(1.05)",
            backgroundImage: "url(/img/staticpages/QVM5Ahairstylesbeauty.jpg)",
          }}
        >
          <div className="row flex-column flex-column-reverse flex-md-row ">
            <div
              className="col-md-12  text-center"
              style={{ marginTop: "160px", marginBottom: "80px" }}
            >
              <p
                className="text-center"
                style={{
                  textAlign: "center",
                  fontFamily: "Concert One, cursive",
                  color: "#fff",
                  fontSize: "36px",
                  letterSpacing: "6px",
                  lineHeight: "1.4",
                  textTransform: "uppercase",
                  fontWeight: "600",
                }}
              >
                now, you have two options
              </p>
              <p
                className="text-center"
                style={{
                  textAlign: "center",
                  fontFamily: "Concert One, cursive",
                  color: "#fff",
                  fontSize: "30px",
                  letterSpacing: "6px",
                  lineHeight: "1.4",
                  textTransform: "uppercase",
                  fontWeight: "600",
                }}
              >
                regret later & studying now
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
