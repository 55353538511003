import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function WatchedIcon({ completed = null }) {
  return completed == null ? (
    <FontAwesomeIcon icon={faCircleCheck} className="me-2" color="#ffffff" />
  ) : (
    <FontAwesomeIcon icon={faCircleCheck} className="me-2" color="#0056d2" />
  );
}
