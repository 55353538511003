import { Helmet } from "react-helmet";
import BreadCrumb from "../component/BreadCrumb";
import NativeLazyImage from "../component/NativeLazyImage";
import "./styles/AboutUsPage.style.css";

export default function RefundPolicyPage() {
  return (
    <main>
      <Helmet>
        <title>Refund Policy | {process.env.REACT_APP_NAME}</title>
        <meta
          name="keywords"
          content="online law courses, online law courses in india, law courses in india, management course, law course, civil services, finance course, cyber security course, earn free certificate, online job oriented courses, best online law courses, best online law courses in india"
        />
        <meta
          name="description"
          content="Take online management and law courses from top institution. Learn about business law to advance your education and career with IALM Academy Today!"
        />
      </Helmet>

      <BreadCrumb title="Refund Policy" desc="" />

      <section>
        <div className="container">
          <h2>Refund & Cancellation Policy for Certificate Courses of IALM</h2>

          <h3 className="fw-400 mb-4">
            Learners will be eligible for an 80% refund within the first 7 days
            of registration, provided they have not accessed two or more modules
            of the course material.
          </h3>
          <h3 className="fw-400 mb-4">
            Learners will be eligible for an 80% refund within the first 7 days
            of registration, provided they have not accessed two or more modules
            of the course material. Learners will be eligible for a 60% refund
            within the first 14 days of registration, provided they have not
            accessed two or more modules of the course material.
          </h3>

          <h3 className="fw-400">How to seek a refund?</h3>
          <ul className="lead">
            <li className="mb-2">
              Please write an email to registrar@ialm.academy mentioning your
              registered email address and the name and batch of the course for
              which you are seeking a refund. Please also mention your reason
              for requesting a refund.
            </li>
            <li className="mb-2">
              A refund confirmation email mentioning the refund amount will be
              sent within three days.
            </li>
            <li className="mb-2">
              The refund shall be processed in your original mode of payment
              within ten days of receipt of your request.
            </li>
          </ul>
        </div>
      </section>
    </main>
  );
}
