import { Helmet } from "react-helmet";
import BreadCrumb from "../component/BreadCrumb";
import NativeLazyImage from "../component/NativeLazyImage";
import "./styles/AboutUsPage.style.css";

export default function TermsPage() {
  return (
    <main>
      <Helmet>
        <title>Terms | {process.env.REACT_APP_NAME}</title>
        <meta
          name="keywords"
          content="online law courses, online law courses in india, law courses in india, management course, law course, civil services, finance course, cyber security course, earn free certificate, online job oriented courses, best online law courses, best online law courses in india"
        />
        <meta
          name="description"
          content="Take online management and law courses from top institution. Learn about business law to advance your education and career with IALM Academy Today!"
        />
      </Helmet>

      <BreadCrumb title="Terms of use" desc="" />

      <section>
        <div className="container">
          <ul className="lead">
            <li className="mb-2">
              All students of our courses are required to follow these terms and
              conditions.
            </li>
            <li className="mb-2">
              The student is presumed to have read, agreed and accepted these
              terms & conditions.
            </li>
            <li className="mb-2">
              The course and/or app logins, and course materials provided to the
              student will be for his/her use only. The student agrees to not
              distribute the same to others or reproduce any course material, in
              any form or manner.
            </li>
            <li className="mb-2">
              IALM offers only one reattempt in case of non-qualifying the
              course within the course duration. Contact the concerned
              department at <a href="tel:+91 9650727918">+91 9650727918</a>
            </li>
            <li className="mb-2">
              The student is expected to recheck her/his personal details for
              any mismatch in the registration confirmation. For any changes,
              the students can inform their IALM within two (2) weeks from the
              start of the course.
            </li>
            <li className="mb-2">
              The student will receive an e-certificate/ Hard copy upon
              successful completion of the course. The student needs to inform
              IALM after course completion via filling the "Course Completion
              Form" along with KYC details.
            </li>
          </ul>
        </div>
      </section>
    </main>
  );
}
