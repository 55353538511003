import { Helmet } from "react-helmet";
import BreadCrumb from "../component/BreadCrumb";
import NativeLazyImage from "../component/NativeLazyImage";
import "./styles/AboutUsPage.style.css";
import { Link } from "react-router-dom";

export default function PrivacyPolicyPage() {
  return (
    <main>
      <Helmet>
        <title>Privacy Policy | {process.env.REACT_APP_NAME}</title>
        <meta
          name="keywords"
          content="online law courses, online law courses in india, law courses in india, management course, law course, civil services, finance course, cyber security course, earn free certificate, online job oriented courses, best online law courses, best online law courses in india"
        />
        <meta
          name="description"
          content="Take online management and law courses from top institution. Learn about business law to advance your education and career with IALM Academy Today!"
        />
      </Helmet>

      <BreadCrumb
        title="Privacy Policy"
        desc="Indian Academy of Law and Management (IALM) is a unit of The Indian Legal Foundation (TILF)"
      />

      <section>
        <div className="container">
          <h2>Privacy Policy</h2>
          <p className="lead">
            IALM takes its responsibilities with regard to the management of the
            requirements of Data Protection is very seriously. This policy sets
            out how the IALM manages those responsibilities.
          </p>

          <h3 className="fw-400">
            This policy, therefore, seeks to ensure that we:
          </h3>
          <ol type="alpha" className="mb-4 lead">
            <li class="mb-2">
              are clear about how personal data must be processed and IALM
              expectations for all those who process personal data on its
              behalf;
            </li>
            <li class="mb-2">
              comply with the data protection law and with good practice;
            </li>
            <li class="mb-2">
              protect the IALM's reputation by ensuring the personal data
              entrusted to us is processed in accordance with data subjects’
              rights;
            </li>
            <li class="mb-2">
              protect the IALM from risks of personal data breaches and other
              breaches of data protection law.
            </li>
          </ol>

          <h3 className="fw-400">The Scope of the Policy</h3>
          <p className="mb-3 lead">
            This policy applies to all personal data we process regardless of
            the location where that personal data is stored and regardless of
            the data subject. All members and others processing personal data on
            IALM's behalf must read it. A failure to comply with this policy may
            result in disciplinary action.
          </p>
          <p className="mb-2 lead">
            The IALM Management is responsible for overseeing this policy.
          </p>
          <p className="mb-2 lead">
            The IALM can be reached at registrar@ialm.academy
          </p>
        </div>
      </section>
    </main>
  );
}
