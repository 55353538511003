import { BrowserRouter, Route, Routes } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/font-awesome/css/font-awesome.min.css";

import "./App.css";

import HomePage from "./pages/HomePage";
import Header from "./sections/Header";
import Footer from "./sections/Footer";

import UnderDevelopmentPage from "./pages/UnderDevelopmentPage";
import FacultyEnrollmentPage from "./pages/faculty/FacultyEnrollmentPage";
import ScrollToTop from "./component/ScrollTotop";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import ErrorBoundaryWrap from "./error-boundries/ErrorBoundaryWrap";
import AllFacultiesPage from "./pages/faculty/AllFacultiesPage";
import SingleFacultyPage from "./pages/faculty/SingleFacultyPage";
import AllCoursesPage from "./pages/course/AllCoursesPage";
import SingleCoursePage from "./pages/course/SingleCoursePage";
import PageNotFound404 from "./pages/PageNotFound404";
import AboutUsPage from "./pages/AboutUsPage";
import BlogPage from "./pages/Blog/BlogPage";
import CategoryCoursesPage from "./pages/course/CategoryCoursesPage";
import { PersistGate } from "redux-persist/integration/react";
import Loader from "./component/Loader";
import CartPage from "./pages/CartPage";
import LogInGuard from "./component/LogInGuard";
import DashboardPage from "./pages/student_dashboard/DashboardPage";
import ProfilePage from "./pages/student_dashboard/ProfilePage";
import MyPurchasesPage from "./pages/student_dashboard/MyPurchasesPage";
import MyCoursesPage from "./pages/student_dashboard/MyCoursesPage";
import WatchCoursePage from "./pages/student_dashboard/WatchCoursePage";
import TakeQuizzPage from "./pages/student_dashboard/TakeQuizzPage";
import QuizzResultPage from "./pages/student_dashboard/QuizzResultPage";
import CategoryBlogPage from "./pages/Blog/CategoryBlogPage";
import SingleBlogPage from "./pages/Blog/SingleBlogPage";
import MyLoginsPage from "./pages/student_dashboard/MyLoginsPage";
import AdminLoginPage from "./pages/AdminLoginPage";
import TermsPage from "./pages/TermsPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import ContactUsPage from "./pages/ContactUsPage";
import EmailVerificationPage from "./pages/EmailVerificationPage";
import ForgetPasswordPage from "./pages/ForgetPasswordPage";
import UpscarticlePage from "./pages/Upscarticle";
import IprandinnovationcellPage from "./pages/Iprandinnovationcell";
import IalmhonouredPage from "./pages/Ialmhonoured";
import UpscSyllabusPage from "./pages/UpscSyllabusPage";
import RefundPolicyPage from "./pages/RefundPolicyPage";

function App() {
  return (
    <Provider store={store}>
      <PersistGate
        loading={
          <div className="vh-100 w-100 d-flex align-items-center justify-content-center">
            <Loader />
          </div>
        }
        persistor={persistor}
      >
        <div className="App">
          <BrowserRouter>
            <ErrorBoundaryWrap component={Header} />
            <ScrollToTop />

            <Routes>
              <Route path="/" exact element={<HomePage />}></Route>
              <Route
                path="/tryingddwwLoginweeeWithasdfToken/:token"
                exact
                element={<AdminLoginPage />}
              ></Route>

              <Route
                path="/verify-email/:token"
                exact
                element={<EmailVerificationPage />}
              ></Route>

              <Route
                path="/forget-password"
                exact
                element={<ForgetPasswordPage />}
              ></Route>

              <Route path="/about-us" exact element={<AboutUsPage />}></Route>
              <Route path="/blogs" exact element={<BlogPage />}></Route>
              <Route
                path="/blogs/category/:category_slug"
                exact
                element={<CategoryBlogPage />}
              ></Route>

              <Route
                path="/blogs/:blog_slug"
                exact
                element={<SingleBlogPage />}
              ></Route>

              <Route
                path="/under-development"
                element={<UnderDevelopmentPage />}
              ></Route>

              <Route path="/contact-us" element={<ContactUsPage />}></Route>
              <Route path="/about-us" element={<AboutUsPage />}></Route>
              <Route path="/terms-of-use" element={<TermsPage />}></Route>
              <Route
                path="/refund-policy"
                element={<RefundPolicyPage />}
              ></Route>
              <Route
                path="/privacy-policy"
                element={<PrivacyPolicyPage />}
              ></Route>

              <Route
                path="/faculty-registration"
                element={<FacultyEnrollmentPage />}
              ></Route>

              <Route path="/faculties" element={<AllFacultiesPage />}></Route>
              <Route
                path="/faculties/:username"
                element={<SingleFacultyPage />}
              ></Route>

              <Route path="/courses" element={<AllCoursesPage />}></Route>
              <Route
                path="/courses/:slug"
                element={<SingleCoursePage />}
              ></Route>

              <Route
                path="/category/:slug"
                element={<CategoryCoursesPage />}
              ></Route>

              {/* protected routes starts */}

              <Route
                path="/cart"
                element={
                  <LogInGuard>
                    <CartPage />
                  </LogInGuard>
                }
              ></Route>

              <Route
                path="/dashboard"
                element={
                  <LogInGuard>
                    <DashboardPage />
                  </LogInGuard>
                }
              ></Route>
              <Route
                path="/profile"
                element={
                  <LogInGuard>
                    <ProfilePage />
                  </LogInGuard>
                }
              ></Route>
              <Route
                path="/my-courses"
                element={
                  <LogInGuard>
                    <MyCoursesPage />
                  </LogInGuard>
                }
              ></Route>
              <Route
                path="/my-purchases"
                element={
                  <LogInGuard>
                    <MyPurchasesPage />
                  </LogInGuard>
                }
              ></Route>
              <Route
                path="/my-logins"
                element={
                  <LogInGuard>
                    <MyLoginsPage />
                  </LogInGuard>
                }
              ></Route>

              <Route
                path="/my-courses/watch/:course_slug"
                element={
                  <LogInGuard>
                    <WatchCoursePage />
                  </LogInGuard>
                }
              ></Route>
              <Route
                path="/my-course/take-quizz/:course_id/:quizz_id"
                element={
                  <LogInGuard>
                    <TakeQuizzPage />
                  </LogInGuard>
                }
              ></Route>

              <Route
                path="/my-course/quizz-result/:course_id/:quizz_id"
                element={
                  <LogInGuard>
                    <QuizzResultPage />
                  </LogInGuard>
                }
              ></Route>

              {/* protected routes ends */}

              {/* static pages routes start  */}
              <Route
                path="/upscarticle"
                exact
                element={<UpscarticlePage />}
              ></Route>
              <Route
                path="/iprandinnovationcell"
                exact
                element={<IprandinnovationcellPage />}
              ></Route>
              <Route
                path="/ialm-honoured-with-national-pride-educational-award-2022"
                exact
                element={<IalmhonouredPage />}
              ></Route>
              <Route
                path="/upsc-syllabus"
                exact
                element={<UpscSyllabusPage />}
              ></Route>
              {/* static pages routes end  */}
              <Route path="/page-not-found" element={<PageNotFound404 />} />
              <Route path="/*" element={<PageNotFound404 />} />
            </Routes>
            <Footer />
          </BrowserRouter>
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
