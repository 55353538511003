import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";

export default function MarqueeHome() {
  return (
    <section className="bg-light py-0">
      <div className="container">
        <Marquee className="home_marquee py-2" pauseOnHover={true} speed={120}>
          {" | "}
          <a href="/upscarticle" target="_blank">
            Guidelines for IAS Interview - JOTWANI IAS ACADEMY
          </a>
          {" | "}
          <a href="/iprandinnovationcell" target="_blank">
            IPR and Innovation Cell
          </a>
          {" | "}
          {" | "}
          <a
            href="/ialm-honoured-with-national-pride-educational-award-2022"
            target="_blank"
          >
            IALM Honoured with National Pride Educational Award-2022
          </a>
          {" | "}
          {" | "}
          <a href="/upsc-syllabus" target="_blank">
            UPSC Syllabus 2022-2023
          </a>
          {" | "}
        </Marquee>
      </div>
    </section>
  );
}
