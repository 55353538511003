import { useEffect, useState } from "react";
import { API_TESTIMONIALS } from "../ApiEndPoints";
import AxiosClient from "../client/AxiosClient";
import Loader from "../component/Loader";
import TestimonialTile from "../component/TestimonialTile";

export default function TestimonialRow() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    AxiosClient.get(API_TESTIMONIALS)
      .then((response) => {
        setData(response.data.data);
      })
      .catch((err) => {
        console.error("Error white getting testimonials: " + err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <section className="bg-white">
      <div className="container">
        <h2 className="sec-title text-center">From the IALM community</h2>
        <h3 className="sec-title-sm text-center fw-500 mb-5">
          148+ million people have already joined IALM
        </h3>

        <div className="row justify-content-center">
          {loading ? (
            <div className="col-12 text-center">
              <Loader classes="text-primary" />
            </div>
          ) : null}

          {!loading &&
            data.map((row, index) => {
              return (
                <div className="col-md-6 col-lg-4" key={`testimonial-${index}`}>
                  <TestimonialTile
                    author={row.author}
                    avatar={row.image}
                    desc={row.body}
                    designation={row.profession}
                  />
                </div>
              );
            })}

          {/* <div className="col-md-4">
            <div className="card testimonial-card border-0 px-4 mb-4">
              <div className="avtar mx-auto">
                <img
                  src="/img/testimonial/Circle_Ryan.png"
                  className="card-img-top"
                  alt=""
                />
              </div>
              <div className="card-body text-center">
                <h4 className="card-title">Kenia R.</h4>
                <p className="card-city">United States</p>
                <span className="sep mx-auto"></span>

                <div className="_desc mt-3">
                  “Being a mother — especially a working mother means I’m
                  constantly trying to juggle my schedule, my kids’ schedules,
                  and work. I am very grateful for the flexible and remote
                  learning programs that IALM has to offer.”
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card testimonial-card border-0 px-4 mb-0">
              <div className="avtar mx-auto">
                <img
                  src="/img/testimonial/Vishal_Verma.png"
                  className="card-img-top"
                  alt=""
                />
              </div>
              <div className="card-body text-center">
                <h4 className="card-title">Kenia R.</h4>
                <p className="card-city">United States</p>
                <span className="sep mx-auto"></span>

                <div className="_desc mt-3">
                  “Being a mother — especially a working mother means I’m
                  constantly trying to juggle my schedule, my kids’ schedules,
                  and work. I am very grateful for the flexible and remote
                  learning programs that IALM has to offer.”
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}
