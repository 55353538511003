import { FilledWhiteBtnLink } from "./FilledBtn";
import { WhiteOutlineBtnLink } from "./OutlineBtn";
import NativeLazyImage from "./NativeLazyImage";
import HorizonalCheckLine from "./HorizonalCheckLine";

export default function HeroBanner({ title, desc }) {
  return (
    <section className="hero py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-7 d-flex align-items-center">
            <div>
              <h1 className="text-white mb-2 text-md-start mt-lg-2 mt-xl-0">
                6 Reasons To Take IALM Course
              </h1>

              <HorizonalCheckLine
                list={[
                  "Improve Knowledge, Skills and Education",
                  "Earn Globally Recognized Certifications",
                  "Race Ahead in Indian Competitive Examinations",
                  "Increase Credits to apply to Foreign Universities",
                  "Get recommendations from Highly acclaimed IALM faculty",
                  "Get into the prestigious IALM Placement cell",
                ]}
              />

              <div className="d-flex justify-content-center justify-content-md-start mt-4">
                <FilledWhiteBtnLink
                  label="Explore Courses"
                  link={"/courses"}
                  classes="me-2"
                />

                <WhiteOutlineBtnLink label="Join For Free" link={"#"} />
              </div>
            </div>
          </div>
          <div className="col-md-5 d-none d-lg-block">
            <NativeLazyImage src="/img/slides/slide.png" classes="img-fluid" />
          </div>
        </div>
      </div>
    </section>
  );
}
