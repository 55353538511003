import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./../styles/mobile_nav.style.css";
import {
  faArrowRight,
  faCartShopping,
  faL,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FilledPrimaryBtn, FilledPrimaryBtnLink } from "./FilledBtn";
import MobileSearch from "./MobileSearch";
import ProfileDropdown from "./ProfileDropdown";

export default function MobileNav() {
  let linkClasses = "d-block text-decoration-none";

  const [toggleNav, setToggleNav] = useState(false);

  const location = useLocation();
  console.log("Location is: ", location);

  let cart = useSelector((state) => state.cart);
  let userSession = useSelector((state) => state.userSession);

  const navigateTo = useNavigate();

  const [toggleSearch, setToggleSearch] = useState(false);

  useEffect(() => {
    setToggleNav(false);
    setToggleSearch(false);
  }, [location.pathname, location.search]);

  return (
    <>
      <nav className="container-fluid mobile-header d-xl-none">
        <div className="w-100 py-2 d-flex">
          <button
            type="button"
            onClick={() => {
              setToggleNav(true);
            }}
            className="btn btn-transparent ps-0"
          >
            <img
              src="/icons/menu-icon.svg"
              alt="menu-icon"
              className="_menu_icon"
            />
          </button>

          <a className="navbar-brand" href="/">
            <img
              src="/logo.png"
              alt="logo"
              className="img-fluid"
              id="header-logo-mobile"
            />
          </a>

          <div className="_right_icons d-flex align-self-center ms-auto">
            <button
              type="button"
              className="btn btn-sm btn-transparent position-relative mx-2"
              onClick={() => {
                navigateTo("/cart");
              }}
            >
              <FontAwesomeIcon
                icon={faCartShopping}
                color="#0056d2"
                fontSize={20}
              />
              {cart.length > 0 && (
                <span className="badge bg-primary position-absolute">
                  {cart.length}
                </span>
              )}
            </button>

            <button
              className="btn btn-transparent"
              onClick={() => {
                setToggleSearch(true);
              }}
            >
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                color="#0056d2"
                fontSize={20}
              />
            </button>

            {Object.keys(userSession).length > 0 && (
              <ProfileDropdown userSession={userSession} />
            )}
          </div>
        </div>

        <div
          className={`mobile-nav-wrap ${toggleNav === true ? "active" : ""}`}
        >
          <div className="_top d-flex justify-content-between bg-white p-3 align-items-center">
            <Link to="/">
              <img
                src="logo.png"
                alt="logo"
                className="img-fluid"
                id="header-logo-mobile"
              />
            </Link>
            <button
              type="button"
              className="btn btn-transparent"
              onClick={() => {
                setToggleNav(false);
              }}
              id="menu-close-btn"
            >
              <img
                src="/icons/menu-close.svg"
                alt="menu-icon"
                className="img-fluid"
              />
            </button>
          </div>

          <div className="px-4">
            <ul className="list mt-2 px-0">
              <li>
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive ? `active ${linkClasses}` : `${linkClasses}`
                  }
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/courses"
                  className={({ isActive }) =>
                    isActive ? `active ${linkClasses}` : `${linkClasses}`
                  }
                >
                  All Courses
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about"
                  className={({ isActive }) =>
                    isActive ? `active ${linkClasses}` : `${linkClasses}`
                  }
                >
                  About us
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/blogs"
                  className={({ isActive }) =>
                    isActive ? `active ${linkClasses}` : `${linkClasses}`
                  }
                >
                  Blogs
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="_bottom bg-white py-4 position-absolute">
            {/* <a className="btn btn-primary theme-btn bg-primary me-2">
              Join for Free
            </a> */}

            {Object.keys(userSession).length == 0 ? (
              <>
                <Link
                  to="/?action=register"
                  className="btn btn-primary theme-btn bg-primary me-2"
                >
                  {" "}
                  Join for Free
                </Link>

                <Link
                  to="/?action=login"
                  className="btn btn-outline-primary theme-btn"
                >
                  Log in
                  <span className="_icon">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </span>
                </Link>
              </>
            ) : (
              <FilledPrimaryBtnLink label={"Dashboard"} link={"/dashboard"} />
            )}
          </div>
        </div>

        <MobileSearch
          closeSearch={() => {
            setToggleSearch(false);
          }}
          showSearch={toggleSearch}
        />
      </nav>
    </>
  );
}
