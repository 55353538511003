import { Helmet } from "react-helmet";

import BreadCrumb from "../../component/BreadCrumb";
import ImageCard from "../../component/ImageCard";
import { useEffect } from "react";
import { Axios } from "axios";
import { API_ALL_INSTRUCTORS } from "../../ApiEndPoints";
import AxiosClient from "../../client/AxiosClient";
import { useState } from "react";
import Loader from "../../component/Loader";

export default function AllFacultiesPage() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AxiosClient.get(API_ALL_INSTRUCTORS)
      .then((response) => {
        setData(response.data.data);
      })
      .catch((err) => {
        console.error("Error while fetching faculties: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <main>
      <Helmet>
        <title>All Faculties | {process.env.REACT_APP_NAME}</title>
        <meta
          name="keywords"
          content="online law courses, online law courses in india, law courses in india, management course, law course, civil services, finance course, cyber security course, earn free certificate, online job oriented courses, best online law courses, best online law courses in india"
        />
        <meta
          name="description"
          content="Take online management and law courses from top institution. Learn about business law to advance your education and career with IALM Academy Today!"
        />
      </Helmet>

      <BreadCrumb
        title="All Instructors"
        desc="Explore our dedicated team of instructors, each bringing their unique expertise to help you succeed"
      />

      <section>
        <div className="container">
          <div className="row">
            {loading && (
              <div className="col-12 text-center">
                <Loader classes="text-primary" />
              </div>
            )}

            {!loading &&
              data.map((row, index) => {
                return (
                  <div
                    className="col-md-6 col-lg-4 px-4 mb-3"
                    key={`faculties-${index}`}
                  >
                    <ImageCard
                      thumbnail={row.image}
                      title={row.instructor_name}
                      institute={row.job_title == null ? "-" : row.job_title}
                      link={`/faculties/${row.username}`}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </main>
  );
}
