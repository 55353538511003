import { Link } from "react-router-dom";
import { FilledPrimaryBtn } from "./FilledBtn";
import { Form } from "react-bootstrap";
import TogglePasswordField from "./TogglePasswordField";
import { useState } from "react";
import DismissableAlert from "./DismissableAlert";
import AxiosClient from "../client/AxiosClient";
import { API_REGISTER_FACULTY } from "../ApiEndPoints";
import { SERVER_ERR_MSG } from "../DefaultMsg";
import InfoTooltip from "./InfoTooltip";

export default function FacultyRegistrationForm() {
  const [formValues, setFormValues] = useState({
    name: "",
    nic_name: "",
    institute_name: "",
    email: "",
    password: "",
    phone: "",
    photograph: null,
    short_video: null,
    about: "",
    address: "",
    country: 101,
    state: 10,
    zip: "",
    terms_accepted: "",
  });

  const [formErros, setFormErros] = useState({
    name: "",
    nic_name: "",
    institute_name: "",
    email: "",
    password: "",
    phone: "",
    photograph: "",
    short_video: "",
    about: "",
    address: "",
    country: "",
    state: "",
    zip: "",
    terms_accepted: "",
  });

  const [showLoader, setShowLoader] = useState(false);
  const [showAlert, setshowAlert] = useState({
    show: false,
    type: "",
    title: "",
    desc: "",
  });

  function setPasswordField(password) {
    setFormValues({ ...formValues, password: password });
  }

  function handleForm(event) {
    setShowLoader(true);
    event.preventDefault();

    setFormErros({
      name: "",
      nic_name: "",
      institute_name: "",
      email: "",
      password: "",
      phone: "",
      photograph: null,
      short_video: null,
      about: "",
      address: "",
      country: "",
      state: "",
      zip: "",
      terms_accepted: false,
    });

    const formData = new FormData();

    for (let key in formValues) {
      // console.log(key, yourobject[key]);

      formData.append(key, formValues[key]);
    }

    AxiosClient.post(API_REGISTER_FACULTY, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        const { data: responseData } = response;

        if (responseData.success === true) {
          setFormErros({
            name: "",
            nic_name: "",
            institute_name: "",
            email: "",
            password: "",
            phone: "",
            photograph: null,
            short_video: null,
            about: "",
            address: "",
            country: "",
            state: "",
            zip: "",
            terms_accepted: false,
          });

          setshowAlert({
            show: true,
            type: "success",
            title: "",
            desc: responseData.message,
          });

          //   setTimeout(() => {
          //     navigateTo("/under-development");
          //   }, 1000);
        } else {
          setFormErros({
            name:
              responseData.errors.name != null ? responseData.errors.name : "",

            nic_name:
              responseData.errors.nic_name != null
                ? responseData.errors.nic_name
                : "",

            institute_name:
              responseData.errors.institute_name != null
                ? responseData.errors.institute_name
                : "",
            email:
              responseData.errors.email != null
                ? responseData.errors.email
                : "",
            password:
              responseData.errors.password != null
                ? responseData.errors.password
                : "",
            phone:
              responseData.errors.phone != null
                ? responseData.errors.phone
                : "",
            photograph:
              responseData.errors.photograph != null
                ? responseData.errors.photograph
                : "",
            short_video:
              responseData.errors.short_video != null
                ? responseData.errors.short_video
                : "",
            about:
              responseData.errors.about != null
                ? responseData.errors.about
                : "",
            address:
              responseData.errors.address != null
                ? responseData.errors.address
                : "",
            country:
              responseData.errors.country != null
                ? responseData.errors.country
                : "",
            state:
              responseData.errors.state != null
                ? responseData.errors.state
                : "",
            zip: responseData.errors.zip != null ? responseData.errors.zip : "",
            terms_accepted:
              responseData.errors.terms_accepted != null
                ? responseData.errors.terms_accepted
                : "",
          });

          setshowAlert({
            show: true,
            type: "danger",
            title: "",
            desc: responseData.message,
          });

          console.log(showAlert);
        }
      })
      .catch((err) => {
        console.error(err);

        setshowAlert({
          show: true,
          type: "danger",
          title: "",
          desc: SERVER_ERR_MSG,
        });

        setShowLoader(false);
      })
      .finally(() => {
        setShowLoader(false);
      });

    console.log(formValues);
  }

  return (
    <Form onSubmit={handleForm}>
      <div className="row">
        <div className="col-md-6">
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>
              Name (Cannot be change){" "}
              <span className="required-mark text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={formValues.name}
              onChange={(event) =>
                setFormValues({ ...formValues, name: event.target.value })
              }
            />
            <span className="validation-err text-danger">{formErros.name}</span>
          </Form.Group>
        </div>

        <div className="col-md-6">
          <Form.Group className="mb-3" controlId="nic_name">
            <Form.Label>Nic name (optional)</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={formValues.nic_name}
              onChange={(event) =>
                setFormValues({ ...formValues, nic_name: event.target.value })
              }
            />
            <span className="validation-err text-danger">
              {formErros.nic_name}
            </span>
          </Form.Group>
        </div>

        <div className="col-md-12">
          <Form.Group className="mb-3" controlId="institute_name">
            <Form.Label>Institute name (if any)</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={formValues.institute_name}
              onChange={(event) =>
                setFormValues({
                  ...formValues,
                  institute_name: event.target.value,
                })
              }
            />
            <span className="validation-err text-danger">
              {formErros.institute_name}
            </span>
          </Form.Group>
        </div>
        <div className="col-md-6">
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>
              Email <span className="required-mark text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={formValues.email}
              onChange={(event) =>
                setFormValues({ ...formValues, email: event.target.value })
              }
            />
            <span className="validation-err text-danger">
              {formErros.email}
            </span>
          </Form.Group>
        </div>
        <div className="col-md-6">
          <Form.Group className="mb-3" controlId="password">
            <Form.Label>
              Password <span className="required-mark text-danger">*</span>
            </Form.Label>
            <TogglePasswordField
              password={formValues.password}
              setPassword={setPasswordField}
            />
            <span className="validation-err text-danger">
              {formErros.password}
            </span>
          </Form.Group>
        </div>

        <div className="col-md-6">
          <Form.Group className="mb-3" controlId="mobile_no">
            <Form.Label>
              Mobile no. <span className="required-mark text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={formValues.phone}
              onChange={(event) =>
                setFormValues({ ...formValues, phone: event.target.value })
              }
            />
            <span className="validation-err text-danger">
              {formErros.phone}
            </span>
          </Form.Group>
        </div>
        <div className="col-md-6">
          <Form.Group className="mb-3" controlId="photograph">
            <Form.Label>
              Photograph <span className="required-mark text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              onChange={(event) =>
                setFormValues({
                  ...formValues,
                  photograph: event.target.files[0],
                })
              }
            />
            <span className="validation-err text-danger">
              {formErros.photograph}
            </span>
          </Form.Group>
        </div>

        <div className="col-md-6">
          <Form.Group className="mb-3" controlId="short_video">
            <Form.Label>
              Short video of 60 sec. about you{" "}
              <span className="required-mark text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="file"
              accept="video/*"
              onChange={(event) =>
                setFormValues({
                  ...formValues,
                  short_video: event.target.files[0],
                })
              }
            />
            <span className="validation-err text-danger">
              {formErros.short_video}
            </span>
          </Form.Group>
        </div>

        <div className="col-md-12">
          <Form.Group className="mb-3" controlId="about">
            <Form.Label>
              A little bit about you and topic/Subject that you can teach in (in
              max. 1250 chars){" "}
              <span className="required-mark text-danger">*</span>
              <InfoTooltip
                tooltip={"This will be visible publicly on instructor page"}
                classes="ms-1"
              />
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              style={{ height: "80px" }}
              onChange={(event) =>
                setFormValues({
                  ...formValues,
                  about: event.target.value,
                })
              }
              maxLength={1024}
            />
            <span className="validation-err text-danger">
              {formErros.about}
            </span>
          </Form.Group>
        </div>

        <div className="col-md-12">
          <Form.Group className="mb-3" controlId="address">
            <Form.Label>
              Address <span className="required-mark text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={formValues.address}
              onChange={(event) =>
                setFormValues({ ...formValues, address: event.target.value })
              }
            />
            <span className="validation-err text-danger">
              {formErros.address}
            </span>
          </Form.Group>
        </div>
        <div className="col-md-6">
          <Form.Group className="mb-3" controlId="country">
            <Form.Label>
              Country <span className="required-mark text-danger">*</span>
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              onChange={(event) =>
                setFormValues({ ...formValues, country: event.target.value })
              }
              defaultValue={formValues.country}
            >
              <option value="1">Afghanistan</option>
              <option value="2">Aland Islands</option>
              <option value="3">Albania</option>
              <option value="4">Algeria</option>
              <option value="5">American Samoa</option>
              <option value="6">Andorra</option>
              <option value="7">Angola</option>
              <option value="8">Anguilla</option>
              <option value="9">Antarctica</option>
              <option value="10">Antigua And Barbuda</option>
              <option value="11">Argentina</option>
              <option value="12">Armenia</option>
              <option value="13">Aruba</option>
              <option value="14">Australia</option>
              <option value="15">Austria</option>
              <option value="16">Azerbaijan</option>
              <option value="17">Bahamas The</option>
              <option value="18">Bahrain</option>
              <option value="19">Bangladesh</option>
              <option value="20">Barbados</option>
              <option value="21">Belarus</option>
              <option value="22">Belgium</option>
              <option value="23">Belize</option>
              <option value="24">Benin</option>
              <option value="25">Bermuda</option>
              <option value="26">Bhutan</option>
              <option value="27">Bolivia</option>
              <option value="28">Bosnia and Herzegovina</option>
              <option value="29">Botswana</option>
              <option value="30">Bouvet Island</option>
              <option value="31">Brazil</option>
              <option value="32">British Indian Ocean Territory</option>
              <option value="33">Brunei</option>
              <option value="34">Bulgaria</option>
              <option value="35">Burkina Faso</option>
              <option value="36">Burundi</option>
              <option value="37">Cambodia</option>
              <option value="38">Cameroon</option>
              <option value="39">Canada</option>
              <option value="40">Cape Verde</option>
              <option value="41">Cayman Islands</option>
              <option value="42">Central African Republic</option>
              <option value="43">Chad</option>
              <option value="44">Chile</option>
              <option value="45">China</option>
              <option value="46">Christmas Island</option>
              <option value="47">Cocos (Keeling) Islands</option>
              <option value="48">Colombia</option>
              <option value="49">Comoros</option>
              <option value="50">Congo</option>
              <option value="51">Congo The Democratic Republic Of The</option>
              <option value="52">Cook Islands</option>
              <option value="53">Costa Rica</option>
              <option value="54">Cote DIvoire (Ivory Coast)</option>
              <option value="55">Croatia (Hrvatska)</option>
              <option value="56">Cuba</option>
              <option value="57">Cyprus</option>
              <option value="58">Czech Republic</option>
              <option value="59">Denmark</option>
              <option value="60">Djibouti</option>
              <option value="61">Dominica</option>
              <option value="62">Dominican Republic</option>
              <option value="63">East Timor</option>
              <option value="64">Ecuador</option>
              <option value="65">Egypt</option>
              <option value="66">El Salvador</option>
              <option value="67">Equatorial Guinea</option>
              <option value="68">Eritrea</option>
              <option value="69">Estonia</option>
              <option value="70">Ethiopia</option>
              <option value="71">Falkland Islands</option>
              <option value="72">Faroe Islands</option>
              <option value="73">Fiji Islands</option>
              <option value="74">Finland</option>
              <option value="75">France</option>
              <option value="76">French Guiana</option>
              <option value="77">French Polynesia</option>
              <option value="78">French Southern Territories</option>
              <option value="79">Gabon</option>
              <option value="80">Gambia The</option>
              <option value="81">Georgia</option>
              <option value="82">Germany</option>
              <option value="83">Ghana</option>
              <option value="84">Gibraltar</option>
              <option value="85">Greece</option>
              <option value="86">Greenland</option>
              <option value="87">Grenada</option>
              <option value="88">Guadeloupe</option>
              <option value="89">Guam</option>
              <option value="90">Guatemala</option>
              <option value="91">Guernsey and Alderney</option>
              <option value="92">Guinea</option>
              <option value="93">Guinea-Bissau</option>
              <option value="94">Guyana</option>
              <option value="95">Haiti</option>
              <option value="96">Heard and McDonald Islands</option>
              <option value="97">Honduras</option>
              <option value="98">Hong Kong S.A.R.</option>
              <option value="99">Hungary</option>
              <option value="100">Iceland</option>
              <option value="101">India</option>
              <option value="102">Indonesia</option>
              <option value="103">Iran</option>
              <option value="104">Iraq</option>
              <option value="105">Ireland</option>
              <option value="106">Israel</option>
              <option value="107">Italy</option>
              <option value="108">Jamaica</option>
              <option value="109">Japan</option>
              <option value="110">Jersey</option>
              <option value="111">Jordan</option>
              <option value="112">Kazakhstan</option>
              <option value="113">Kenya</option>
              <option value="114">Kiribati</option>
              <option value="115">Korea North</option>
              <option value="116">Korea South</option>
              <option value="117">Kuwait</option>
              <option value="118">Kyrgyzstan</option>
              <option value="119">Laos</option>
              <option value="120">Latvia</option>
              <option value="121">Lebanon</option>
              <option value="122">Lesotho</option>
              <option value="123">Liberia</option>
              <option value="124">Libya</option>
              <option value="125">Liechtenstein</option>
              <option value="126">Lithuania</option>
              <option value="127">Luxembourg</option>
              <option value="128">Macau S.A.R.</option>
              <option value="129">Macedonia</option>
              <option value="130">Madagascar</option>
              <option value="131">Malawi</option>
              <option value="132">Malaysia</option>
              <option value="133">Maldives</option>
              <option value="134">Mali</option>
              <option value="135">Malta</option>
              <option value="136">Man (Isle of)</option>
              <option value="137">Marshall Islands</option>
              <option value="138">Martinique</option>
              <option value="139">Mauritania</option>
              <option value="140">Mauritius</option>
              <option value="141">Mayotte</option>
              <option value="142">Mexico</option>
              <option value="143">Micronesia</option>
              <option value="144">Moldova</option>
              <option value="145">Monaco</option>
              <option value="146">Mongolia</option>
              <option value="147">Montenegro</option>
              <option value="148">Montserrat</option>
              <option value="149">Morocco</option>
              <option value="150">Mozambique</option>
              <option value="151">Myanmar</option>
              <option value="152">Namibia</option>
              <option value="153">Nauru</option>
              <option value="154">Nepal</option>
              <option value="155">Netherlands Antilles</option>
              <option value="156">Netherlands The</option>
              <option value="157">New Caledonia</option>
              <option value="158">New Zealand</option>
              <option value="159">Nicaragua</option>
              <option value="160">Niger</option>
              <option value="161">Nigeria</option>
              <option value="162">Niue</option>
              <option value="163">Norfolk Island</option>
              <option value="164">Northern Mariana Islands</option>
              <option value="165">Norway</option>
              <option value="166">Oman</option>
              <option value="167">Pakistan</option>
              <option value="168">Palau</option>
              <option value="169">Palestinian Territory Occupied</option>
              <option value="170">Panama</option>
              <option value="171">Papua new Guinea</option>
              <option value="172">Paraguay</option>
              <option value="173">Peru</option>
              <option value="174">Philippines</option>
              <option value="175">Pitcairn Island</option>
              <option value="176">Poland</option>
              <option value="177">Portugal</option>
              <option value="178">Puerto Rico</option>
              <option value="179">Qatar</option>
              <option value="180">Reunion</option>
              <option value="181">Romania</option>
              <option value="182">Russia</option>
              <option value="183">Rwanda</option>
              <option value="184">Saint Helena</option>
              <option value="185">Saint Kitts And Nevis</option>
              <option value="186">Saint Lucia</option>
              <option value="187">Saint Pierre and Miquelon</option>
              <option value="188">Saint Vincent And The Grenadines</option>
              <option value="189">Saint-Barthelemy</option>
              <option value="190">Saint-Martin (French part)</option>
              <option value="191">Samoa</option>
              <option value="192">San Marino</option>
              <option value="193">Sao Tome and Principe</option>
              <option value="194">Saudi Arabia</option>
              <option value="195">Senegal</option>
              <option value="196">Serbia</option>
              <option value="197">Seychelles</option>
              <option value="198">Sierra Leone</option>
              <option value="199">Singapore</option>
              <option value="200">Slovakia</option>
              <option value="201">Slovenia</option>
              <option value="202">Solomon Islands</option>
              <option value="203">Somalia</option>
              <option value="204">South Africa</option>
              <option value="205">South Georgia</option>
              <option value="206">South Sudan</option>
              <option value="207">Spain</option>
              <option value="208">Sri Lanka</option>
              <option value="209">Sudan</option>
              <option value="210">Suriname</option>
              <option value="211">Svalbard And Jan Mayen Islands</option>
              <option value="212">Swaziland</option>
              <option value="213">Sweden</option>
              <option value="214">Switzerland</option>
              <option value="215">Syria</option>
              <option value="216">Taiwan</option>
              <option value="217">Tajikistan</option>
              <option value="218">Tanzania</option>
              <option value="219">Thailand</option>
              <option value="220">Togo</option>
              <option value="221">Tokelau</option>
              <option value="222">Tonga</option>
              <option value="223">Trinidad And Tobago</option>
              <option value="224">Tunisia</option>
              <option value="225">Turkey</option>
              <option value="226">Turkmenistan</option>
              <option value="227">Turks And Caicos Islands</option>
              <option value="228">Tuvalu</option>
              <option value="229">Uganda</option>
              <option value="230">Ukraine</option>
              <option value="231">United Arab Emirates</option>
              <option value="232">United Kingdom</option>
              <option value="233">United States</option>
              <option value="234">United States Minor Outlying Islands</option>
              <option value="235">Uruguay</option>
              <option value="236">Uzbekistan</option>
              <option value="237">Vanuatu</option>
              <option value="238">Vatican City State (Holy See)</option>
              <option value="239">Venezuela</option>
              <option value="240">Vietnam</option>
              <option value="241">Virgin Islands (British)</option>
              <option value="242">Virgin Islands (US)</option>
              <option value="243">Wallis And Futuna Islands</option>
              <option value="244">Western Sahara</option>
              <option value="245">Yemen</option>
              <option value="246">Zambia</option>
              <option value="247">Zimbabwe</option>
            </Form.Select>
            <span className="validation-err text-danger">
              {formErros.country}
            </span>
          </Form.Group>
        </div>

        <div className="col-md-6">
          <Form.Group className="mb-3" controlId="state">
            <Form.Label>
              State <span className="required-mark text-danger">*</span>
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              onChange={(event) =>
                setFormValues({ ...formValues, state: event.target.value })
              }
              defaultValue={formValues.state}
            >
              <option value="1">Andaman and Nicobar Islands</option>
              <option value="2">Andhra Pradesh</option>
              <option value="3">Arunachal Pradesh</option>
              <option value="4">Assam</option>
              <option value="5">Bihar</option>
              <option value="6">Chandigarh</option>
              <option value="7">Chhattisgarh</option>
              <option value="8">Dadra and Nagar Haveli</option>
              <option value="9">Daman and Diu</option>
              <option value="10">Delhi</option>
              <option value="11">Goa</option>
              <option value="12">Gujarat</option>
              <option value="13">Haryana</option>
              <option value="14">Himachal Pradesh</option>
              <option value="15">Jammu and Kashmir</option>
              <option value="16">Jharkhand</option>
              <option value="17">Karnataka</option>
              <option value="18">Kenmore</option>
              <option value="19">Kerala</option>
              <option value="20">Lakshadweep</option>
              <option value="21">Madhya Pradesh</option>
              <option value="22">Maharashtra</option>
              <option value="23">Manipur</option>
              <option value="24">Meghalaya</option>
              <option value="25">Mizoram</option>
              <option value="26">Nagaland</option>
              <option value="27">Narora</option>
              <option value="28">Natwar</option>
              <option value="29">Odisha</option>
              <option value="30">Paschim Medinipur</option>
              <option value="31">Pondicherry</option>
              <option value="32">Punjab</option>
              <option value="33">Rajasthan</option>
              <option value="34">Sikkim</option>
              <option value="35">Tamil Nadu</option>
              <option value="36">Telangana</option>
              <option value="37">Tripura</option>
              <option value="38">Uttar Pradesh</option>
              <option value="39">Uttarakhand</option>
              <option value="40">Vaishali</option>
              <option value="41">West Bengal</option>
              <option value="42">Badakhshan</option>
              <option value="43">Badgis</option>
              <option value="44">Baglan</option>
              <option value="45">Balkh</option>
              <option value="46">Bamiyan</option>
              <option value="47">Farah</option>
              <option value="48">Faryab</option>
              <option value="49">Gawr</option>
              <option value="50">Gazni</option>
              <option value="51">Herat</option>
              <option value="52">Hilmand</option>
              <option value="53">Jawzjan</option>
              <option value="54">Kabul</option>
              <option value="55">Kapisa</option>
              <option value="56">Khawst</option>
              <option value="57">Kunar</option>
              <option value="58">Lagman</option>
              <option value="59">Lawghar</option>
              <option value="60">Nangarhar</option>
              <option value="61">Nimruz</option>
              <option value="62">Nuristan</option>
              <option value="63">Paktika</option>
              <option value="64">Paktiya</option>
              <option value="65">Parwan</option>
              <option value="66">Qandahar</option>
              <option value="67">Qunduz</option>
              <option value="68">Samangan</option>
              <option value="69">Sar-e Pul</option>
              <option value="70">Takhar</option>
              <option value="71">Uruzgan</option>
              <option value="72">Wardak</option>
              <option value="73">Zabul</option>
              <option value="74">Berat</option>
              <option value="75">Bulqize</option>
              <option value="76">Delvine</option>
              <option value="77">Devoll</option>
              <option value="78">Dibre</option>
              <option value="79">Durres</option>
              <option value="80">Elbasan</option>
              <option value="81">Fier</option>
              <option value="82">Gjirokaster</option>
              <option value="83">Gramsh</option>
              <option value="84">Has</option>
              <option value="85">Kavaje</option>
              <option value="86">Kolonje</option>
              <option value="87">Korce</option>
              <option value="88">Kruje</option>
              <option value="89">Kucove</option>
              <option value="90">Kukes</option>
              <option value="91">Kurbin</option>
              <option value="92">Lezhe</option>
              <option value="93">Librazhd</option>
              <option value="94">Lushnje</option>
              <option value="95">Mallakaster</option>
              <option value="96">Malsi e Madhe</option>
              <option value="97">Mat</option>
              <option value="98">Mirdite</option>
              <option value="99">Peqin</option>
              <option value="100">Permet</option>
              <option value="101">Pogradec</option>
              <option value="102">Puke</option>
              <option value="103">Sarande</option>
              <option value="104">Shkoder</option>
              <option value="105">Skrapar</option>
              <option value="106">Tepelene</option>
              <option value="107">Tirane</option>
              <option value="108">Tropoje</option>
              <option value="109">Vlore</option>
              <option value="110">'Ayn Daflah</option>
              <option value="111">'Ayn Tamushanat</option>
              <option value="112">Adrar</option>
              <option value="113">Algiers</option>
              <option value="114">Annabah</option>
              <option value="115">Bashshar</option>
              <option value="116">Batnah</option>
              <option value="117">Bijayah</option>
              <option value="118">Biskrah</option>
              <option value="119">Blidah</option>
              <option value="120">Buirah</option>
              <option value="121">Bumardas</option>
              <option value="122">Burj Bu Arririj</option>
              <option value="123">Ghalizan</option>
              <option value="124">Ghardayah</option>
              <option value="125">Ilizi</option>
              <option value="126">Jijili</option>
              <option value="127">Jilfah</option>
              <option value="128">Khanshalah</option>
              <option value="129">Masilah</option>
              <option value="130">Midyah</option>
              <option value="131">Milah</option>
              <option value="132">Muaskar</option>
              <option value="133">Mustaghanam</option>
              <option value="134">Naama</option>
              <option value="135">Oran</option>
              <option value="136">Ouargla</option>
              <option value="137">Qalmah</option>
              <option value="138">Qustantinah</option>
              <option value="139">Sakikdah</option>
              <option value="140">Satif</option>
              <option value="141">Sayda'</option>
              <option value="142">Sidi ban-al-'Abbas</option>
              <option value="143">Suq Ahras</option>
              <option value="144">Tamanghasat</option>
              <option value="145">Tibazah</option>
              <option value="146">Tibissah</option>
              <option value="147">Tilimsan</option>
              <option value="148">Tinduf</option>
              <option value="149">Tisamsilt</option>
              <option value="150">Tiyarat</option>
              <option value="151">Tizi Wazu</option>
              <option value="152">Umm-al-Bawaghi</option>
              <option value="153">Wahran</option>
              <option value="154">Warqla</option>
              <option value="155">Wilaya d Alger</option>
              <option value="156">Wilaya de Bejaia</option>
              <option value="157">Wilaya de Constantine</option>
              <option value="158">al-Aghwat</option>
              <option value="159">al-Bayadh</option>
              <option value="160">al-Jaza'ir</option>
              <option value="161">al-Wad</option>
              <option value="162">ash-Shalif</option>
              <option value="163">at-Tarif</option>
              <option value="164">Eastern</option>
              <option value="165">Manu'a</option>
              <option value="166">Swains Island</option>
              <option value="167">Western</option>
              <option value="168">Andorra la Vella</option>
              <option value="169">Canillo</option>
              <option value="170">Encamp</option>
              <option value="171">La Massana</option>
              <option value="172">Les Escaldes</option>
              <option value="173">Ordino</option>
              <option value="174">Sant Julia de Loria</option>
              <option value="175">Bengo</option>
              <option value="176">Benguela</option>
              <option value="177">Bie</option>
              <option value="178">Cabinda</option>
              <option value="179">Cunene</option>
              <option value="180">Huambo</option>
              <option value="181">Huila</option>
              <option value="182">Kuando-Kubango</option>
              <option value="183">Kwanza Norte</option>
              <option value="184">Kwanza Sul</option>
              <option value="185">Luanda</option>
              <option value="186">Lunda Norte</option>
              <option value="187">Lunda Sul</option>
              <option value="188">Malanje</option>
              <option value="189">Moxico</option>
              <option value="190">Namibe</option>
              <option value="191">Uige</option>
              <option value="192">Zaire</option>
              <option value="193">Other Provinces</option>
              <option value="194">Sector claimed by Argentina/Ch</option>
              <option value="195">Sector claimed by Argentina/UK</option>
              <option value="196">Sector claimed by Australia</option>
              <option value="197">Sector claimed by France</option>
              <option value="198">Sector claimed by New Zealand</option>
              <option value="199">Sector claimed by Norway</option>
              <option value="200">Unclaimed Sector</option>
              <option value="201">Barbuda</option>
              <option value="202">Saint George</option>
              <option value="203">Saint John</option>
              <option value="204">Saint Mary</option>
              <option value="205">Saint Paul</option>
              <option value="206">Saint Peter</option>
              <option value="207">Saint Philip</option>
              <option value="208">Buenos Aires</option>
              <option value="209">Catamarca</option>
              <option value="210">Chaco</option>
              <option value="211">Chubut</option>
              <option value="212">Cordoba</option>
              <option value="213">Corrientes</option>
              <option value="214">Distrito Federal</option>
              <option value="215">Entre Rios</option>
              <option value="216">Formosa</option>
              <option value="217">Jujuy</option>
              <option value="218">La Pampa</option>
              <option value="219">La Rioja</option>
              <option value="220">Mendoza</option>
              <option value="221">Misiones</option>
              <option value="222">Neuquen</option>
              <option value="223">Rio Negro</option>
              <option value="224">Salta</option>
              <option value="225">San Juan</option>
              <option value="226">San Luis</option>
              <option value="227">Santa Cruz</option>
              <option value="228">Santa Fe</option>
              <option value="229">Santiago del Estero</option>
              <option value="230">Tierra del Fuego</option>
              <option value="231">Tucuman</option>
              <option value="232">Aragatsotn</option>
              <option value="233">Ararat</option>
              <option value="234">Armavir</option>
              <option value="235">Gegharkunik</option>
              <option value="236">Kotaik</option>
              <option value="237">Lori</option>
              <option value="238">Shirak</option>
              <option value="239">Stepanakert</option>
              <option value="240">Syunik</option>
              <option value="241">Tavush</option>
              <option value="242">Vayots Dzor</option>
              <option value="243">Yerevan</option>
              <option value="244">Aruba</option>
              <option value="245">Auckland</option>
              <option value="246">Australian Capital Territory</option>
              <option value="247">Balgowlah</option>
              <option value="248">Balmain</option>
              <option value="249">Bankstown</option>
              <option value="250">Baulkham Hills</option>
              <option value="251">Bonnet Bay</option>
              <option value="252">Camberwell</option>
              <option value="253">Carole Park</option>
              <option value="254">Castle Hill</option>
              <option value="255">Caulfield</option>
              <option value="256">Chatswood</option>
              <option value="257">Cheltenham</option>
              <option value="258">Cherrybrook</option>
              <option value="259">Clayton</option>
              <option value="260">Collingwood</option>
              <option value="261">Frenchs Forest</option>
              <option value="262">Hawthorn</option>
              <option value="263">Jannnali</option>
              <option value="264">Knoxfield</option>
              <option value="265">Melbourne</option>
              <option value="266">New South Wales</option>
              <option value="267">Northern Territory</option>
              <option value="268">Perth</option>
              <option value="269">Queensland</option>
              <option value="270">South Australia</option>
              <option value="271">Tasmania</option>
              <option value="272">Templestowe</option>
              <option value="273">Victoria</option>
              <option value="274">Werribee south</option>
              <option value="275">Western Australia</option>
              <option value="276">Wheeler</option>
              <option value="277">Bundesland Salzburg</option>
              <option value="278">Bundesland Steiermark</option>
              <option value="279">Bundesland Tirol</option>
              <option value="280">Burgenland</option>
              <option value="281">Carinthia</option>
              <option value="282">Karnten</option>
              <option value="283">Liezen</option>
              <option value="284">Lower Austria</option>
              <option value="285">Niederosterreich</option>
              <option value="286">Oberosterreich</option>
              <option value="287">Salzburg</option>
              <option value="288">Schleswig-Holstein</option>
              <option value="289">Steiermark</option>
              <option value="290">Styria</option>
              <option value="291">Tirol</option>
              <option value="292">Upper Austria</option>
              <option value="293">Vorarlberg</option>
              <option value="294">Wien</option>
              <option value="295">Abseron</option>
              <option value="296">Baki Sahari</option>
              <option value="297">Ganca</option>
              <option value="298">Ganja</option>
              <option value="299">Kalbacar</option>
              <option value="300">Lankaran</option>
              <option value="301">Mil-Qarabax</option>
              <option value="302">Mugan-Salyan</option>
              <option value="303">Nagorni-Qarabax</option>
              <option value="304">Naxcivan</option>
              <option value="305">Priaraks</option>
              <option value="306">Qazax</option>
              <option value="307">Saki</option>
              <option value="308">Sirvan</option>
              <option value="309">Xacmaz</option>
              <option value="310">Abaco</option>
              <option value="311">Acklins Island</option>
              <option value="312">Andros</option>
              <option value="313">Berry Islands</option>
              <option value="314">Biminis</option>
              <option value="315">Cat Island</option>
              <option value="316">Crooked Island</option>
              <option value="317">Eleuthera</option>
              <option value="318">Exuma and Cays</option>
              <option value="319">Grand Bahama</option>
              <option value="320">Inagua Islands</option>
              <option value="321">Long Island</option>
              <option value="322">Mayaguana</option>
              <option value="323">New Providence</option>
              <option value="324">Ragged Island</option>
              <option value="325">Rum Cay</option>
              <option value="326">San Salvador</option>
              <option value="327">'Isa</option>
              <option value="328">Badiyah</option>
              <option value="329">Hidd</option>
              <option value="330">Jidd Hafs</option>
              <option value="331">Mahama</option>
              <option value="332">Manama</option>
              <option value="333">Sitrah</option>
              <option value="334">al-Manamah</option>
              <option value="335">al-Muharraq</option>
              <option value="336">ar-Rifa'a</option>
              <option value="337">Bagar Hat</option>
              <option value="338">Bandarban</option>
              <option value="339">Barguna</option>
              <option value="340">Barisal</option>
              <option value="341">Bhola</option>
              <option value="342">Bogora</option>
              <option value="343">Brahman Bariya</option>
              <option value="344">Chandpur</option>
              <option value="345">Chattagam</option>
              <option value="346">Chittagong Division</option>
              <option value="347">Chuadanga</option>
              <option value="348">Dhaka</option>
              <option value="349">Dinajpur</option>
              <option value="350">Faridpur</option>
              <option value="351">Feni</option>
              <option value="352">Gaybanda</option>
              <option value="353">Gazipur</option>
              <option value="354">Gopalganj</option>
              <option value="355">Habiganj</option>
              <option value="356">Jaipur Hat</option>
              <option value="357">Jamalpur</option>
              <option value="358">Jessor</option>
              <option value="359">Jhalakati</option>
              <option value="360">Jhanaydah</option>
              <option value="361">Khagrachhari</option>
              <option value="362">Khulna</option>
              <option value="363">Kishorganj</option>
              <option value="364">Koks Bazar</option>
              <option value="365">Komilla</option>
              <option value="366">Kurigram</option>
              <option value="367">Kushtiya</option>
              <option value="368">Lakshmipur</option>
              <option value="369">Lalmanir Hat</option>
              <option value="370">Madaripur</option>
              <option value="371">Magura</option>
              <option value="372">Maimansingh</option>
              <option value="373">Manikganj</option>
              <option value="374">Maulvi Bazar</option>
              <option value="375">Meherpur</option>
              <option value="376">Munshiganj</option>
              <option value="377">Naral</option>
              <option value="378">Narayanganj</option>
              <option value="379">Narsingdi</option>
              <option value="380">Nator</option>
              <option value="381">Naugaon</option>
              <option value="382">Nawabganj</option>
              <option value="383">Netrakona</option>
              <option value="384">Nilphamari</option>
              <option value="385">Noakhali</option>
              <option value="386">Pabna</option>
              <option value="387">Panchagarh</option>
              <option value="388">Patuakhali</option>
              <option value="389">Pirojpur</option>
              <option value="390">Rajbari</option>
              <option value="391">Rajshahi</option>
              <option value="392">Rangamati</option>
              <option value="393">Rangpur</option>
              <option value="394">Satkhira</option>
              <option value="395">Shariatpur</option>
              <option value="396">Sherpur</option>
              <option value="397">Silhat</option>
              <option value="398">Sirajganj</option>
              <option value="399">Sunamganj</option>
              <option value="400">Tangayal</option>
              <option value="401">Thakurgaon</option>
              <option value="402">Christ Church</option>
              <option value="403">Saint Andrew</option>
              <option value="404">Saint George</option>
              <option value="405">Saint James</option>
              <option value="406">Saint John</option>
              <option value="407">Saint Joseph</option>
              <option value="408">Saint Lucy</option>
              <option value="409">Saint Michael</option>
              <option value="410">Saint Peter</option>
              <option value="411">Saint Philip</option>
              <option value="412">Saint Thomas</option>
              <option value="413">Brest</option>
              <option value="414">Homjel'</option>
              <option value="415">Hrodna</option>
              <option value="416">Mahiljow</option>
              <option value="417">Mahilyowskaya Voblasts</option>
              <option value="418">Minsk</option>
              <option value="419">Minskaja Voblasts'</option>
              <option value="420">Petrik</option>
              <option value="421">Vicebsk</option>
              <option value="422">Antwerpen</option>
              <option value="423">Berchem</option>
              <option value="424">Brabant</option>
              <option value="425">Brabant Wallon</option>
              <option value="426">Brussel</option>
              <option value="427">East Flanders</option>
              <option value="428">Hainaut</option>
              <option value="429">Liege</option>
              <option value="430">Limburg</option>
              <option value="431">Luxembourg</option>
              <option value="432">Namur</option>
              <option value="433">Ontario</option>
              <option value="434">Oost-Vlaanderen</option>
              <option value="435">Provincie Brabant</option>
              <option value="436">Vlaams-Brabant</option>
              <option value="437">Wallonne</option>
              <option value="438">West-Vlaanderen</option>
              <option value="439">Belize</option>
              <option value="440">Cayo</option>
              <option value="441">Corozal</option>
              <option value="442">Orange Walk</option>
              <option value="443">Stann Creek</option>
              <option value="444">Toledo</option>
              <option value="445">Alibori</option>
              <option value="446">Atacora</option>
              <option value="447">Atlantique</option>
              <option value="448">Borgou</option>
              <option value="449">Collines</option>
              <option value="450">Couffo</option>
              <option value="451">Donga</option>
              <option value="452">Littoral</option>
              <option value="453">Mono</option>
              <option value="454">Oueme</option>
              <option value="455">Plateau</option>
              <option value="456">Zou</option>
              <option value="457">Hamilton</option>
              <option value="458">Saint George</option>
              <option value="459">Bumthang</option>
              <option value="460">Chhukha</option>
              <option value="461">Chirang</option>
              <option value="462">Daga</option>
              <option value="463">Geylegphug</option>
              <option value="464">Ha</option>
              <option value="465">Lhuntshi</option>
              <option value="466">Mongar</option>
              <option value="467">Pemagatsel</option>
              <option value="468">Punakha</option>
              <option value="469">Rinpung</option>
              <option value="470">Samchi</option>
              <option value="471">Samdrup Jongkhar</option>
              <option value="472">Shemgang</option>
              <option value="473">Tashigang</option>
              <option value="474">Timphu</option>
              <option value="475">Tongsa</option>
              <option value="476">Wangdiphodrang</option>
              <option value="477">Beni</option>
              <option value="478">Chuquisaca</option>
              <option value="479">Cochabamba</option>
              <option value="480">La Paz</option>
              <option value="481">Oruro</option>
              <option value="482">Pando</option>
              <option value="483">Potosi</option>
              <option value="484">Santa Cruz</option>
              <option value="485">Tarija</option>
              <option value="486">Federacija Bosna i Hercegovina</option>
              <option value="487">Republika Srpska</option>
              <option value="488">Central Bobonong</option>
              <option value="489">Central Boteti</option>
              <option value="490">Central Mahalapye</option>
              <option value="491">Central Serowe-Palapye</option>
              <option value="492">Central Tutume</option>
              <option value="493">Chobe</option>
              <option value="494">Francistown</option>
              <option value="495">Gaborone</option>
              <option value="496">Ghanzi</option>
              <option value="497">Jwaneng</option>
              <option value="498">Kgalagadi North</option>
              <option value="499">Kgalagadi South</option>
              <option value="500">Kgatleng</option>
              <option value="501">Kweneng</option>
              <option value="502">Lobatse</option>
              <option value="503">Ngamiland</option>
              <option value="504">Ngwaketse</option>
              <option value="505">North East</option>
              <option value="506">Okavango</option>
              <option value="507">Orapa</option>
              <option value="508">Selibe Phikwe</option>
              <option value="509">South East</option>
              <option value="510">Sowa</option>
              <option value="511">Bouvet Island</option>
              <option value="512">Acre</option>
              <option value="513">Alagoas</option>
              <option value="514">Amapa</option>
              <option value="515">Amazonas</option>
              <option value="516">Bahia</option>
              <option value="517">Ceara</option>
              <option value="518">Distrito Federal</option>
              <option value="519">Espirito Santo</option>
              <option value="520">Estado de Sao Paulo</option>
              <option value="521">Goias</option>
              <option value="522">Maranhao</option>
              <option value="523">Mato Grosso</option>
              <option value="524">Mato Grosso do Sul</option>
              <option value="525">Minas Gerais</option>
              <option value="526">Para</option>
              <option value="527">Paraiba</option>
              <option value="528">Parana</option>
              <option value="529">Pernambuco</option>
              <option value="530">Piaui</option>
              <option value="531">Rio Grande do Norte</option>
              <option value="532">Rio Grande do Sul</option>
              <option value="533">Rio de Janeiro</option>
              <option value="534">Rondonia</option>
              <option value="535">Roraima</option>
              <option value="536">Santa Catarina</option>
              <option value="537">Sao Paulo</option>
              <option value="538">Sergipe</option>
              <option value="539">Tocantins</option>
              <option value="540">British Indian Ocean Territory</option>
              <option value="541">Belait</option>
              <option value="542">Brunei-Muara</option>
              <option value="543">Temburong</option>
              <option value="544">Tutong</option>
              <option value="545">Blagoevgrad</option>
              <option value="546">Burgas</option>
              <option value="547">Dobrich</option>
              <option value="548">Gabrovo</option>
              <option value="549">Haskovo</option>
              <option value="550">Jambol</option>
              <option value="551">Kardzhali</option>
              <option value="552">Kjustendil</option>
              <option value="553">Lovech</option>
              <option value="554">Montana</option>
              <option value="555">Oblast Sofiya-Grad</option>
              <option value="556">Pazardzhik</option>
              <option value="557">Pernik</option>
              <option value="558">Pleven</option>
              <option value="559">Plovdiv</option>
              <option value="560">Razgrad</option>
              <option value="561">Ruse</option>
              <option value="562">Shumen</option>
              <option value="563">Silistra</option>
              <option value="564">Sliven</option>
              <option value="565">Smoljan</option>
              <option value="566">Sofija grad</option>
              <option value="567">Sofijska oblast</option>
              <option value="568">Stara Zagora</option>
              <option value="569">Targovishte</option>
              <option value="570">Varna</option>
              <option value="571">Veliko Tarnovo</option>
              <option value="572">Vidin</option>
              <option value="573">Vraca</option>
              <option value="574">Yablaniza</option>
              <option value="575">Bale</option>
              <option value="576">Bam</option>
              <option value="577">Bazega</option>
              <option value="578">Bougouriba</option>
              <option value="579">Boulgou</option>
              <option value="580">Boulkiemde</option>
              <option value="581">Comoe</option>
              <option value="582">Ganzourgou</option>
              <option value="583">Gnagna</option>
              <option value="584">Gourma</option>
              <option value="585">Houet</option>
              <option value="586">Ioba</option>
              <option value="587">Kadiogo</option>
              <option value="588">Kenedougou</option>
              <option value="589">Komandjari</option>
              <option value="590">Kompienga</option>
              <option value="591">Kossi</option>
              <option value="592">Kouritenga</option>
              <option value="593">Kourweogo</option>
              <option value="594">Leraba</option>
              <option value="595">Mouhoun</option>
              <option value="596">Nahouri</option>
              <option value="597">Namentenga</option>
              <option value="598">Noumbiel</option>
              <option value="599">Oubritenga</option>
              <option value="600">Oudalan</option>
              <option value="601">Passore</option>
              <option value="602">Poni</option>
              <option value="603">Sanguie</option>
              <option value="604">Sanmatenga</option>
              <option value="605">Seno</option>
              <option value="606">Sissili</option>
              <option value="607">Soum</option>
              <option value="608">Sourou</option>
              <option value="609">Tapoa</option>
              <option value="610">Tuy</option>
              <option value="611">Yatenga</option>
              <option value="612">Zondoma</option>
              <option value="613">Zoundweogo</option>
              <option value="614">Bubanza</option>
              <option value="615">Bujumbura</option>
              <option value="616">Bururi</option>
              <option value="617">Cankuzo</option>
              <option value="618">Cibitoke</option>
              <option value="619">Gitega</option>
              <option value="620">Karuzi</option>
              <option value="621">Kayanza</option>
              <option value="622">Kirundo</option>
              <option value="623">Makamba</option>
              <option value="624">Muramvya</option>
              <option value="625">Muyinga</option>
              <option value="626">Ngozi</option>
              <option value="627">Rutana</option>
              <option value="628">Ruyigi</option>
              <option value="629">Banteay Mean Chey</option>
              <option value="630">Bat Dambang</option>
              <option value="631">Kampong Cham</option>
              <option value="632">Kampong Chhnang</option>
              <option value="633">Kampong Spoeu</option>
              <option value="634">Kampong Thum</option>
              <option value="635">Kampot</option>
              <option value="636">Kandal</option>
              <option value="637">Kaoh Kong</option>
              <option value="638">Kracheh</option>
              <option value="639">Krong Kaeb</option>
              <option value="640">Krong Pailin</option>
              <option value="641">Krong Preah Sihanouk</option>
              <option value="642">Mondol Kiri</option>
              <option value="643">Otdar Mean Chey</option>
              <option value="644">Phnum Penh</option>
              <option value="645">Pousat</option>
              <option value="646">Preah Vihear</option>
              <option value="647">Prey Veaeng</option>
              <option value="648">Rotanak Kiri</option>
              <option value="649">Siem Reab</option>
              <option value="650">Stueng Traeng</option>
              <option value="651">Svay Rieng</option>
              <option value="652">Takaev</option>
              <option value="653">Adamaoua</option>
              <option value="654">Centre</option>
              <option value="655">Est</option>
              <option value="656">Littoral</option>
              <option value="657">Nord</option>
              <option value="658">Nord Extreme</option>
              <option value="659">Nordouest</option>
              <option value="660">Ouest</option>
              <option value="661">Sud</option>
              <option value="662">Sudouest</option>
              <option value="663">Alberta</option>
              <option value="664">British Columbia</option>
              <option value="665">Manitoba</option>
              <option value="666">New Brunswick</option>
              <option value="667">Newfoundland and Labrador</option>
              <option value="668">Northwest Territories</option>
              <option value="669">Nova Scotia</option>
              <option value="670">Nunavut</option>
              <option value="671">Ontario</option>
              <option value="672">Prince Edward Island</option>
              <option value="673">Quebec</option>
              <option value="674">Saskatchewan</option>
              <option value="675">Yukon</option>
              <option value="676">Boavista</option>
              <option value="677">Brava</option>
              <option value="678">Fogo</option>
              <option value="679">Maio</option>
              <option value="680">Sal</option>
              <option value="681">Santo Antao</option>
              <option value="682">Sao Nicolau</option>
              <option value="683">Sao Tiago</option>
              <option value="684">Sao Vicente</option>
              <option value="685">Grand Cayman</option>
              <option value="686">Bamingui-Bangoran</option>
              <option value="687">Bangui</option>
              <option value="688">Basse-Kotto</option>
              <option value="689">Haut-Mbomou</option>
              <option value="690">Haute-Kotto</option>
              <option value="691">Kemo</option>
              <option value="692">Lobaye</option>
              <option value="693">Mambere-Kadei</option>
              <option value="694">Mbomou</option>
              <option value="695">Nana-Gribizi</option>
              <option value="696">Nana-Mambere</option>
              <option value="697">Ombella Mpoko</option>
              <option value="698">Ouaka</option>
              <option value="699">Ouham</option>
              <option value="700">Ouham-Pende</option>
              <option value="701">Sangha-Mbaere</option>
              <option value="702">Vakaga</option>
              <option value="703">Batha</option>
              <option value="704">Biltine</option>
              <option value="705">Bourkou-Ennedi-Tibesti</option>
              <option value="706">Chari-Baguirmi</option>
              <option value="707">Guera</option>
              <option value="708">Kanem</option>
              <option value="709">Lac</option>
              <option value="710">Logone Occidental</option>
              <option value="711">Logone Oriental</option>
              <option value="712">Mayo-Kebbi</option>
              <option value="713">Moyen-Chari</option>
              <option value="714">Ouaddai</option>
              <option value="715">Salamat</option>
              <option value="716">Tandjile</option>
              <option value="717">Aisen</option>
              <option value="718">Antofagasta</option>
              <option value="719">Araucania</option>
              <option value="720">Atacama</option>
              <option value="721">Bio Bio</option>
              <option value="722">Coquimbo</option>
              <option value="723">Libertador General Bernardo O'</option>
              <option value="724">Los Lagos</option>
              <option value="725">Magellanes</option>
              <option value="726">Maule</option>
              <option value="727">Metropolitana</option>
              <option value="728">Metropolitana de Santiago</option>
              <option value="729">Tarapaca</option>
              <option value="730">Valparaiso</option>
              <option value="731">Anhui</option>
              <option value="732">Anhui Province</option>
              <option value="733">Anhui Sheng</option>
              <option value="734">Aomen</option>
              <option value="735">Beijing</option>
              <option value="736">Beijing Shi</option>
              <option value="737">Chongqing</option>
              <option value="738">Fujian</option>
              <option value="739">Fujian Sheng</option>
              <option value="740">Gansu</option>
              <option value="741">Guangdong</option>
              <option value="742">Guangdong Sheng</option>
              <option value="743">Guangxi</option>
              <option value="744">Guizhou</option>
              <option value="745">Hainan</option>
              <option value="746">Hebei</option>
              <option value="747">Heilongjiang</option>
              <option value="748">Henan</option>
              <option value="749">Hubei</option>
              <option value="750">Hunan</option>
              <option value="751">Jiangsu</option>
              <option value="752">Jiangsu Sheng</option>
              <option value="753">Jiangxi</option>
              <option value="754">Jilin</option>
              <option value="755">Liaoning</option>
              <option value="756">Liaoning Sheng</option>
              <option value="757">Nei Monggol</option>
              <option value="758">Ningxia Hui</option>
              <option value="759">Qinghai</option>
              <option value="760">Shaanxi</option>
              <option value="761">Shandong</option>
              <option value="762">Shandong Sheng</option>
              <option value="763">Shanghai</option>
              <option value="764">Shanxi</option>
              <option value="765">Sichuan</option>
              <option value="766">Tianjin</option>
              <option value="767">Xianggang</option>
              <option value="768">Xinjiang</option>
              <option value="769">Xizang</option>
              <option value="770">Yunnan</option>
              <option value="771">Zhejiang</option>
              <option value="772">Zhejiang Sheng</option>
              <option value="773">Christmas Island</option>
              <option value="774">Cocos (Keeling) Islands</option>
              <option value="775">Amazonas</option>
              <option value="776">Antioquia</option>
              <option value="777">Arauca</option>
              <option value="778">Atlantico</option>
              <option value="779">Bogota</option>
              <option value="780">Bolivar</option>
              <option value="781">Boyaca</option>
              <option value="782">Caldas</option>
              <option value="783">Caqueta</option>
              <option value="784">Casanare</option>
              <option value="785">Cauca</option>
              <option value="786">Cesar</option>
              <option value="787">Choco</option>
              <option value="788">Cordoba</option>
              <option value="789">Cundinamarca</option>
              <option value="790">Guainia</option>
              <option value="791">Guaviare</option>
              <option value="792">Huila</option>
              <option value="793">La Guajira</option>
              <option value="794">Magdalena</option>
              <option value="795">Meta</option>
              <option value="796">Narino</option>
              <option value="797">Norte de Santander</option>
              <option value="798">Putumayo</option>
              <option value="799">Quindio</option>
              <option value="800">Risaralda</option>
              <option value="801">San Andres y Providencia</option>
              <option value="802">Santander</option>
              <option value="803">Sucre</option>
              <option value="804">Tolima</option>
              <option value="805">Valle del Cauca</option>
              <option value="806">Vaupes</option>
              <option value="807">Vichada</option>
              <option value="808">Mwali</option>
              <option value="809">Njazidja</option>
              <option value="810">Nzwani</option>
              <option value="811">Bouenza</option>
              <option value="812">Brazzaville</option>
              <option value="813">Cuvette</option>
              <option value="814">Kouilou</option>
              <option value="815">Lekoumou</option>
              <option value="816">Likouala</option>
              <option value="817">Niari</option>
              <option value="818">Plateaux</option>
              <option value="819">Pool</option>
              <option value="820">Sangha</option>
              <option value="821">Bandundu</option>
              <option value="822">Bas-Congo</option>
              <option value="823">Equateur</option>
              <option value="824">Haut-Congo</option>
              <option value="825">Kasai-Occidental</option>
              <option value="826">Kasai-Oriental</option>
              <option value="827">Katanga</option>
              <option value="828">Kinshasa</option>
              <option value="829">Maniema</option>
              <option value="830">Nord-Kivu</option>
              <option value="831">Sud-Kivu</option>
              <option value="832">Aitutaki</option>
              <option value="833">Atiu</option>
              <option value="834">Mangaia</option>
              <option value="835">Manihiki</option>
              <option value="836">Mauke</option>
              <option value="837">Mitiaro</option>
              <option value="838">Nassau</option>
              <option value="839">Pukapuka</option>
              <option value="840">Rakahanga</option>
              <option value="841">Rarotonga</option>
              <option value="842">Tongareva</option>
              <option value="843">Alajuela</option>
              <option value="844">Cartago</option>
              <option value="845">Guanacaste</option>
              <option value="846">Heredia</option>
              <option value="847">Limon</option>
              <option value="848">Puntarenas</option>
              <option value="849">San Jose</option>
              <option value="850">Abidjan</option>
              <option value="851">Agneby</option>
              <option value="852">Bafing</option>
              <option value="853">Denguele</option>
              <option value="854">Dix-huit Montagnes</option>
              <option value="855">Fromager</option>
              <option value="856">Haut-Sassandra</option>
              <option value="857">Lacs</option>
              <option value="858">Lagunes</option>
              <option value="859">Marahoue</option>
              <option value="860">Moyen-Cavally</option>
              <option value="861">Moyen-Comoe</option>
              <option value="862">N'zi-Comoe</option>
              <option value="863">Sassandra</option>
              <option value="864">Savanes</option>
              <option value="865">Sud-Bandama</option>
              <option value="866">Sud-Comoe</option>
              <option value="867">Vallee du Bandama</option>
              <option value="868">Worodougou</option>
              <option value="869">Zanzan</option>
              <option value="870">Bjelovar-Bilogora</option>
              <option value="871">Dubrovnik-Neretva</option>
              <option value="872">Grad Zagreb</option>
              <option value="873">Istra</option>
              <option value="874">Karlovac</option>
              <option value="875">Koprivnica-Krizhevci</option>
              <option value="876">Krapina-Zagorje</option>
              <option value="877">Lika-Senj</option>
              <option value="878">Medhimurje</option>
              <option value="879">Medimurska Zupanija</option>
              <option value="880">Osijek-Baranja</option>
              <option value="881">Osjecko-Baranjska Zupanija</option>
              <option value="882">Pozhega-Slavonija</option>
              <option value="883">Primorje-Gorski Kotar</option>
              <option value="884">Shibenik-Knin</option>
              <option value="885">Sisak-Moslavina</option>
              <option value="886">Slavonski Brod-Posavina</option>
              <option value="887">Split-Dalmacija</option>
              <option value="888">Varazhdin</option>
              <option value="889">Virovitica-Podravina</option>
              <option value="890">Vukovar-Srijem</option>
              <option value="891">Zadar</option>
              <option value="892">Zagreb</option>
              <option value="893">Camaguey</option>
              <option value="894">Ciego de Avila</option>
              <option value="895">Cienfuegos</option>
              <option value="896">Ciudad de la Habana</option>
              <option value="897">Granma</option>
              <option value="898">Guantanamo</option>
              <option value="899">Habana</option>
              <option value="900">Holguin</option>
              <option value="901">Isla de la Juventud</option>
              <option value="902">La Habana</option>
              <option value="903">Las Tunas</option>
              <option value="904">Matanzas</option>
              <option value="905">Pinar del Rio</option>
              <option value="906">Sancti Spiritus</option>
              <option value="907">Santiago de Cuba</option>
              <option value="908">Villa Clara</option>
              <option value="909">Government controlled area</option>
              <option value="910">Limassol</option>
              <option value="911">Nicosia District</option>
              <option value="912">Paphos</option>
              <option value="913">Turkish controlled area</option>
              <option value="914">Central Bohemian</option>
              <option value="915">Frycovice</option>
              <option value="916">Jihocesky Kraj</option>
              <option value="917">Jihochesky</option>
              <option value="918">Jihomoravsky</option>
              <option value="919">Karlovarsky</option>
              <option value="920">Klecany</option>
              <option value="921">Kralovehradecky</option>
              <option value="922">Liberecky</option>
              <option value="923">Lipov</option>
              <option value="924">Moravskoslezsky</option>
              <option value="925">Olomoucky</option>
              <option value="926">Olomoucky Kraj</option>
              <option value="927">Pardubicky</option>
              <option value="928">Plzensky</option>
              <option value="929">Praha</option>
              <option value="930">Rajhrad</option>
              <option value="931">Smirice</option>
              <option value="932">South Moravian</option>
              <option value="933">Straz nad Nisou</option>
              <option value="934">Stredochesky</option>
              <option value="935">Unicov</option>
              <option value="936">Ustecky</option>
              <option value="937">Valletta</option>
              <option value="938">Velesin</option>
              <option value="939">Vysochina</option>
              <option value="940">Zlinsky</option>
              <option value="941">Arhus</option>
              <option value="942">Bornholm</option>
              <option value="943">Frederiksborg</option>
              <option value="944">Fyn</option>
              <option value="945">Hovedstaden</option>
              <option value="946">Kobenhavn</option>
              <option value="947">Kobenhavns Amt</option>
              <option value="948">Kobenhavns Kommune</option>
              <option value="949">Nordjylland</option>
              <option value="950">Ribe</option>
              <option value="951">Ringkobing</option>
              <option value="952">Roervig</option>
              <option value="953">Roskilde</option>
              <option value="954">Roslev</option>
              <option value="955">Sjaelland</option>
              <option value="956">Soeborg</option>
              <option value="957">Sonderjylland</option>
              <option value="958">Storstrom</option>
              <option value="959">Syddanmark</option>
              <option value="960">Toelloese</option>
              <option value="961">Vejle</option>
              <option value="962">Vestsjalland</option>
              <option value="963">Viborg</option>
              <option value="964">'Ali Sabih</option>
              <option value="965">Dikhil</option>
              <option value="966">Jibuti</option>
              <option value="967">Tajurah</option>
              <option value="968">Ubuk</option>
              <option value="969">Saint Andrew</option>
              <option value="970">Saint David</option>
              <option value="971">Saint George</option>
              <option value="972">Saint John</option>
              <option value="973">Saint Joseph</option>
              <option value="974">Saint Luke</option>
              <option value="975">Saint Mark</option>
              <option value="976">Saint Patrick</option>
              <option value="977">Saint Paul</option>
              <option value="978">Saint Peter</option>
              <option value="979">Azua</option>
              <option value="980">Bahoruco</option>
              <option value="981">Barahona</option>
              <option value="982">Dajabon</option>
              <option value="983">Distrito Nacional</option>
              <option value="984">Duarte</option>
              <option value="985">El Seybo</option>
              <option value="986">Elias Pina</option>
              <option value="987">Espaillat</option>
              <option value="988">Hato Mayor</option>
              <option value="989">Independencia</option>
              <option value="990">La Altagracia</option>
              <option value="991">La Romana</option>
              <option value="992">La Vega</option>
              <option value="993">Maria Trinidad Sanchez</option>
              <option value="994">Monsenor Nouel</option>
              <option value="995">Monte Cristi</option>
              <option value="996">Monte Plata</option>
              <option value="997">Pedernales</option>
              <option value="998">Peravia</option>
              <option value="999">Puerto Plata</option>
              <option value="1000">Salcedo</option>
              <option value="1001">Samana</option>
              <option value="1002">San Cristobal</option>
              <option value="1003">San Juan</option>
              <option value="1004">San Pedro de Macoris</option>
              <option value="1005">Sanchez Ramirez</option>
              <option value="1006">Santiago</option>
              <option value="1007">Santiago Rodriguez</option>
              <option value="1008">Valverde</option>
              <option value="1022">Azuay</option>
              <option value="1023">Bolivar</option>
              <option value="1024">Canar</option>
              <option value="1025">Carchi</option>
              <option value="1026">Chimborazo</option>
              <option value="1027">Cotopaxi</option>
              <option value="1028">El Oro</option>
              <option value="1029">Esmeraldas</option>
              <option value="1030">Galapagos</option>
              <option value="1031">Guayas</option>
              <option value="1032">Imbabura</option>
              <option value="1033">Loja</option>
              <option value="1034">Los Rios</option>
              <option value="1035">Manabi</option>
              <option value="1036">Morona Santiago</option>
              <option value="1037">Napo</option>
              <option value="1038">Orellana</option>
              <option value="1039">Pastaza</option>
              <option value="1040">Pichincha</option>
              <option value="1041">Sucumbios</option>
              <option value="1042">Tungurahua</option>
              <option value="1043">Zamora Chinchipe</option>
              <option value="1044">Aswan</option>
              <option value="1045">Asyut</option>
              <option value="1046">Bani Suwayf</option>
              <option value="1047">Bur Sa'id</option>
              <option value="1048">Cairo</option>
              <option value="1049">Dumyat</option>
              <option value="1050">Kafr-ash-Shaykh</option>
              <option value="1051">Matruh</option>
              <option value="1052">Muhafazat ad Daqahliyah</option>
              <option value="1053">Muhafazat al Fayyum</option>
              <option value="1054">Muhafazat al Gharbiyah</option>
              <option value="1055">Muhafazat al Iskandariyah</option>
              <option value="1056">Muhafazat al Qahirah</option>
              <option value="1057">Qina</option>
              <option value="1058">Sawhaj</option>
              <option value="1059">Sina al-Janubiyah</option>
              <option value="1060">Sina ash-Shamaliyah</option>
              <option value="1061">ad-Daqahliyah</option>
              <option value="1062">al-Bahr-al-Ahmar</option>
              <option value="1063">al-Buhayrah</option>
              <option value="1064">al-Fayyum</option>
              <option value="1065">al-Gharbiyah</option>
              <option value="1066">al-Iskandariyah</option>
              <option value="1067">al-Ismailiyah</option>
              <option value="1068">al-Jizah</option>
              <option value="1069">al-Minufiyah</option>
              <option value="1070">al-Minya</option>
              <option value="1071">al-Qahira</option>
              <option value="1072">al-Qalyubiyah</option>
              <option value="1073">al-Uqsur</option>
              <option value="1074">al-Wadi al-Jadid</option>
              <option value="1075">as-Suways</option>
              <option value="1076">ash-Sharqiyah</option>
              <option value="1077">Ahuachapan</option>
              <option value="1078">Cabanas</option>
              <option value="1079">Chalatenango</option>
              <option value="1080">Cuscatlan</option>
              <option value="1081">La Libertad</option>
              <option value="1082">La Paz</option>
              <option value="1083">La Union</option>
              <option value="1084">Morazan</option>
              <option value="1085">San Miguel</option>
              <option value="1086">San Salvador</option>
              <option value="1087">San Vicente</option>
              <option value="1088">Santa Ana</option>
              <option value="1089">Sonsonate</option>
              <option value="1090">Usulutan</option>
              <option value="1091">Annobon</option>
              <option value="1092">Bioko Norte</option>
              <option value="1093">Bioko Sur</option>
              <option value="1094">Centro Sur</option>
              <option value="1095">Kie-Ntem</option>
              <option value="1096">Litoral</option>
              <option value="1097">Wele-Nzas</option>
              <option value="1098">Anseba</option>
              <option value="1099">Debub</option>
              <option value="1100">Debub-Keih-Bahri</option>
              <option value="1101">Gash-Barka</option>
              <option value="1102">Maekel</option>
              <option value="1103">Semien-Keih-Bahri</option>
              <option value="1104">Harju</option>
              <option value="1105">Hiiu</option>
              <option value="1106">Ida-Viru</option>
              <option value="1107">Jarva</option>
              <option value="1108">Jogeva</option>
              <option value="1109">Laane</option>
              <option value="1110">Laane-Viru</option>
              <option value="1111">Parnu</option>
              <option value="1112">Polva</option>
              <option value="1113">Rapla</option>
              <option value="1114">Saare</option>
              <option value="1115">Tartu</option>
              <option value="1116">Valga</option>
              <option value="1117">Viljandi</option>
              <option value="1118">Voru</option>
              <option value="1119">Addis Abeba</option>
              <option value="1120">Afar</option>
              <option value="1121">Amhara</option>
              <option value="1122">Benishangul</option>
              <option value="1123">Diredawa</option>
              <option value="1124">Gambella</option>
              <option value="1125">Harar</option>
              <option value="1126">Jigjiga</option>
              <option value="1127">Mekele</option>
              <option value="1128">Oromia</option>
              <option value="1129">Somali</option>
              <option value="1130">Southern</option>
              <option value="1131">Tigray</option>
              <option value="1135">Falkland Islands</option>
              <option value="1136">South Georgia</option>
              <option value="1137">Klaksvik</option>
              <option value="1138">Nor ara Eysturoy</option>
              <option value="1139">Nor oy</option>
              <option value="1140">Sandoy</option>
              <option value="1141">Streymoy</option>
              <option value="1142">Su uroy</option>
              <option value="1143">Sy ra Eysturoy</option>
              <option value="1144">Torshavn</option>
              <option value="1145">Vaga</option>
              <option value="1146">Central</option>
              <option value="1147">Eastern</option>
              <option value="1148">Northern</option>
              <option value="1149">South Pacific</option>
              <option value="1150">Western</option>
              <option value="1151">Ahvenanmaa</option>
              <option value="1152">Etela-Karjala</option>
              <option value="1153">Etela-Pohjanmaa</option>
              <option value="1154">Etela-Savo</option>
              <option value="1155">Etela-Suomen Laani</option>
              <option value="1156">Ita-Suomen Laani</option>
              <option value="1157">Ita-Uusimaa</option>
              <option value="1158">Kainuu</option>
              <option value="1159">Kanta-Hame</option>
              <option value="1160">Keski-Pohjanmaa</option>
              <option value="1161">Keski-Suomi</option>
              <option value="1162">Kymenlaakso</option>
              <option value="1163">Lansi-Suomen Laani</option>
              <option value="1164">Lappi</option>
              <option value="1165">Northern Savonia</option>
              <option value="1166">Ostrobothnia</option>
              <option value="1167">Oulun Laani</option>
              <option value="1168">Paijat-Hame</option>
              <option value="1169">Pirkanmaa</option>
              <option value="1170">Pohjanmaa</option>
              <option value="1171">Pohjois-Karjala</option>
              <option value="1172">Pohjois-Pohjanmaa</option>
              <option value="1173">Pohjois-Savo</option>
              <option value="1174">Saarijarvi</option>
              <option value="1175">Satakunta</option>
              <option value="1176">Southern Savonia</option>
              <option value="1177">Tavastia Proper</option>
              <option value="1178">Uleaborgs Lan</option>
              <option value="1179">Uusimaa</option>
              <option value="1180">Varsinais-Suomi</option>
              <option value="1181">Ain</option>
              <option value="1182">Aisne</option>
              <option value="1183">Albi Le Sequestre</option>
              <option value="1184">Allier</option>
              <option value="1185">Alpes-Cote dAzur</option>
              <option value="1186">Alpes-Maritimes</option>
              <option value="1187">Alpes-de-Haute-Provence</option>
              <option value="1188">Alsace</option>
              <option value="1189">Aquitaine</option>
              <option value="1190">Ardeche</option>
              <option value="1191">Ardennes</option>
              <option value="1192">Ariege</option>
              <option value="1193">Aube</option>
              <option value="1194">Aude</option>
              <option value="1195">Auvergne</option>
              <option value="1196">Aveyron</option>
              <option value="1197">Bas-Rhin</option>
              <option value="1198">Basse-Normandie</option>
              <option value="1199">Bouches-du-Rhone</option>
              <option value="1200">Bourgogne</option>
              <option value="1201">Bretagne</option>
              <option value="1202">Brittany</option>
              <option value="1203">Burgundy</option>
              <option value="1204">Calvados</option>
              <option value="1205">Cantal</option>
              <option value="1206">Cedex</option>
              <option value="1207">Centre</option>
              <option value="1208">Charente</option>
              <option value="1209">Charente-Maritime</option>
              <option value="1210">Cher</option>
              <option value="1211">Correze</option>
              <option value="1212">Corse-du-Sud</option>
              <option value="1213">Cote-d'Or</option>
              <option value="1214">Cotes-d'Armor</option>
              <option value="1215">Creuse</option>
              <option value="1216">Crolles</option>
              <option value="1217">Deux-Sevres</option>
              <option value="1218">Dordogne</option>
              <option value="1219">Doubs</option>
              <option value="1220">Drome</option>
              <option value="1221">Essonne</option>
              <option value="1222">Eure</option>
              <option value="1223">Eure-et-Loir</option>
              <option value="1224">Feucherolles</option>
              <option value="1225">Finistere</option>
              <option value="1226">Franche-Comte</option>
              <option value="1227">Gard</option>
              <option value="1228">Gers</option>
              <option value="1229">Gironde</option>
              <option value="1230">Haut-Rhin</option>
              <option value="1231">Haute-Corse</option>
              <option value="1232">Haute-Garonne</option>
              <option value="1233">Haute-Loire</option>
              <option value="1234">Haute-Marne</option>
              <option value="1235">Haute-Saone</option>
              <option value="1236">Haute-Savoie</option>
              <option value="1237">Haute-Vienne</option>
              <option value="1238">Hautes-Alpes</option>
              <option value="1239">Hautes-Pyrenees</option>
              <option value="1240">Hauts-de-Seine</option>
              <option value="1241">Herault</option>
              <option value="1242">Ile-de-France</option>
              <option value="1243">Ille-et-Vilaine</option>
              <option value="1244">Indre</option>
              <option value="1245">Indre-et-Loire</option>
              <option value="1246">Isere</option>
              <option value="1247">Jura</option>
              <option value="1248">Klagenfurt</option>
              <option value="1249">Landes</option>
              <option value="1250">Languedoc-Roussillon</option>
              <option value="1251">Larcay</option>
              <option value="1252">Le Castellet</option>
              <option value="1253">Le Creusot</option>
              <option value="1254">Limousin</option>
              <option value="1255">Loir-et-Cher</option>
              <option value="1256">Loire</option>
              <option value="1257">Loire-Atlantique</option>
              <option value="1258">Loiret</option>
              <option value="1259">Lorraine</option>
              <option value="1260">Lot</option>
              <option value="1261">Lot-et-Garonne</option>
              <option value="1262">Lower Normandy</option>
              <option value="1263">Lozere</option>
              <option value="1264">Maine-et-Loire</option>
              <option value="1265">Manche</option>
              <option value="1266">Marne</option>
              <option value="1267">Mayenne</option>
              <option value="1268">Meurthe-et-Moselle</option>
              <option value="1269">Meuse</option>
              <option value="1270">Midi-Pyrenees</option>
              <option value="1271">Morbihan</option>
              <option value="1272">Moselle</option>
              <option value="1273">Nievre</option>
              <option value="1274">Nord</option>
              <option value="1275">Nord-Pas-de-Calais</option>
              <option value="1276">Oise</option>
              <option value="1277">Orne</option>
              <option value="1278">Paris</option>
              <option value="1279">Pas-de-Calais</option>
              <option value="1280">Pays de la Loire</option>
              <option value="1281">Pays-de-la-Loire</option>
              <option value="1282">Picardy</option>
              <option value="1283">Puy-de-Dome</option>
              <option value="1284">Pyrenees-Atlantiques</option>
              <option value="1285">Pyrenees-Orientales</option>
              <option value="1286">Quelmes</option>
              <option value="1287">Rhone</option>
              <option value="1288">Rhone-Alpes</option>
              <option value="1289">Saint Ouen</option>
              <option value="1290">Saint Viatre</option>
              <option value="1291">Saone-et-Loire</option>
              <option value="1292">Sarthe</option>
              <option value="1293">Savoie</option>
              <option value="1294">Seine-Maritime</option>
              <option value="1295">Seine-Saint-Denis</option>
              <option value="1296">Seine-et-Marne</option>
              <option value="1297">Somme</option>
              <option value="1298">Sophia Antipolis</option>
              <option value="1299">Souvans</option>
              <option value="1300">Tarn</option>
              <option value="1301">Tarn-et-Garonne</option>
              <option value="1302">Territoire de Belfort</option>
              <option value="1303">Treignac</option>
              <option value="1304">Upper Normandy</option>
              <option value="1305">Val-d'Oise</option>
              <option value="1306">Val-de-Marne</option>
              <option value="1307">Var</option>
              <option value="1308">Vaucluse</option>
              <option value="1309">Vellise</option>
              <option value="1310">Vendee</option>
              <option value="1311">Vienne</option>
              <option value="1312">Vosges</option>
              <option value="1313">Yonne</option>
              <option value="1314">Yvelines</option>
              <option value="1315">Cayenne</option>
              <option value="1316">Saint-Laurent-du-Maroni</option>
              <option value="1317">Iles du Vent</option>
              <option value="1318">Iles sous le Vent</option>
              <option value="1319">Marquesas</option>
              <option value="1320">Tuamotu</option>
              <option value="1321">Tubuai</option>
              <option value="1322">Amsterdam</option>
              <option value="1323">Crozet Islands</option>
              <option value="1324">Kerguelen</option>
              <option value="1325">Estuaire</option>
              <option value="1326">Haut-Ogooue</option>
              <option value="1327">Moyen-Ogooue</option>
              <option value="1328">Ngounie</option>
              <option value="1329">Nyanga</option>
              <option value="1330">Ogooue-Ivindo</option>
              <option value="1331">Ogooue-Lolo</option>
              <option value="1332">Ogooue-Maritime</option>
              <option value="1333">Woleu-Ntem</option>
              <option value="1334">Banjul</option>
              <option value="1335">Basse</option>
              <option value="1336">Brikama</option>
              <option value="1337">Janjanbureh</option>
              <option value="1338">Kanifing</option>
              <option value="1339">Kerewan</option>
              <option value="1340">Kuntaur</option>
              <option value="1341">Mansakonko</option>
              <option value="1342">Abhasia</option>
              <option value="1343">Ajaria</option>
              <option value="1344">Guria</option>
              <option value="1345">Imereti</option>
              <option value="1346">Kaheti</option>
              <option value="1347">Kvemo Kartli</option>
              <option value="1348">Mcheta-Mtianeti</option>
              <option value="1349">Racha</option>
              <option value="1350">Samagrelo-Zemo Svaneti</option>
              <option value="1351">Samche-Zhavaheti</option>
              <option value="1352">Shida Kartli</option>
              <option value="1353">Tbilisi</option>
              <option value="1354">Auvergne</option>
              <option value="1355">Baden-Wurttemberg</option>
              <option value="1356">Bavaria</option>
              <option value="1357">Bayern</option>
              <option value="1358">Beilstein Wurtt</option>
              <option value="1359">Berlin</option>
              <option value="1360">Brandenburg</option>
              <option value="1361">Bremen</option>
              <option value="1362">Dreisbach</option>
              <option value="1363">Freistaat Bayern</option>
              <option value="1364">Hamburg</option>
              <option value="1365">Hannover</option>
              <option value="1366">Heroldstatt</option>
              <option value="1367">Hessen</option>
              <option value="1368">Kortenberg</option>
              <option value="1369">Laasdorf</option>
              <option value="1370">Land Baden-Wurttemberg</option>
              <option value="1371">Land Bayern</option>
              <option value="1372">Land Brandenburg</option>
              <option value="1373">Land Hessen</option>
              <option value="1374">Land Mecklenburg-Vorpommern</option>
              <option value="1375">Land Nordrhein-Westfalen</option>
              <option value="1376">Land Rheinland-Pfalz</option>
              <option value="1377">Land Sachsen</option>
              <option value="1378">Land Sachsen-Anhalt</option>
              <option value="1379">Land Thuringen</option>
              <option value="1380">Lower Saxony</option>
              <option value="1381">Mecklenburg-Vorpommern</option>
              <option value="1382">Mulfingen</option>
              <option value="1383">Munich</option>
              <option value="1384">Neubeuern</option>
              <option value="1385">Niedersachsen</option>
              <option value="1386">Noord-Holland</option>
              <option value="1387">Nordrhein-Westfalen</option>
              <option value="1388">North Rhine-Westphalia</option>
              <option value="1389">Osterode</option>
              <option value="1390">Rheinland-Pfalz</option>
              <option value="1391">Rhineland-Palatinate</option>
              <option value="1392">Saarland</option>
              <option value="1393">Sachsen</option>
              <option value="1394">Sachsen-Anhalt</option>
              <option value="1395">Saxony</option>
              <option value="1396">Schleswig-Holstein</option>
              <option value="1397">Thuringia</option>
              <option value="1398">Webling</option>
              <option value="1399">Weinstrabe</option>
              <option value="1400">schlobborn</option>
              <option value="1401">Ashanti</option>
              <option value="1402">Brong-Ahafo</option>
              <option value="1403">Central</option>
              <option value="1404">Eastern</option>
              <option value="1405">Greater Accra</option>
              <option value="1406">Northern</option>
              <option value="1407">Upper East</option>
              <option value="1408">Upper West</option>
              <option value="1409">Volta</option>
              <option value="1410">Western</option>
              <option value="1411">Gibraltar</option>
              <option value="1412">Acharnes</option>
              <option value="1413">Ahaia</option>
              <option value="1414">Aitolia kai Akarnania</option>
              <option value="1415">Argolis</option>
              <option value="1416">Arkadia</option>
              <option value="1417">Arta</option>
              <option value="1418">Attica</option>
              <option value="1419">Attiki</option>
              <option value="1420">Ayion Oros</option>
              <option value="1421">Crete</option>
              <option value="1422">Dodekanisos</option>
              <option value="1423">Drama</option>
              <option value="1424">Evia</option>
              <option value="1425">Evritania</option>
              <option value="1426">Evros</option>
              <option value="1427">Evvoia</option>
              <option value="1428">Florina</option>
              <option value="1429">Fokis</option>
              <option value="1430">Fthiotis</option>
              <option value="1431">Grevena</option>
              <option value="1432">Halandri</option>
              <option value="1433">Halkidiki</option>
              <option value="1434">Hania</option>
              <option value="1435">Heraklion</option>
              <option value="1436">Hios</option>
              <option value="1437">Ilia</option>
              <option value="1438">Imathia</option>
              <option value="1439">Ioannina</option>
              <option value="1440">Iraklion</option>
              <option value="1441">Karditsa</option>
              <option value="1442">Kastoria</option>
              <option value="1443">Kavala</option>
              <option value="1444">Kefallinia</option>
              <option value="1445">Kerkira</option>
              <option value="1446">Kiklades</option>
              <option value="1447">Kilkis</option>
              <option value="1448">Korinthia</option>
              <option value="1449">Kozani</option>
              <option value="1450">Lakonia</option>
              <option value="1451">Larisa</option>
              <option value="1452">Lasithi</option>
              <option value="1453">Lesvos</option>
              <option value="1454">Levkas</option>
              <option value="1455">Magnisia</option>
              <option value="1456">Messinia</option>
              <option value="1457">Nomos Attikis</option>
              <option value="1458">Nomos Zakynthou</option>
              <option value="1459">Pella</option>
              <option value="1460">Pieria</option>
              <option value="1461">Piraios</option>
              <option value="1462">Preveza</option>
              <option value="1463">Rethimni</option>
              <option value="1464">Rodopi</option>
              <option value="1465">Samos</option>
              <option value="1466">Serrai</option>
              <option value="1467">Thesprotia</option>
              <option value="1468">Thessaloniki</option>
              <option value="1469">Trikala</option>
              <option value="1470">Voiotia</option>
              <option value="1471">West Greece</option>
              <option value="1472">Xanthi</option>
              <option value="1473">Zakinthos</option>
              <option value="1474">Aasiaat</option>
              <option value="1475">Ammassalik</option>
              <option value="1476">Illoqqortoormiut</option>
              <option value="1477">Ilulissat</option>
              <option value="1478">Ivittuut</option>
              <option value="1479">Kangaatsiaq</option>
              <option value="1480">Maniitsoq</option>
              <option value="1481">Nanortalik</option>
              <option value="1482">Narsaq</option>
              <option value="1483">Nuuk</option>
              <option value="1484">Paamiut</option>
              <option value="1485">Qaanaaq</option>
              <option value="1486">Qaqortoq</option>
              <option value="1487">Qasigiannguit</option>
              <option value="1488">Qeqertarsuaq</option>
              <option value="1489">Sisimiut</option>
              <option value="1490">Udenfor kommunal inddeling</option>
              <option value="1491">Upernavik</option>
              <option value="1492">Uummannaq</option>
              <option value="1493">Carriacou-Petite Martinique</option>
              <option value="1494">Saint Andrew</option>
              <option value="1495">Saint Davids</option>
              <option value="1496">Saint George's</option>
              <option value="1497">Saint John</option>
              <option value="1498">Saint Mark</option>
              <option value="1499">Saint Patrick</option>
              <option value="1500">Basse-Terre</option>
              <option value="1501">Grande-Terre</option>
              <option value="1502">Iles des Saintes</option>
              <option value="1503">La Desirade</option>
              <option value="1504">Marie-Galante</option>
              <option value="1505">Saint Barthelemy</option>
              <option value="1506">Saint Martin</option>
              <option value="1507">Agana Heights</option>
              <option value="1508">Agat</option>
              <option value="1509">Barrigada</option>
              <option value="1510">Chalan-Pago-Ordot</option>
              <option value="1511">Dededo</option>
              <option value="1512">Hagatna</option>
              <option value="1513">Inarajan</option>
              <option value="1514">Mangilao</option>
              <option value="1515">Merizo</option>
              <option value="1516">Mongmong-Toto-Maite</option>
              <option value="1517">Santa Rita</option>
              <option value="1518">Sinajana</option>
              <option value="1519">Talofofo</option>
              <option value="1520">Tamuning</option>
              <option value="1521">Yigo</option>
              <option value="1522">Yona</option>
              <option value="1523">Alta Verapaz</option>
              <option value="1524">Baja Verapaz</option>
              <option value="1525">Chimaltenango</option>
              <option value="1526">Chiquimula</option>
              <option value="1527">El Progreso</option>
              <option value="1528">Escuintla</option>
              <option value="1529">Guatemala</option>
              <option value="1530">Huehuetenango</option>
              <option value="1531">Izabal</option>
              <option value="1532">Jalapa</option>
              <option value="1533">Jutiapa</option>
              <option value="1534">Peten</option>
              <option value="1535">Quezaltenango</option>
              <option value="1536">Quiche</option>
              <option value="1537">Retalhuleu</option>
              <option value="1538">Sacatepequez</option>
              <option value="1539">San Marcos</option>
              <option value="1540">Santa Rosa</option>
              <option value="1541">Solola</option>
              <option value="1542">Suchitepequez</option>
              <option value="1543">Totonicapan</option>
              <option value="1544">Zacapa</option>
              <option value="1557">Beyla</option>
              <option value="1558">Boffa</option>
              <option value="1559">Boke</option>
              <option value="1560">Conakry</option>
              <option value="1561">Coyah</option>
              <option value="1562">Dabola</option>
              <option value="1563">Dalaba</option>
              <option value="1564">Dinguiraye</option>
              <option value="1565">Faranah</option>
              <option value="1566">Forecariah</option>
              <option value="1567">Fria</option>
              <option value="1568">Gaoual</option>
              <option value="1569">Gueckedou</option>
              <option value="1570">Kankan</option>
              <option value="1571">Kerouane</option>
              <option value="1572">Kindia</option>
              <option value="1573">Kissidougou</option>
              <option value="1574">Koubia</option>
              <option value="1575">Koundara</option>
              <option value="1576">Kouroussa</option>
              <option value="1577">Labe</option>
              <option value="1578">Lola</option>
              <option value="1579">Macenta</option>
              <option value="1580">Mali</option>
              <option value="1581">Mamou</option>
              <option value="1582">Mandiana</option>
              <option value="1583">Nzerekore</option>
              <option value="1584">Pita</option>
              <option value="1585">Siguiri</option>
              <option value="1586">Telimele</option>
              <option value="1587">Tougue</option>
              <option value="1588">Yomou</option>
              <option value="1589">Bafata</option>
              <option value="1590">Bissau</option>
              <option value="1591">Bolama</option>
              <option value="1592">Cacheu</option>
              <option value="1593">Gabu</option>
              <option value="1594">Oio</option>
              <option value="1595">Quinara</option>
              <option value="1596">Tombali</option>
              <option value="1597">Barima-Waini</option>
              <option value="1598">Cuyuni-Mazaruni</option>
              <option value="1599">Demerara-Mahaica</option>
              <option value="1600">East Berbice-Corentyne</option>
              <option value="1601">Essequibo Islands-West Demerar</option>
              <option value="1602">Mahaica-Berbice</option>
              <option value="1603">Pomeroon-Supenaam</option>
              <option value="1604">Potaro-Siparuni</option>
              <option value="1605">Upper Demerara-Berbice</option>
              <option value="1606">Upper Takutu-Upper Essequibo</option>
              <option value="1607">Artibonite</option>
              <option value="1608">Centre</option>
              <option value="1609">Grand'Anse</option>
              <option value="1610">Nord</option>
              <option value="1611">Nord-Est</option>
              <option value="1612">Nord-Ouest</option>
              <option value="1613">Ouest</option>
              <option value="1614">Sud</option>
              <option value="1615">Sud-Est</option>
              <option value="1616">Heard and McDonald Islands</option>
              <option value="1617">Atlantida</option>
              <option value="1618">Choluteca</option>
              <option value="1619">Colon</option>
              <option value="1620">Comayagua</option>
              <option value="1621">Copan</option>
              <option value="1622">Cortes</option>
              <option value="1623">Distrito Central</option>
              <option value="1624">El Paraiso</option>
              <option value="1625">Francisco Morazan</option>
              <option value="1626">Gracias a Dios</option>
              <option value="1627">Intibuca</option>
              <option value="1628">Islas de la Bahia</option>
              <option value="1629">La Paz</option>
              <option value="1630">Lempira</option>
              <option value="1631">Ocotepeque</option>
              <option value="1632">Olancho</option>
              <option value="1633">Santa Barbara</option>
              <option value="1634">Valle</option>
              <option value="1635">Yoro</option>
              <option value="1636">Hong Kong</option>
              <option value="1637">Bacs-Kiskun</option>
              <option value="1638">Baranya</option>
              <option value="1639">Bekes</option>
              <option value="1640">Borsod-Abauj-Zemplen</option>
              <option value="1641">Budapest</option>
              <option value="1642">Csongrad</option>
              <option value="1643">Fejer</option>
              <option value="1644">Gyor-Moson-Sopron</option>
              <option value="1645">Hajdu-Bihar</option>
              <option value="1646">Heves</option>
              <option value="1647">Jasz-Nagykun-Szolnok</option>
              <option value="1648">Komarom-Esztergom</option>
              <option value="1649">Nograd</option>
              <option value="1650">Pest</option>
              <option value="1651">Somogy</option>
              <option value="1652">Szabolcs-Szatmar-Bereg</option>
              <option value="1653">Tolna</option>
              <option value="1654">Vas</option>
              <option value="1655">Veszprem</option>
              <option value="1656">Zala</option>
              <option value="1657">Austurland</option>
              <option value="1658">Gullbringusysla</option>
              <option value="1659">Hofu borgarsva i</option>
              <option value="1660">Nor urland eystra</option>
              <option value="1661">Nor urland vestra</option>
              <option value="1662">Su urland</option>
              <option value="1663">Su urnes</option>
              <option value="1664">Vestfir ir</option>
              <option value="1665">Vesturland</option>
              <option value="1666">Aceh</option>
              <option value="1667">Bali</option>
              <option value="1668">Bangka-Belitung</option>
              <option value="1669">Banten</option>
              <option value="1670">Bengkulu</option>
              <option value="1671">Gandaria</option>
              <option value="1672">Gorontalo</option>
              <option value="1673">Jakarta</option>
              <option value="1674">Jambi</option>
              <option value="1675">Jawa Barat</option>
              <option value="1676">Jawa Tengah</option>
              <option value="1677">Jawa Timur</option>
              <option value="1678">Kalimantan Barat</option>
              <option value="1679">Kalimantan Selatan</option>
              <option value="1680">Kalimantan Tengah</option>
              <option value="1681">Kalimantan Timur</option>
              <option value="1682">Kendal</option>
              <option value="1683">Lampung</option>
              <option value="1684">Maluku</option>
              <option value="1685">Maluku Utara</option>
              <option value="1686">Nusa Tenggara Barat</option>
              <option value="1687">Nusa Tenggara Timur</option>
              <option value="1688">Papua</option>
              <option value="1689">Riau</option>
              <option value="1690">Riau Kepulauan</option>
              <option value="1691">Solo</option>
              <option value="1692">Sulawesi Selatan</option>
              <option value="1693">Sulawesi Tengah</option>
              <option value="1694">Sulawesi Tenggara</option>
              <option value="1695">Sulawesi Utara</option>
              <option value="1696">Sumatera Barat</option>
              <option value="1697">Sumatera Selatan</option>
              <option value="1698">Sumatera Utara</option>
              <option value="1699">Yogyakarta</option>
              <option value="1700">Ardabil</option>
              <option value="1701">Azarbayjan-e Bakhtari</option>
              <option value="1702">Azarbayjan-e Khavari</option>
              <option value="1703">Bushehr</option>
              <option value="1704">Chahar Mahal-e Bakhtiari</option>
              <option value="1705">Esfahan</option>
              <option value="1706">Fars</option>
              <option value="1707">Gilan</option>
              <option value="1708">Golestan</option>
              <option value="1709">Hamadan</option>
              <option value="1710">Hormozgan</option>
              <option value="1711">Ilam</option>
              <option value="1712">Kerman</option>
              <option value="1713">Kermanshah</option>
              <option value="1714">Khorasan</option>
              <option value="1715">Khuzestan</option>
              <option value="1716">Kohgiluyeh-e Boyerahmad</option>
              <option value="1717">Kordestan</option>
              <option value="1718">Lorestan</option>
              <option value="1719">Markazi</option>
              <option value="1720">Mazandaran</option>
              <option value="1721">Ostan-e Esfahan</option>
              <option value="1722">Qazvin</option>
              <option value="1723">Qom</option>
              <option value="1724">Semnan</option>
              <option value="1725">Sistan-e Baluchestan</option>
              <option value="1726">Tehran</option>
              <option value="1727">Yazd</option>
              <option value="1728">Zanjan</option>
              <option value="1729">Babil</option>
              <option value="1730">Baghdad</option>
              <option value="1731">Dahuk</option>
              <option value="1732">Dhi Qar</option>
              <option value="1733">Diyala</option>
              <option value="1734">Erbil</option>
              <option value="1735">Irbil</option>
              <option value="1736">Karbala</option>
              <option value="1737">Kurdistan</option>
              <option value="1738">Maysan</option>
              <option value="1739">Ninawa</option>
              <option value="1740">Salah-ad-Din</option>
              <option value="1741">Wasit</option>
              <option value="1742">al-Anbar</option>
              <option value="1743">al-Basrah</option>
              <option value="1744">al-Muthanna</option>
              <option value="1745">al-Qadisiyah</option>
              <option value="1746">an-Najaf</option>
              <option value="1747">as-Sulaymaniyah</option>
              <option value="1748">at-Ta'mim</option>
              <option value="1749">Armagh</option>
              <option value="1750">Carlow</option>
              <option value="1751">Cavan</option>
              <option value="1752">Clare</option>
              <option value="1753">Cork</option>
              <option value="1754">Donegal</option>
              <option value="1755">Dublin</option>
              <option value="1756">Galway</option>
              <option value="1757">Kerry</option>
              <option value="1758">Kildare</option>
              <option value="1759">Kilkenny</option>
              <option value="1760">Laois</option>
              <option value="1761">Leinster</option>
              <option value="1762">Leitrim</option>
              <option value="1763">Limerick</option>
              <option value="1764">Loch Garman</option>
              <option value="1765">Longford</option>
              <option value="1766">Louth</option>
              <option value="1767">Mayo</option>
              <option value="1768">Meath</option>
              <option value="1769">Monaghan</option>
              <option value="1770">Offaly</option>
              <option value="1771">Roscommon</option>
              <option value="1772">Sligo</option>
              <option value="1773">Tipperary North Riding</option>
              <option value="1774">Tipperary South Riding</option>
              <option value="1775">Ulster</option>
              <option value="1776">Waterford</option>
              <option value="1777">Westmeath</option>
              <option value="1778">Wexford</option>
              <option value="1779">Wicklow</option>
              <option value="1780">Beit Hanania</option>
              <option value="1781">Ben Gurion Airport</option>
              <option value="1782">Bethlehem</option>
              <option value="1783">Caesarea</option>
              <option value="1784">Centre</option>
              <option value="1785">Gaza</option>
              <option value="1786">Hadaron</option>
              <option value="1787">Haifa District</option>
              <option value="1788">Hamerkaz</option>
              <option value="1789">Hazafon</option>
              <option value="1790">Hebron</option>
              <option value="1791">Jaffa</option>
              <option value="1792">Jerusalem</option>
              <option value="1793">Khefa</option>
              <option value="1794">Kiryat Yam</option>
              <option value="1795">Lower Galilee</option>
              <option value="1796">Qalqilya</option>
              <option value="1797">Talme Elazar</option>
              <option value="1798">Tel Aviv</option>
              <option value="1799">Tsafon</option>
              <option value="1800">Umm El Fahem</option>
              <option value="1801">Yerushalayim</option>
              <option value="1802">Abruzzi</option>
              <option value="1803">Abruzzo</option>
              <option value="1804">Agrigento</option>
              <option value="1805">Alessandria</option>
              <option value="1806">Ancona</option>
              <option value="1807">Arezzo</option>
              <option value="1808">Ascoli Piceno</option>
              <option value="1809">Asti</option>
              <option value="1810">Avellino</option>
              <option value="1811">Bari</option>
              <option value="1812">Basilicata</option>
              <option value="1813">Belluno</option>
              <option value="1814">Benevento</option>
              <option value="1815">Bergamo</option>
              <option value="1816">Biella</option>
              <option value="1817">Bologna</option>
              <option value="1818">Bolzano</option>
              <option value="1819">Brescia</option>
              <option value="1820">Brindisi</option>
              <option value="1821">Calabria</option>
              <option value="1822">Campania</option>
              <option value="1823">Cartoceto</option>
              <option value="1824">Caserta</option>
              <option value="1825">Catania</option>
              <option value="1826">Chieti</option>
              <option value="1827">Como</option>
              <option value="1828">Cosenza</option>
              <option value="1829">Cremona</option>
              <option value="1830">Cuneo</option>
              <option value="1831">Emilia-Romagna</option>
              <option value="1832">Ferrara</option>
              <option value="1833">Firenze</option>
              <option value="1834">Florence</option>
              <option value="1835">Forli-Cesena </option>
              <option value="1836">Friuli-Venezia Giulia</option>
              <option value="1837">Frosinone</option>
              <option value="1838">Genoa</option>
              <option value="1839">Gorizia</option>
              <option value="1840">L'Aquila</option>
              <option value="1841">Lazio</option>
              <option value="1842">Lecce</option>
              <option value="1843">Lecco</option>
              <option value="1844">Lecco Province</option>
              <option value="1845">Liguria</option>
              <option value="1846">Lodi</option>
              <option value="1847">Lombardia</option>
              <option value="1848">Lombardy</option>
              <option value="1849">Macerata</option>
              <option value="1850">Mantova</option>
              <option value="1851">Marche</option>
              <option value="1852">Messina</option>
              <option value="1853">Milan</option>
              <option value="1854">Modena</option>
              <option value="1855">Molise</option>
              <option value="1856">Molteno</option>
              <option value="1857">Montenegro</option>
              <option value="1858">Monza and Brianza</option>
              <option value="1859">Naples</option>
              <option value="1860">Novara</option>
              <option value="1861">Padova</option>
              <option value="1862">Parma</option>
              <option value="1863">Pavia</option>
              <option value="1864">Perugia</option>
              <option value="1865">Pesaro-Urbino</option>
              <option value="1866">Piacenza</option>
              <option value="1867">Piedmont</option>
              <option value="1868">Piemonte</option>
              <option value="1869">Pisa</option>
              <option value="1870">Pordenone</option>
              <option value="1871">Potenza</option>
              <option value="1872">Puglia</option>
              <option value="1873">Reggio Emilia</option>
              <option value="1874">Rimini</option>
              <option value="1875">Roma</option>
              <option value="1876">Salerno</option>
              <option value="1877">Sardegna</option>
              <option value="1878">Sassari</option>
              <option value="1879">Savona</option>
              <option value="1880">Sicilia</option>
              <option value="1881">Siena</option>
              <option value="1882">Sondrio</option>
              <option value="1883">South Tyrol</option>
              <option value="1884">Taranto</option>
              <option value="1885">Teramo</option>
              <option value="1886">Torino</option>
              <option value="1887">Toscana</option>
              <option value="1888">Trapani</option>
              <option value="1889">Trentino-Alto Adige</option>
              <option value="1890">Trento</option>
              <option value="1891">Treviso</option>
              <option value="1892">Udine</option>
              <option value="1893">Umbria</option>
              <option value="1894">Valle d'Aosta</option>
              <option value="1895">Varese</option>
              <option value="1896">Veneto</option>
              <option value="1897">Venezia</option>
              <option value="1898">Verbano-Cusio-Ossola</option>
              <option value="1899">Vercelli</option>
              <option value="1900">Verona</option>
              <option value="1901">Vicenza</option>
              <option value="1902">Viterbo</option>
              <option value="1903">Buxoro Viloyati</option>
              <option value="1904">Clarendon</option>
              <option value="1905">Hanover</option>
              <option value="1906">Kingston</option>
              <option value="1907">Manchester</option>
              <option value="1908">Portland</option>
              <option value="1909">Saint Andrews</option>
              <option value="1910">Saint Ann</option>
              <option value="1911">Saint Catherine</option>
              <option value="1912">Saint Elizabeth</option>
              <option value="1913">Saint James</option>
              <option value="1914">Saint Mary</option>
              <option value="1915">Saint Thomas</option>
              <option value="1916">Trelawney</option>
              <option value="1917">Westmoreland</option>
              <option value="1918">Aichi</option>
              <option value="1919">Akita</option>
              <option value="1920">Aomori</option>
              <option value="1921">Chiba</option>
              <option value="1922">Ehime</option>
              <option value="1923">Fukui</option>
              <option value="1924">Fukuoka</option>
              <option value="1925">Fukushima</option>
              <option value="1926">Gifu</option>
              <option value="1927">Gumma</option>
              <option value="1928">Hiroshima</option>
              <option value="1929">Hokkaido</option>
              <option value="1930">Hyogo</option>
              <option value="1931">Ibaraki</option>
              <option value="1932">Ishikawa</option>
              <option value="1933">Iwate</option>
              <option value="1934">Kagawa</option>
              <option value="1935">Kagoshima</option>
              <option value="1936">Kanagawa</option>
              <option value="1937">Kanto</option>
              <option value="1938">Kochi</option>
              <option value="1939">Kumamoto</option>
              <option value="1940">Kyoto</option>
              <option value="1941">Mie</option>
              <option value="1942">Miyagi</option>
              <option value="1943">Miyazaki</option>
              <option value="1944">Nagano</option>
              <option value="1945">Nagasaki</option>
              <option value="1946">Nara</option>
              <option value="1947">Niigata</option>
              <option value="1948">Oita</option>
              <option value="1949">Okayama</option>
              <option value="1950">Okinawa</option>
              <option value="1951">Osaka</option>
              <option value="1952">Saga</option>
              <option value="1953">Saitama</option>
              <option value="1954">Shiga</option>
              <option value="1955">Shimane</option>
              <option value="1956">Shizuoka</option>
              <option value="1957">Tochigi</option>
              <option value="1958">Tokushima</option>
              <option value="1959">Tokyo</option>
              <option value="1960">Tottori</option>
              <option value="1961">Toyama</option>
              <option value="1962">Wakayama</option>
              <option value="1963">Yamagata</option>
              <option value="1964">Yamaguchi</option>
              <option value="1965">Yamanashi</option>
              <option value="1977">'Ajlun</option>
              <option value="1978">Amman</option>
              <option value="1979">Irbid</option>
              <option value="1980">Jarash</option>
              <option value="1981">Ma'an</option>
              <option value="1982">Madaba</option>
              <option value="1983">al-'Aqabah</option>
              <option value="1984">al-Balqa'</option>
              <option value="1985">al-Karak</option>
              <option value="1986">al-Mafraq</option>
              <option value="1987">at-Tafilah</option>
              <option value="1988">az-Zarqa'</option>
              <option value="1989">Akmecet</option>
              <option value="1990">Akmola</option>
              <option value="1991">Aktobe</option>
              <option value="1992">Almati</option>
              <option value="1993">Atirau</option>
              <option value="1994">Batis Kazakstan</option>
              <option value="1995">Burlinsky Region</option>
              <option value="1996">Karagandi</option>
              <option value="1997">Kostanay</option>
              <option value="1998">Mankistau</option>
              <option value="1999">Ontustik Kazakstan</option>
              <option value="2000">Pavlodar</option>
              <option value="2001">Sigis Kazakstan</option>
              <option value="2002">Soltustik Kazakstan</option>
              <option value="2003">Taraz</option>
              <option value="2004">Central</option>
              <option value="2005">Coast</option>
              <option value="2006">Eastern</option>
              <option value="2007">Nairobi</option>
              <option value="2008">North Eastern</option>
              <option value="2009">Nyanza</option>
              <option value="2010">Rift Valley</option>
              <option value="2011">Western</option>
              <option value="2012">Abaiang</option>
              <option value="2013">Abemana</option>
              <option value="2014">Aranuka</option>
              <option value="2015">Arorae</option>
              <option value="2016">Banaba</option>
              <option value="2017">Beru</option>
              <option value="2018">Butaritari</option>
              <option value="2019">Kiritimati</option>
              <option value="2020">Kuria</option>
              <option value="2021">Maiana</option>
              <option value="2022">Makin</option>
              <option value="2023">Marakei</option>
              <option value="2024">Nikunau</option>
              <option value="2025">Nonouti</option>
              <option value="2026">Onotoa</option>
              <option value="2027">Phoenix Islands</option>
              <option value="2028">Tabiteuea North</option>
              <option value="2029">Tabiteuea South</option>
              <option value="2030">Tabuaeran</option>
              <option value="2031">Tamana</option>
              <option value="2032">Tarawa North</option>
              <option value="2033">Tarawa South</option>
              <option value="2034">Teraina</option>
              <option value="2035">Chagangdo</option>
              <option value="2036">Hamgyeongbukto</option>
              <option value="2037">Hamgyeongnamdo</option>
              <option value="2038">Hwanghaebukto</option>
              <option value="2039">Hwanghaenamdo</option>
              <option value="2040">Kaeseong</option>
              <option value="2041">Kangweon</option>
              <option value="2042">Nampo</option>
              <option value="2043">Pyeonganbukto</option>
              <option value="2044">Pyeongannamdo</option>
              <option value="2045">Pyeongyang</option>
              <option value="2046">Yanggang</option>
              <option value="2047">Busan</option>
              <option value="2048">Cheju</option>
              <option value="2049">Chollabuk</option>
              <option value="2050">Chollanam</option>
              <option value="2051">Chungbuk</option>
              <option value="2052">Chungcheongbuk</option>
              <option value="2053">Chungcheongnam</option>
              <option value="2054">Chungnam</option>
              <option value="2055">Daegu</option>
              <option value="2056">Gangwon-do</option>
              <option value="2057">Goyang-si</option>
              <option value="2058">Gyeonggi-do</option>
              <option value="2059">Gyeongsang </option>
              <option value="2060">Gyeongsangnam-do</option>
              <option value="2061">Incheon</option>
              <option value="2062">Jeju-Si</option>
              <option value="2063">Jeonbuk</option>
              <option value="2064">Kangweon</option>
              <option value="2065">Kwangju</option>
              <option value="2066">Kyeonggi</option>
              <option value="2067">Kyeongsangbuk</option>
              <option value="2068">Kyeongsangnam</option>
              <option value="2069">Kyonggi-do</option>
              <option value="2070">Kyungbuk-Do</option>
              <option value="2071">Kyunggi-Do</option>
              <option value="2072">Kyunggi-do</option>
              <option value="2073">Pusan</option>
              <option value="2074">Seoul</option>
              <option value="2075">Sudogwon</option>
              <option value="2076">Taegu</option>
              <option value="2077">Taejeon</option>
              <option value="2078">Taejon-gwangyoksi</option>
              <option value="2079">Ulsan</option>
              <option value="2080">Wonju</option>
              <option value="2081">gwangyoksi</option>
              <option value="2082">Al Asimah</option>
              <option value="2083">Hawalli</option>
              <option value="2084">Mishref</option>
              <option value="2085">Qadesiya</option>
              <option value="2086">Safat</option>
              <option value="2087">Salmiya</option>
              <option value="2088">al-Ahmadi</option>
              <option value="2089">al-Farwaniyah</option>
              <option value="2090">al-Jahra</option>
              <option value="2091">al-Kuwayt</option>
              <option value="2092">Batken</option>
              <option value="2093">Bishkek</option>
              <option value="2094">Chui</option>
              <option value="2095">Issyk-Kul</option>
              <option value="2096">Jalal-Abad</option>
              <option value="2097">Naryn</option>
              <option value="2098">Osh</option>
              <option value="2099">Talas</option>
              <option value="2100">Attopu</option>
              <option value="2101">Bokeo</option>
              <option value="2102">Bolikhamsay</option>
              <option value="2103">Champasak</option>
              <option value="2104">Houaphanh</option>
              <option value="2105">Khammouane</option>
              <option value="2106">Luang Nam Tha</option>
              <option value="2107">Luang Prabang</option>
              <option value="2108">Oudomxay</option>
              <option value="2109">Phongsaly</option>
              <option value="2110">Saravan</option>
              <option value="2111">Savannakhet</option>
              <option value="2112">Sekong</option>
              <option value="2113">Viangchan Prefecture</option>
              <option value="2114">Viangchan Province</option>
              <option value="2115">Xaignabury</option>
              <option value="2116">Xiang Khuang</option>
              <option value="2117">Aizkraukles</option>
              <option value="2118">Aluksnes</option>
              <option value="2119">Balvu</option>
              <option value="2120">Bauskas</option>
              <option value="2121">Cesu</option>
              <option value="2122">Daugavpils</option>
              <option value="2123">Daugavpils City</option>
              <option value="2124">Dobeles</option>
              <option value="2125">Gulbenes</option>
              <option value="2126">Jekabspils</option>
              <option value="2127">Jelgava</option>
              <option value="2128">Jelgavas</option>
              <option value="2129">Jurmala City</option>
              <option value="2130">Kraslavas</option>
              <option value="2131">Kuldigas</option>
              <option value="2132">Liepaja</option>
              <option value="2133">Liepajas</option>
              <option value="2134">Limbazhu</option>
              <option value="2135">Ludzas</option>
              <option value="2136">Madonas</option>
              <option value="2137">Ogres</option>
              <option value="2138">Preilu</option>
              <option value="2139">Rezekne</option>
              <option value="2140">Rezeknes</option>
              <option value="2141">Riga</option>
              <option value="2142">Rigas</option>
              <option value="2143">Saldus</option>
              <option value="2144">Talsu</option>
              <option value="2145">Tukuma</option>
              <option value="2146">Valkas</option>
              <option value="2147">Valmieras</option>
              <option value="2148">Ventspils</option>
              <option value="2149">Ventspils City</option>
              <option value="2150">Beirut</option>
              <option value="2151">Jabal Lubnan</option>
              <option value="2152">Mohafazat Liban-Nord</option>
              <option value="2153">Mohafazat Mont-Liban</option>
              <option value="2154">Sidon</option>
              <option value="2155">al-Biqa</option>
              <option value="2156">al-Janub</option>
              <option value="2157">an-Nabatiyah</option>
              <option value="2158">ash-Shamal</option>
              <option value="2159">Berea</option>
              <option value="2160">Butha-Buthe</option>
              <option value="2161">Leribe</option>
              <option value="2162">Mafeteng</option>
              <option value="2163">Maseru</option>
              <option value="2164">Mohale's Hoek</option>
              <option value="2165">Mokhotlong</option>
              <option value="2166">Qacha's Nek</option>
              <option value="2167">Quthing</option>
              <option value="2168">Thaba-Tseka</option>
              <option value="2169">Bomi</option>
              <option value="2170">Bong</option>
              <option value="2171">Grand Bassa</option>
              <option value="2172">Grand Cape Mount</option>
              <option value="2173">Grand Gedeh</option>
              <option value="2174">Loffa</option>
              <option value="2175">Margibi</option>
              <option value="2176">Maryland and Grand Kru</option>
              <option value="2177">Montserrado</option>
              <option value="2178">Nimba</option>
              <option value="2179">Rivercess</option>
              <option value="2180">Sinoe</option>
              <option value="2181">Ajdabiya</option>
              <option value="2182">Fezzan</option>
              <option value="2183">Banghazi</option>
              <option value="2184">Darnah</option>
              <option value="2185">Ghadamis</option>
              <option value="2186">Gharyan</option>
              <option value="2187">Misratah</option>
              <option value="2188">Murzuq</option>
              <option value="2189">Sabha</option>
              <option value="2190">Sawfajjin</option>
              <option value="2191">Surt</option>
              <option value="2192">Tarabulus</option>
              <option value="2193">Tarhunah</option>
              <option value="2194">Tripolitania</option>
              <option value="2195">Tubruq</option>
              <option value="2196">Yafran</option>
              <option value="2197">Zlitan</option>
              <option value="2198">al-'Aziziyah</option>
              <option value="2199">al-Fatih</option>
              <option value="2200">al-Jabal al Akhdar</option>
              <option value="2201">al-Jufrah</option>
              <option value="2202">al-Khums</option>
              <option value="2203">al-Kufrah</option>
              <option value="2204">an-Nuqat al-Khams</option>
              <option value="2205">ash-Shati'</option>
              <option value="2206">az-Zawiyah</option>
              <option value="2207">Balzers</option>
              <option value="2208">Eschen</option>
              <option value="2209">Gamprin</option>
              <option value="2210">Mauren</option>
              <option value="2211">Planken</option>
              <option value="2212">Ruggell</option>
              <option value="2213">Schaan</option>
              <option value="2214">Schellenberg</option>
              <option value="2215">Triesen</option>
              <option value="2216">Triesenberg</option>
              <option value="2217">Vaduz</option>
              <option value="2218">Alytaus</option>
              <option value="2219">Anyksciai</option>
              <option value="2220">Kauno</option>
              <option value="2221">Klaipedos</option>
              <option value="2222">Marijampoles</option>
              <option value="2223">Panevezhio</option>
              <option value="2224">Panevezys</option>
              <option value="2225">Shiauliu</option>
              <option value="2226">Taurages</option>
              <option value="2227">Telshiu</option>
              <option value="2228">Telsiai</option>
              <option value="2229">Utenos</option>
              <option value="2230">Vilniaus</option>
              <option value="2231">Capellen</option>
              <option value="2232">Clervaux</option>
              <option value="2233">Diekirch</option>
              <option value="2234">Echternach</option>
              <option value="2235">Esch-sur-Alzette</option>
              <option value="2236">Grevenmacher</option>
              <option value="2237">Luxembourg</option>
              <option value="2238">Mersch</option>
              <option value="2239">Redange</option>
              <option value="2240">Remich</option>
              <option value="2241">Vianden</option>
              <option value="2242">Wiltz</option>
              <option value="2243">Macau</option>
              <option value="2244">Berovo</option>
              <option value="2245">Bitola</option>
              <option value="2246">Brod</option>
              <option value="2247">Debar</option>
              <option value="2248">Delchevo</option>
              <option value="2249">Demir Hisar</option>
              <option value="2250">Gevgelija</option>
              <option value="2251">Gostivar</option>
              <option value="2252">Kavadarci</option>
              <option value="2253">Kichevo</option>
              <option value="2254">Kochani</option>
              <option value="2255">Kratovo</option>
              <option value="2256">Kriva Palanka</option>
              <option value="2257">Krushevo</option>
              <option value="2258">Kumanovo</option>
              <option value="2259">Negotino</option>
              <option value="2260">Ohrid</option>
              <option value="2261">Prilep</option>
              <option value="2262">Probishtip</option>
              <option value="2263">Radovish</option>
              <option value="2264">Resen</option>
              <option value="2265">Shtip</option>
              <option value="2266">Skopje</option>
              <option value="2267">Struga</option>
              <option value="2268">Strumica</option>
              <option value="2269">Sveti Nikole</option>
              <option value="2270">Tetovo</option>
              <option value="2271">Valandovo</option>
              <option value="2272">Veles</option>
              <option value="2273">Vinica</option>
              <option value="2274">Antananarivo</option>
              <option value="2275">Antsiranana</option>
              <option value="2276">Fianarantsoa</option>
              <option value="2277">Mahajanga</option>
              <option value="2278">Toamasina</option>
              <option value="2279">Toliary</option>
              <option value="2280">Balaka</option>
              <option value="2281">Blantyre City</option>
              <option value="2282">Chikwawa</option>
              <option value="2283">Chiradzulu</option>
              <option value="2284">Chitipa</option>
              <option value="2285">Dedza</option>
              <option value="2286">Dowa</option>
              <option value="2287">Karonga</option>
              <option value="2288">Kasungu</option>
              <option value="2289">Lilongwe City</option>
              <option value="2290">Machinga</option>
              <option value="2291">Mangochi</option>
              <option value="2292">Mchinji</option>
              <option value="2293">Mulanje</option>
              <option value="2294">Mwanza</option>
              <option value="2295">Mzimba</option>
              <option value="2296">Mzuzu City</option>
              <option value="2297">Nkhata Bay</option>
              <option value="2298">Nkhotakota</option>
              <option value="2299">Nsanje</option>
              <option value="2300">Ntcheu</option>
              <option value="2301">Ntchisi</option>
              <option value="2302">Phalombe</option>
              <option value="2303">Rumphi</option>
              <option value="2304">Salima</option>
              <option value="2305">Thyolo</option>
              <option value="2306">Zomba Municipality</option>
              <option value="2307">Johor</option>
              <option value="2308">Kedah</option>
              <option value="2309">Kelantan</option>
              <option value="2310">Kuala Lumpur</option>
              <option value="2311">Labuan</option>
              <option value="2312">Melaka</option>
              <option value="2313">Negeri Johor</option>
              <option value="2314">Negeri Sembilan</option>
              <option value="2315">Pahang</option>
              <option value="2316">Penang</option>
              <option value="2317">Perak</option>
              <option value="2318">Perlis</option>
              <option value="2319">Pulau Pinang</option>
              <option value="2320">Sabah</option>
              <option value="2321">Sarawak</option>
              <option value="2322">Selangor</option>
              <option value="2323">Sembilan</option>
              <option value="2324">Terengganu</option>
              <option value="2325">Alif Alif</option>
              <option value="2326">Alif Dhaal</option>
              <option value="2327">Baa</option>
              <option value="2328">Dhaal</option>
              <option value="2329">Faaf</option>
              <option value="2330">Gaaf Alif</option>
              <option value="2331">Gaaf Dhaal</option>
              <option value="2332">Ghaviyani</option>
              <option value="2333">Haa Alif</option>
              <option value="2334">Haa Dhaal</option>
              <option value="2335">Kaaf</option>
              <option value="2336">Laam</option>
              <option value="2337">Lhaviyani</option>
              <option value="2338">Male</option>
              <option value="2339">Miim</option>
              <option value="2340">Nuun</option>
              <option value="2341">Raa</option>
              <option value="2342">Shaviyani</option>
              <option value="2343">Siin</option>
              <option value="2344">Thaa</option>
              <option value="2345">Vaav</option>
              <option value="2346">Bamako</option>
              <option value="2347">Gao</option>
              <option value="2348">Kayes</option>
              <option value="2349">Kidal</option>
              <option value="2350">Koulikoro</option>
              <option value="2351">Mopti</option>
              <option value="2352">Segou</option>
              <option value="2353">Sikasso</option>
              <option value="2354">Tombouctou</option>
              <option value="2355">Gozo and Comino</option>
              <option value="2356">Inner Harbour</option>
              <option value="2357">Northern</option>
              <option value="2358">Outer Harbour</option>
              <option value="2359">South Eastern</option>
              <option value="2360">Valletta</option>
              <option value="2361">Western</option>
              <option value="2370">Ailinlaplap</option>
              <option value="2371">Ailuk</option>
              <option value="2372">Arno</option>
              <option value="2373">Aur</option>
              <option value="2374">Bikini</option>
              <option value="2375">Ebon</option>
              <option value="2376">Enewetak</option>
              <option value="2377">Jabat</option>
              <option value="2378">Jaluit</option>
              <option value="2379">Kili</option>
              <option value="2380">Kwajalein</option>
              <option value="2381">Lae</option>
              <option value="2382">Lib</option>
              <option value="2383">Likiep</option>
              <option value="2384">Majuro</option>
              <option value="2385">Maloelap</option>
              <option value="2386">Mejit</option>
              <option value="2387">Mili</option>
              <option value="2388">Namorik</option>
              <option value="2389">Namu</option>
              <option value="2390">Rongelap</option>
              <option value="2391">Ujae</option>
              <option value="2392">Utrik</option>
              <option value="2393">Wotho</option>
              <option value="2394">Wotje</option>
              <option value="2395">Fort-de-France</option>
              <option value="2396">La Trinite</option>
              <option value="2397">Le Marin</option>
              <option value="2398">Saint-Pierre</option>
              <option value="2399">Adrar</option>
              <option value="2400">Assaba</option>
              <option value="2401">Brakna</option>
              <option value="2402">Dhakhlat Nawadibu</option>
              <option value="2403">Hudh-al-Gharbi</option>
              <option value="2404">Hudh-ash-Sharqi</option>
              <option value="2405">Inshiri</option>
              <option value="2406">Nawakshut</option>
              <option value="2407">Qidimagha</option>
              <option value="2408">Qurqul</option>
              <option value="2409">Taqant</option>
              <option value="2410">Tiris Zammur</option>
              <option value="2411">Trarza</option>
              <option value="2412">Black River</option>
              <option value="2413">Eau Coulee</option>
              <option value="2414">Flacq</option>
              <option value="2415">Floreal</option>
              <option value="2416">Grand Port</option>
              <option value="2417">Moka</option>
              <option value="2418">Pamplempousses</option>
              <option value="2419">Plaines Wilhelm</option>
              <option value="2420">Port Louis</option>
              <option value="2421">Riviere du Rempart</option>
              <option value="2422">Rodrigues</option>
              <option value="2423">Rose Hill</option>
              <option value="2424">Savanne</option>
              <option value="2425">Mayotte</option>
              <option value="2426">Pamanzi</option>
              <option value="2427">Aguascalientes</option>
              <option value="2428">Baja California</option>
              <option value="2429">Baja California Sur</option>
              <option value="2430">Campeche</option>
              <option value="2431">Chiapas</option>
              <option value="2432">Chihuahua</option>
              <option value="2433">Coahuila</option>
              <option value="2434">Colima</option>
              <option value="2435">Distrito Federal</option>
              <option value="2436">Durango</option>
              <option value="2437">Estado de Mexico</option>
              <option value="2438">Guanajuato</option>
              <option value="2439">Guerrero</option>
              <option value="2440">Hidalgo</option>
              <option value="2441">Jalisco</option>
              <option value="2442">Mexico</option>
              <option value="2443">Michoacan</option>
              <option value="2444">Morelos</option>
              <option value="2445">Nayarit</option>
              <option value="2446">Nuevo Leon</option>
              <option value="2447">Oaxaca</option>
              <option value="2448">Puebla</option>
              <option value="2449">Queretaro</option>
              <option value="2450">Quintana Roo</option>
              <option value="2451">San Luis Potosi</option>
              <option value="2452">Sinaloa</option>
              <option value="2453">Sonora</option>
              <option value="2454">Tabasco</option>
              <option value="2455">Tamaulipas</option>
              <option value="2456">Tlaxcala</option>
              <option value="2457">Veracruz</option>
              <option value="2458">Yucatan</option>
              <option value="2459">Zacatecas</option>
              <option value="2460">Chuuk</option>
              <option value="2461">Kusaie</option>
              <option value="2462">Pohnpei</option>
              <option value="2463">Yap</option>
              <option value="2464">Balti</option>
              <option value="2465">Cahul</option>
              <option value="2466">Chisinau</option>
              <option value="2467">Chisinau Oras</option>
              <option value="2468">Edinet</option>
              <option value="2469">Gagauzia</option>
              <option value="2470">Lapusna</option>
              <option value="2471">Orhei</option>
              <option value="2472">Soroca</option>
              <option value="2473">Taraclia</option>
              <option value="2474">Tighina</option>
              <option value="2475">Transnistria</option>
              <option value="2476">Ungheni</option>
              <option value="2477">Fontvieille</option>
              <option value="2478">La Condamine</option>
              <option value="2479">Monaco-Ville</option>
              <option value="2480">Monte Carlo</option>
              <option value="2481">Arhangaj</option>
              <option value="2482">Bajan-Olgij</option>
              <option value="2483">Bajanhongor</option>
              <option value="2484">Bulgan</option>
              <option value="2485">Darhan-Uul</option>
              <option value="2486">Dornod</option>
              <option value="2487">Dornogovi</option>
              <option value="2488">Dundgovi</option>
              <option value="2489">Govi-Altaj</option>
              <option value="2490">Govisumber</option>
              <option value="2491">Hentij</option>
              <option value="2492">Hovd</option>
              <option value="2493">Hovsgol</option>
              <option value="2494">Omnogovi</option>
              <option value="2495">Orhon</option>
              <option value="2496">Ovorhangaj</option>
              <option value="2497">Selenge</option>
              <option value="2498">Suhbaatar</option>
              <option value="2499">Tov</option>
              <option value="2500">Ulaanbaatar</option>
              <option value="2501">Uvs</option>
              <option value="2502">Zavhan</option>
              <option value="2503">Montserrat</option>
              <option value="2504">Agadir</option>
              <option value="2505">Casablanca</option>
              <option value="2506">Chaouia-Ouardigha</option>
              <option value="2507">Doukkala-Abda</option>
              <option value="2508">Fes-Boulemane</option>
              <option value="2509">Gharb-Chrarda-Beni Hssen</option>
              <option value="2510">Guelmim</option>
              <option value="2511">Kenitra</option>
              <option value="2512">Marrakech-Tensift-Al Haouz</option>
              <option value="2513">Meknes-Tafilalet</option>
              <option value="2514">Oriental</option>
              <option value="2515">Oujda</option>
              <option value="2516">Province de Tanger</option>
              <option value="2517">Rabat-Sale-Zammour-Zaer</option>
              <option value="2518">Sala Al Jadida</option>
              <option value="2519">Settat</option>
              <option value="2520">Souss Massa-Draa</option>
              <option value="2521">Tadla-Azilal</option>
              <option value="2522">Tangier-Tetouan</option>
              <option value="2523">Taza-Al Hoceima-Taounate</option>
              <option value="2524">Wilaya de Casablanca</option>
              <option value="2525">Wilaya de Rabat-Sale</option>
              <option value="2526">Cabo Delgado</option>
              <option value="2527">Gaza</option>
              <option value="2528">Inhambane</option>
              <option value="2529">Manica</option>
              <option value="2530">Maputo</option>
              <option value="2531">Maputo Provincia</option>
              <option value="2532">Nampula</option>
              <option value="2533">Niassa</option>
              <option value="2534">Sofala</option>
              <option value="2535">Tete</option>
              <option value="2536">Zambezia</option>
              <option value="2537">Ayeyarwady</option>
              <option value="2538">Bago</option>
              <option value="2539">Chin</option>
              <option value="2540">Kachin</option>
              <option value="2541">Kayah</option>
              <option value="2542">Kayin</option>
              <option value="2543">Magway</option>
              <option value="2544">Mandalay</option>
              <option value="2545">Mon</option>
              <option value="2546">Nay Pyi Taw</option>
              <option value="2547">Rakhine</option>
              <option value="2548">Sagaing</option>
              <option value="2549">Shan</option>
              <option value="2550">Tanintharyi</option>
              <option value="2551">Yangon</option>
              <option value="2552">Caprivi</option>
              <option value="2553">Erongo</option>
              <option value="2554">Hardap</option>
              <option value="2555">Karas</option>
              <option value="2556">Kavango</option>
              <option value="2557">Khomas</option>
              <option value="2558">Kunene</option>
              <option value="2559">Ohangwena</option>
              <option value="2560">Omaheke</option>
              <option value="2561">Omusati</option>
              <option value="2562">Oshana</option>
              <option value="2563">Oshikoto</option>
              <option value="2564">Otjozondjupa</option>
              <option value="2565">Yaren</option>
              <option value="2566">Bagmati</option>
              <option value="2567">Bheri</option>
              <option value="2568">Dhawalagiri</option>
              <option value="2569">Gandaki</option>
              <option value="2570">Janakpur</option>
              <option value="2571">Karnali</option>
              <option value="2572">Koshi</option>
              <option value="2573">Lumbini</option>
              <option value="2574">Mahakali</option>
              <option value="2575">Mechi</option>
              <option value="2576">Narayani</option>
              <option value="2577">Rapti</option>
              <option value="2578">Sagarmatha</option>
              <option value="2579">Seti</option>
              <option value="2580">Bonaire</option>
              <option value="2581">Curacao</option>
              <option value="2582">Saba</option>
              <option value="2583">Sint Eustatius</option>
              <option value="2584">Sint Maarten</option>
              <option value="2585">Amsterdam</option>
              <option value="2586">Benelux</option>
              <option value="2587">Drenthe</option>
              <option value="2588">Flevoland</option>
              <option value="2589">Friesland</option>
              <option value="2590">Gelderland</option>
              <option value="2591">Groningen</option>
              <option value="2592">Limburg</option>
              <option value="2593">Noord-Brabant</option>
              <option value="2594">Noord-Holland</option>
              <option value="2595">Overijssel</option>
              <option value="2596">South Holland</option>
              <option value="2597">Utrecht</option>
              <option value="2598">Zeeland</option>
              <option value="2599">Zuid-Holland</option>
              <option value="2600">Iles</option>
              <option value="2601">Nord</option>
              <option value="2602">Sud</option>
              <option value="2603">Area Outside Region</option>
              <option value="2604">Auckland</option>
              <option value="2605">Bay of Plenty</option>
              <option value="2606">Canterbury</option>
              <option value="2607">Christchurch</option>
              <option value="2608">Gisborne</option>
              <option value="2609">Hawke's Bay</option>
              <option value="2610">Manawatu-Wanganui</option>
              <option value="2611">Marlborough</option>
              <option value="2612">Nelson</option>
              <option value="2613">Northland</option>
              <option value="2614">Otago</option>
              <option value="2615">Rodney</option>
              <option value="2616">Southland</option>
              <option value="2617">Taranaki</option>
              <option value="2618">Tasman</option>
              <option value="2619">Waikato</option>
              <option value="2620">Wellington</option>
              <option value="2621">West Coast</option>
              <option value="2622">Atlantico Norte</option>
              <option value="2623">Atlantico Sur</option>
              <option value="2624">Boaco</option>
              <option value="2625">Carazo</option>
              <option value="2626">Chinandega</option>
              <option value="2627">Chontales</option>
              <option value="2628">Esteli</option>
              <option value="2629">Granada</option>
              <option value="2630">Jinotega</option>
              <option value="2631">Leon</option>
              <option value="2632">Madriz</option>
              <option value="2633">Managua</option>
              <option value="2634">Masaya</option>
              <option value="2635">Matagalpa</option>
              <option value="2636">Nueva Segovia</option>
              <option value="2637">Rio San Juan</option>
              <option value="2638">Rivas</option>
              <option value="2639">Agadez</option>
              <option value="2640">Diffa</option>
              <option value="2641">Dosso</option>
              <option value="2642">Maradi</option>
              <option value="2643">Niamey</option>
              <option value="2644">Tahoua</option>
              <option value="2645">Tillabery</option>
              <option value="2646">Zinder</option>
              <option value="2647">Abia</option>
              <option value="2648">Abuja Federal Capital Territor</option>
              <option value="2649">Adamawa</option>
              <option value="2650">Akwa Ibom</option>
              <option value="2651">Anambra</option>
              <option value="2652">Bauchi</option>
              <option value="2653">Bayelsa</option>
              <option value="2654">Benue</option>
              <option value="2655">Borno</option>
              <option value="2656">Cross River</option>
              <option value="2657">Delta</option>
              <option value="2658">Ebonyi</option>
              <option value="2659">Edo</option>
              <option value="2660">Ekiti</option>
              <option value="2661">Enugu</option>
              <option value="2662">Gombe</option>
              <option value="2663">Imo</option>
              <option value="2664">Jigawa</option>
              <option value="2665">Kaduna</option>
              <option value="2666">Kano</option>
              <option value="2667">Katsina</option>
              <option value="2668">Kebbi</option>
              <option value="2669">Kogi</option>
              <option value="2670">Kwara</option>
              <option value="2671">Lagos</option>
              <option value="2672">Nassarawa</option>
              <option value="2673">Niger</option>
              <option value="2674">Ogun</option>
              <option value="2675">Ondo</option>
              <option value="2676">Osun</option>
              <option value="2677">Oyo</option>
              <option value="2678">Plateau</option>
              <option value="2679">Rivers</option>
              <option value="2680">Sokoto</option>
              <option value="2681">Taraba</option>
              <option value="2682">Yobe</option>
              <option value="2683">Zamfara</option>
              <option value="2684">Niue</option>
              <option value="2685">Norfolk Island</option>
              <option value="2686">Northern Islands</option>
              <option value="2687">Rota</option>
              <option value="2688">Saipan</option>
              <option value="2689">Tinian</option>
              <option value="2690">Akershus</option>
              <option value="2691">Aust Agder</option>
              <option value="2692">Bergen</option>
              <option value="2693">Buskerud</option>
              <option value="2694">Finnmark</option>
              <option value="2695">Hedmark</option>
              <option value="2696">Hordaland</option>
              <option value="2697">Moere og Romsdal</option>
              <option value="2698">Nord Trondelag</option>
              <option value="2699">Nordland</option>
              <option value="2700">Oestfold</option>
              <option value="2701">Oppland</option>
              <option value="2702">Oslo</option>
              <option value="2703">Rogaland</option>
              <option value="2704">Soer Troendelag</option>
              <option value="2705">Sogn og Fjordane</option>
              <option value="2706">Stavern</option>
              <option value="2707">Sykkylven</option>
              <option value="2708">Telemark</option>
              <option value="2709">Troms</option>
              <option value="2710">Vest Agder</option>
              <option value="2711">Vestfold</option>
              <option value="2712">ÃƒÂ˜stfold</option>
              <option value="2713">Al Buraimi</option>
              <option value="2714">Dhufar</option>
              <option value="2715">Masqat</option>
              <option value="2716">Musandam</option>
              <option value="2717">Rusayl</option>
              <option value="2718">Wadi Kabir</option>
              <option value="2719">ad-Dakhiliyah</option>
              <option value="2720">adh-Dhahirah</option>
              <option value="2721">al-Batinah</option>
              <option value="2722">ash-Sharqiyah</option>
              <option value="2723">Azad kashmir</option>
              <option value="2724">Balochistan</option>
              <option value="2725">Fata</option>
              <option value="2726">Gilgit–baltistan</option>
              <option value="2727">Islamabad capital territory</option>
              <option value="2728">Khyber Pakhtunkhwa</option>
              <option value="2729">Punjab</option>
              <option value="2730">Sindh</option>
              <option value="2731">Aimeliik</option>
              <option value="2732">Airai</option>
              <option value="2733">Angaur</option>
              <option value="2734">Hatobohei</option>
              <option value="2735">Kayangel</option>
              <option value="2736">Koror</option>
              <option value="2737">Melekeok</option>
              <option value="2738">Ngaraard</option>
              <option value="2739">Ngardmau</option>
              <option value="2740">Ngaremlengui</option>
              <option value="2741">Ngatpang</option>
              <option value="2742">Ngchesar</option>
              <option value="2743">Ngerchelong</option>
              <option value="2744">Ngiwal</option>
              <option value="2745">Peleliu</option>
              <option value="2746">Sonsorol</option>
              <option value="2747">Ariha</option>
              <option value="2748">Bayt Lahm</option>
              <option value="2749">Bethlehem</option>
              <option value="2750">Dayr-al-Balah</option>
              <option value="2751">Ghazzah</option>
              <option value="2752">Ghazzah ash-Shamaliyah</option>
              <option value="2753">Janin</option>
              <option value="2754">Khan Yunis</option>
              <option value="2755">Nabulus</option>
              <option value="2756">Qalqilyah</option>
              <option value="2757">Rafah</option>
              <option value="2758">Ram Allah wal-Birah</option>
              <option value="2759">Salfit</option>
              <option value="2760">Tubas</option>
              <option value="2761">Tulkarm</option>
              <option value="2762">al-Khalil</option>
              <option value="2763">al-Quds</option>
              <option value="2764">Bocas del Toro</option>
              <option value="2765">Chiriqui</option>
              <option value="2766">Cocle</option>
              <option value="2767">Colon</option>
              <option value="2768">Darien</option>
              <option value="2769">Embera</option>
              <option value="2770">Herrera</option>
              <option value="2771">Kuna Yala</option>
              <option value="2772">Los Santos</option>
              <option value="2773">Ngobe Bugle</option>
              <option value="2774">Panama</option>
              <option value="2775">Veraguas</option>
              <option value="2776">East New Britain</option>
              <option value="2777">East Sepik</option>
              <option value="2778">Eastern Highlands</option>
              <option value="2779">Enga</option>
              <option value="2780">Fly River</option>
              <option value="2781">Gulf</option>
              <option value="2782">Madang</option>
              <option value="2783">Manus</option>
              <option value="2784">Milne Bay</option>
              <option value="2785">Morobe</option>
              <option value="2786">National Capital District</option>
              <option value="2787">New Ireland</option>
              <option value="2788">North Solomons</option>
              <option value="2789">Oro</option>
              <option value="2790">Sandaun</option>
              <option value="2791">Simbu</option>
              <option value="2792">Southern Highlands</option>
              <option value="2793">West New Britain</option>
              <option value="2794">Western Highlands</option>
              <option value="2795">Alto Paraguay</option>
              <option value="2796">Alto Parana</option>
              <option value="2797">Amambay</option>
              <option value="2798">Asuncion</option>
              <option value="2799">Boqueron</option>
              <option value="2800">Caaguazu</option>
              <option value="2801">Caazapa</option>
              <option value="2802">Canendiyu</option>
              <option value="2803">Central</option>
              <option value="2804">Concepcion</option>
              <option value="2805">Cordillera</option>
              <option value="2806">Guaira</option>
              <option value="2807">Itapua</option>
              <option value="2808">Misiones</option>
              <option value="2809">Neembucu</option>
              <option value="2810">Paraguari</option>
              <option value="2811">Presidente Hayes</option>
              <option value="2812">San Pedro</option>
              <option value="2813">Amazonas</option>
              <option value="2814">Ancash</option>
              <option value="2815">Apurimac</option>
              <option value="2816">Arequipa</option>
              <option value="2817">Ayacucho</option>
              <option value="2818">Cajamarca</option>
              <option value="2819">Cusco</option>
              <option value="2820">Huancavelica</option>
              <option value="2821">Huanuco</option>
              <option value="2822">Ica</option>
              <option value="2823">Junin</option>
              <option value="2824">La Libertad</option>
              <option value="2825">Lambayeque</option>
              <option value="2826">Lima y Callao</option>
              <option value="2827">Loreto</option>
              <option value="2828">Madre de Dios</option>
              <option value="2829">Moquegua</option>
              <option value="2830">Pasco</option>
              <option value="2831">Piura</option>
              <option value="2832">Puno</option>
              <option value="2833">San Martin</option>
              <option value="2834">Tacna</option>
              <option value="2835">Tumbes</option>
              <option value="2836">Ucayali</option>
              <option value="2837">Batangas</option>
              <option value="2838">Bicol</option>
              <option value="2839">Bulacan</option>
              <option value="2840">Cagayan</option>
              <option value="2841">Caraga</option>
              <option value="2842">Central Luzon</option>
              <option value="2843">Central Mindanao</option>
              <option value="2844">Central Visayas</option>
              <option value="2845">Cordillera</option>
              <option value="2846">Davao</option>
              <option value="2847">Eastern Visayas</option>
              <option value="2848">Greater Metropolitan Area</option>
              <option value="2849">Ilocos</option>
              <option value="2850">Laguna</option>
              <option value="2851">Luzon</option>
              <option value="2852">Mactan</option>
              <option value="2853">Metropolitan Manila Area</option>
              <option value="2854">Muslim Mindanao</option>
              <option value="2855">Northern Mindanao</option>
              <option value="2856">Southern Mindanao</option>
              <option value="2857">Southern Tagalog</option>
              <option value="2858">Western Mindanao</option>
              <option value="2859">Western Visayas</option>
              <option value="2860">Pitcairn Island</option>
              <option value="2861">Biale Blota</option>
              <option value="2862">Dobroszyce</option>
              <option value="2863">Dolnoslaskie</option>
              <option value="2864">Dziekanow Lesny</option>
              <option value="2865">Hopowo</option>
              <option value="2866">Kartuzy</option>
              <option value="2867">Koscian</option>
              <option value="2868">Krakow</option>
              <option value="2869">Kujawsko-Pomorskie</option>
              <option value="2870">Lodzkie</option>
              <option value="2871">Lubelskie</option>
              <option value="2872">Lubuskie</option>
              <option value="2873">Malomice</option>
              <option value="2874">Malopolskie</option>
              <option value="2875">Mazowieckie</option>
              <option value="2876">Mirkow</option>
              <option value="2877">Opolskie</option>
              <option value="2878">Ostrowiec</option>
              <option value="2879">Podkarpackie</option>
              <option value="2880">Podlaskie</option>
              <option value="2881">Polska</option>
              <option value="2882">Pomorskie</option>
              <option value="2883">Poznan</option>
              <option value="2884">Pruszkow</option>
              <option value="2885">Rymanowska</option>
              <option value="2886">Rzeszow</option>
              <option value="2887">Slaskie</option>
              <option value="2888">Stare Pole</option>
              <option value="2889">Swietokrzyskie</option>
              <option value="2890">Warminsko-Mazurskie</option>
              <option value="2891">Warsaw</option>
              <option value="2892">Wejherowo</option>
              <option value="2893">Wielkopolskie</option>
              <option value="2894">Wroclaw</option>
              <option value="2895">Zachodnio-Pomorskie</option>
              <option value="2896">Zukowo</option>
              <option value="2897">Abrantes</option>
              <option value="2898">Acores</option>
              <option value="2899">Alentejo</option>
              <option value="2900">Algarve</option>
              <option value="2901">Braga</option>
              <option value="2902">Centro</option>
              <option value="2903">Distrito de Leiria</option>
              <option value="2904">Distrito de Viana do Castelo</option>
              <option value="2905">Distrito de Vila Real</option>
              <option value="2906">Distrito do Porto</option>
              <option value="2907">Lisboa e Vale do Tejo</option>
              <option value="2908">Madeira</option>
              <option value="2909">Norte</option>
              <option value="2910">Paivas</option>
              <option value="2911">Arecibo</option>
              <option value="2912">Bayamon</option>
              <option value="2913">Carolina</option>
              <option value="2914">Florida</option>
              <option value="2915">Guayama</option>
              <option value="2916">Humacao</option>
              <option value="2917">Mayaguez-Aguadilla</option>
              <option value="2918">Ponce</option>
              <option value="2919">Salinas</option>
              <option value="2920">San Juan</option>
              <option value="2921">Doha</option>
              <option value="2922">Jarian-al-Batnah</option>
              <option value="2923">Umm Salal</option>
              <option value="2924">ad-Dawhah</option>
              <option value="2925">al-Ghuwayriyah</option>
              <option value="2926">al-Jumayliyah</option>
              <option value="2927">al-Khawr</option>
              <option value="2928">al-Wakrah</option>
              <option value="2929">ar-Rayyan</option>
              <option value="2930">ash-Shamal</option>
              <option value="2931">Saint-Benoit</option>
              <option value="2932">Saint-Denis</option>
              <option value="2933">Saint-Paul</option>
              <option value="2934">Saint-Pierre</option>
              <option value="2935">Alba</option>
              <option value="2936">Arad</option>
              <option value="2937">Arges</option>
              <option value="2938">Bacau</option>
              <option value="2939">Bihor</option>
              <option value="2940">Bistrita-Nasaud</option>
              <option value="2941">Botosani</option>
              <option value="2942">Braila</option>
              <option value="2943">Brasov</option>
              <option value="2944">Bucuresti</option>
              <option value="2945">Buzau</option>
              <option value="2946">Calarasi</option>
              <option value="2947">Caras-Severin</option>
              <option value="2948">Cluj</option>
              <option value="2949">Constanta</option>
              <option value="2950">Covasna</option>
              <option value="2951">Dambovita</option>
              <option value="2952">Dolj</option>
              <option value="2953">Galati</option>
              <option value="2954">Giurgiu</option>
              <option value="2955">Gorj</option>
              <option value="2956">Harghita</option>
              <option value="2957">Hunedoara</option>
              <option value="2958">Ialomita</option>
              <option value="2959">Iasi</option>
              <option value="2960">Ilfov</option>
              <option value="2961">Maramures</option>
              <option value="2962">Mehedinti</option>
              <option value="2963">Mures</option>
              <option value="2964">Neamt</option>
              <option value="2965">Olt</option>
              <option value="2966">Prahova</option>
              <option value="2967">Salaj</option>
              <option value="2968">Satu Mare</option>
              <option value="2969">Sibiu</option>
              <option value="2970">Sondelor</option>
              <option value="2971">Suceava</option>
              <option value="2972">Teleorman</option>
              <option value="2973">Timis</option>
              <option value="2974">Tulcea</option>
              <option value="2975">Valcea</option>
              <option value="2976">Vaslui</option>
              <option value="2977">Vrancea</option>
              <option value="2978">Adygeja</option>
              <option value="2979">Aga</option>
              <option value="2980">Alanija</option>
              <option value="2981">Altaj</option>
              <option value="2982">Amur</option>
              <option value="2983">Arhangelsk</option>
              <option value="2984">Astrahan</option>
              <option value="2985">Bashkortostan</option>
              <option value="2986">Belgorod</option>
              <option value="2987">Brjansk</option>
              <option value="2988">Burjatija</option>
              <option value="2989">Chechenija</option>
              <option value="2990">Cheljabinsk</option>
              <option value="2991">Chita</option>
              <option value="2992">Chukotka</option>
              <option value="2993">Chuvashija</option>
              <option value="2994">Dagestan</option>
              <option value="2995">Evenkija</option>
              <option value="2996">Gorno-Altaj</option>
              <option value="2997">Habarovsk</option>
              <option value="2998">Hakasija</option>
              <option value="2999">Hanty-Mansija</option>
              <option value="3000">Ingusetija</option>
              <option value="3001">Irkutsk</option>
              <option value="3002">Ivanovo</option>
              <option value="3003">Jamalo-Nenets</option>
              <option value="3004">Jaroslavl</option>
              <option value="3005">Jevrej</option>
              <option value="3006">Kabardino-Balkarija</option>
              <option value="3007">Kaliningrad</option>
              <option value="3008">Kalmykija</option>
              <option value="3009">Kaluga</option>
              <option value="3010">Kamchatka</option>
              <option value="3011">Karachaj-Cherkessija</option>
              <option value="3012">Karelija</option>
              <option value="3013">Kemerovo</option>
              <option value="3014">Khabarovskiy Kray</option>
              <option value="3015">Kirov</option>
              <option value="3016">Komi</option>
              <option value="3017">Komi-Permjakija</option>
              <option value="3018">Korjakija</option>
              <option value="3019">Kostroma</option>
              <option value="3020">Krasnodar</option>
              <option value="3021">Krasnojarsk</option>
              <option value="3022">Krasnoyarskiy Kray</option>
              <option value="3023">Kurgan</option>
              <option value="3024">Kursk</option>
              <option value="3025">Leningrad</option>
              <option value="3026">Lipeck</option>
              <option value="3027">Magadan</option>
              <option value="3028">Marij El</option>
              <option value="3029">Mordovija</option>
              <option value="3030">Moscow</option>
              <option value="3031">Moskovskaja Oblast</option>
              <option value="3032">Moskovskaya Oblast</option>
              <option value="3033">Moskva</option>
              <option value="3034">Murmansk</option>
              <option value="3035">Nenets</option>
              <option value="3036">Nizhnij Novgorod</option>
              <option value="3037">Novgorod</option>
              <option value="3038">Novokusnezk</option>
              <option value="3039">Novosibirsk</option>
              <option value="3040">Omsk</option>
              <option value="3041">Orenburg</option>
              <option value="3042">Orjol</option>
              <option value="3043">Penza</option>
              <option value="3044">Perm</option>
              <option value="3045">Primorje</option>
              <option value="3046">Pskov</option>
              <option value="3047">Pskovskaya Oblast</option>
              <option value="3048">Rjazan</option>
              <option value="3049">Rostov</option>
              <option value="3050">Saha</option>
              <option value="3051">Sahalin</option>
              <option value="3052">Samara</option>
              <option value="3053">Samarskaya</option>
              <option value="3054">Sankt-Peterburg</option>
              <option value="3055">Saratov</option>
              <option value="3056">Smolensk</option>
              <option value="3057">Stavropol</option>
              <option value="3058">Sverdlovsk</option>
              <option value="3059">Tajmyrija</option>
              <option value="3060">Tambov</option>
              <option value="3061">Tatarstan</option>
              <option value="3062">Tjumen</option>
              <option value="3063">Tomsk</option>
              <option value="3064">Tula</option>
              <option value="3065">Tver</option>
              <option value="3066">Tyva</option>
              <option value="3067">Udmurtija</option>
              <option value="3068">Uljanovsk</option>
              <option value="3069">Ulyanovskaya Oblast</option>
              <option value="3070">Ust-Orda</option>
              <option value="3071">Vladimir</option>
              <option value="3072">Volgograd</option>
              <option value="3073">Vologda</option>
              <option value="3074">Voronezh</option>
              <option value="3075">Butare</option>
              <option value="3076">Byumba</option>
              <option value="3077">Cyangugu</option>
              <option value="3078">Gikongoro</option>
              <option value="3079">Gisenyi</option>
              <option value="3080">Gitarama</option>
              <option value="3081">Kibungo</option>
              <option value="3082">Kibuye</option>
              <option value="3083">Kigali-ngali</option>
              <option value="3084">Ruhengeri</option>
              <option value="3085">Ascension</option>
              <option value="3086">Gough Island</option>
              <option value="3087">Saint Helena</option>
              <option value="3088">Tristan da Cunha</option>
              <option value="3089">Christ Church Nichola Town</option>
              <option value="3090">Saint Anne Sandy Point</option>
              <option value="3091">Saint George Basseterre</option>
              <option value="3092">Saint George Gingerland</option>
              <option value="3093">Saint James Windward</option>
              <option value="3094">Saint John Capesterre</option>
              <option value="3095">Saint John Figtree</option>
              <option value="3096">Saint Mary Cayon</option>
              <option value="3097">Saint Paul Capesterre</option>
              <option value="3098">Saint Paul Charlestown</option>
              <option value="3099">Saint Peter Basseterre</option>
              <option value="3100">Saint Thomas Lowland</option>
              <option value="3101">Saint Thomas Middle Island</option>
              <option value="3102">Trinity Palmetto Point</option>
              <option value="3103">Anse-la-Raye</option>
              <option value="3104">Canaries</option>
              <option value="3105">Castries</option>
              <option value="3106">Choiseul</option>
              <option value="3107">Dennery</option>
              <option value="3108">Gros Inlet</option>
              <option value="3109">Laborie</option>
              <option value="3110">Micoud</option>
              <option value="3111">Soufriere</option>
              <option value="3112">Vieux Fort</option>
              <option value="3113">Miquelon-Langlade</option>
              <option value="3114">Saint-Pierre</option>
              <option value="3115">Charlotte</option>
              <option value="3116">Grenadines</option>
              <option value="3117">Saint Andrew</option>
              <option value="3118">Saint David</option>
              <option value="3119">Saint George</option>
              <option value="3120">Saint Patrick</option>
              <option value="3121">A'ana</option>
              <option value="3122">Aiga-i-le-Tai</option>
              <option value="3123">Atua</option>
              <option value="3124">Fa'asaleleaga</option>
              <option value="3125">Gaga'emauga</option>
              <option value="3126">Gagaifomauga</option>
              <option value="3127">Palauli</option>
              <option value="3128">Satupa'itea</option>
              <option value="3129">Tuamasaga</option>
              <option value="3130">Va'a-o-Fonoti</option>
              <option value="3131">Vaisigano</option>
              <option value="3132">Acquaviva</option>
              <option value="3133">Borgo Maggiore</option>
              <option value="3134">Chiesanuova</option>
              <option value="3135">Domagnano</option>
              <option value="3136">Faetano</option>
              <option value="3137">Fiorentino</option>
              <option value="3138">Montegiardino</option>
              <option value="3139">San Marino</option>
              <option value="3140">Serravalle</option>
              <option value="3141">Agua Grande</option>
              <option value="3142">Cantagalo</option>
              <option value="3143">Lemba</option>
              <option value="3144">Lobata</option>
              <option value="3145">Me-Zochi</option>
              <option value="3146">Pague</option>
              <option value="3147">Al Khobar</option>
              <option value="3148">Aseer</option>
              <option value="3149">Ash Sharqiyah</option>
              <option value="3150">Asir</option>
              <option value="3151">Central Province</option>
              <option value="3152">Eastern Province</option>
              <option value="3153">Ha'il</option>
              <option value="3154">Jawf</option>
              <option value="3155">Jizan</option>
              <option value="3156">Makkah</option>
              <option value="3157">Najran</option>
              <option value="3158">Qasim</option>
              <option value="3159">Tabuk</option>
              <option value="3160">Western Province</option>
              <option value="3161">al-Bahah</option>
              <option value="3162">al-Hudud-ash-Shamaliyah</option>
              <option value="3163">al-Madinah</option>
              <option value="3164">ar-Riyad</option>
              <option value="3165">Dakar</option>
              <option value="3166">Diourbel</option>
              <option value="3167">Fatick</option>
              <option value="3168">Kaolack</option>
              <option value="3169">Kolda</option>
              <option value="3170">Louga</option>
              <option value="3171">Saint-Louis</option>
              <option value="3172">Tambacounda</option>
              <option value="3173">Thies</option>
              <option value="3174">Ziguinchor</option>
              <option value="3175">Central Serbia</option>
              <option value="3176">Kosovo and Metohija</option>
              <option value="3177">Vojvodina</option>
              <option value="3178">Anse Boileau</option>
              <option value="3179">Anse Royale</option>
              <option value="3180">Cascade</option>
              <option value="3181">Takamaka</option>
              <option value="3182">Victoria</option>
              <option value="3183">Eastern</option>
              <option value="3184">Northern</option>
              <option value="3185">Southern</option>
              <option value="3186">Western</option>
              <option value="3187">Singapore</option>
              <option value="3188">Banskobystricky</option>
              <option value="3189">Bratislavsky</option>
              <option value="3190">Kosicky</option>
              <option value="3191">Nitriansky</option>
              <option value="3192">Presovsky</option>
              <option value="3193">Trenciansky</option>
              <option value="3194">Trnavsky</option>
              <option value="3195">Zilinsky</option>
              <option value="3196">Benedikt</option>
              <option value="3197">Gorenjska</option>
              <option value="3198">Gorishka</option>
              <option value="3199">Jugovzhodna Slovenija</option>
              <option value="3200">Koroshka</option>
              <option value="3201">Notranjsko-krashka</option>
              <option value="3202">Obalno-krashka</option>
              <option value="3203">Obcina Domzale</option>
              <option value="3204">Obcina Vitanje</option>
              <option value="3205">Osrednjeslovenska</option>
              <option value="3206">Podravska</option>
              <option value="3207">Pomurska</option>
              <option value="3208">Savinjska</option>
              <option value="3209">Slovenian Littoral</option>
              <option value="3210">Spodnjeposavska</option>
              <option value="3211">Zasavska</option>
              <option value="3213">Central</option>
              <option value="3214">Choiseul</option>
              <option value="3215">Guadalcanal</option>
              <option value="3216">Isabel</option>
              <option value="3217">Makira and Ulawa</option>
              <option value="3218">Malaita</option>
              <option value="3219">Rennell and Bellona</option>
              <option value="3220">Temotu</option>
              <option value="3221">Western</option>
              <option value="3222">Awdal</option>
              <option value="3223">Bakol</option>
              <option value="3224">Banadir</option>
              <option value="3225">Bari</option>
              <option value="3226">Bay</option>
              <option value="3227">Galgudug</option>
              <option value="3228">Gedo</option>
              <option value="3229">Hiran</option>
              <option value="3230">Jubbada Hose</option>
              <option value="3231">Jubbadha Dexe</option>
              <option value="3232">Mudug</option>
              <option value="3233">Nugal</option>
              <option value="3234">Sanag</option>
              <option value="3235">Shabellaha Dhexe</option>
              <option value="3236">Shabellaha Hose</option>
              <option value="3237">Togdher</option>
              <option value="3238">Woqoyi Galbed</option>
              <option value="3239">Eastern Cape</option>
              <option value="3240">Free State</option>
              <option value="3241">Gauteng</option>
              <option value="3242">Kempton Park</option>
              <option value="3243">Kramerville</option>
              <option value="3244">KwaZulu Natal</option>
              <option value="3245">Limpopo</option>
              <option value="3246">Mpumalanga</option>
              <option value="3247">North West</option>
              <option value="3248">Northern Cape</option>
              <option value="3249">Parow</option>
              <option value="3250">Table View</option>
              <option value="3251">Umtentweni</option>
              <option value="3252">Western Cape</option>
              <option value="3253">South Georgia</option>
              <option value="3254">Central Equatoria</option>
              <option value="3255">A Coruna</option>
              <option value="3256">Alacant</option>
              <option value="3257">Alava</option>
              <option value="3258">Albacete</option>
              <option value="3259">Almeria</option>
              <option value="3260">Andalucia</option>
              <option value="3261">Asturias</option>
              <option value="3262">Avila</option>
              <option value="3263">Badajoz</option>
              <option value="3264">Balears</option>
              <option value="3265">Barcelona</option>
              <option value="3266">Bertamirans</option>
              <option value="3267">Biscay</option>
              <option value="3268">Burgos</option>
              <option value="3269">Caceres</option>
              <option value="3270">Cadiz</option>
              <option value="3271">Cantabria</option>
              <option value="3272">Castello</option>
              <option value="3273">Catalunya</option>
              <option value="3274">Ceuta</option>
              <option value="3275">Ciudad Real</option>
              <option value="3276">Comunidad Autonoma de Canarias</option>
              <option value="3277">Comunidad Autonoma de Cataluna</option>
              <option value="3278">Comunidad Autonoma de Galicia</option>
              <option value="3279">Comunidad Autonoma de las Isla</option>
              <option value="3280">Comunidad Autonoma del Princip</option>
              <option value="3281">Comunidad Valenciana</option>
              <option value="3282">Cordoba</option>
              <option value="3283">Cuenca</option>
              <option value="3284">Gipuzkoa</option>
              <option value="3285">Girona</option>
              <option value="3286">Granada</option>
              <option value="3287">Guadalajara</option>
              <option value="3288">Guipuzcoa</option>
              <option value="3289">Huelva</option>
              <option value="3290">Huesca</option>
              <option value="3291">Jaen</option>
              <option value="3292">La Rioja</option>
              <option value="3293">Las Palmas</option>
              <option value="3294">Leon</option>
              <option value="3295">Lerida</option>
              <option value="3296">Lleida</option>
              <option value="3297">Lugo</option>
              <option value="3298">Madrid</option>
              <option value="3299">Malaga</option>
              <option value="3300">Melilla</option>
              <option value="3301">Murcia</option>
              <option value="3302">Navarra</option>
              <option value="3303">Ourense</option>
              <option value="3304">Pais Vasco</option>
              <option value="3305">Palencia</option>
              <option value="3306">Pontevedra</option>
              <option value="3307">Salamanca</option>
              <option value="3308">Santa Cruz de Tenerife</option>
              <option value="3309">Segovia</option>
              <option value="3310">Sevilla</option>
              <option value="3311">Soria</option>
              <option value="3312">Tarragona</option>
              <option value="3313">Tenerife</option>
              <option value="3314">Teruel</option>
              <option value="3315">Toledo</option>
              <option value="3316">Valencia</option>
              <option value="3317">Valladolid</option>
              <option value="3318">Vizcaya</option>
              <option value="3319">Zamora</option>
              <option value="3320">Zaragoza</option>
              <option value="3321">Amparai</option>
              <option value="3322">Anuradhapuraya</option>
              <option value="3323">Badulla</option>
              <option value="3324">Boralesgamuwa</option>
              <option value="3325">Colombo</option>
              <option value="3326">Galla</option>
              <option value="3327">Gampaha</option>
              <option value="3328">Hambantota</option>
              <option value="3329">Kalatura</option>
              <option value="3330">Kegalla</option>
              <option value="3331">Kilinochchi</option>
              <option value="3332">Kurunegala</option>
              <option value="3333">Madakalpuwa</option>
              <option value="3334">Maha Nuwara</option>
              <option value="3335">Malwana</option>
              <option value="3336">Mannarama</option>
              <option value="3337">Matale</option>
              <option value="3338">Matara</option>
              <option value="3339">Monaragala</option>
              <option value="3340">Mullaitivu</option>
              <option value="3341">North Eastern Province</option>
              <option value="3342">North Western Province</option>
              <option value="3343">Nuwara Eliya</option>
              <option value="3344">Polonnaruwa</option>
              <option value="3345">Puttalama</option>
              <option value="3346">Ratnapuraya</option>
              <option value="3347">Southern Province</option>
              <option value="3348">Tirikunamalaya</option>
              <option value="3349">Tuscany</option>
              <option value="3350">Vavuniyawa</option>
              <option value="3351">Western Province</option>
              <option value="3352">Yapanaya</option>
              <option value="3353">kadawatha</option>
              <option value="3354">A'ali-an-Nil</option>
              <option value="3355">Bahr-al-Jabal</option>
              <option value="3356">Central Equatoria</option>
              <option value="3357">Gharb Bahr-al-Ghazal</option>
              <option value="3358">Gharb Darfur</option>
              <option value="3359">Gharb Kurdufan</option>
              <option value="3360">Gharb-al-Istiwa'iyah</option>
              <option value="3361">Janub Darfur</option>
              <option value="3362">Janub Kurdufan</option>
              <option value="3363">Junqali</option>
              <option value="3364">Kassala</option>
              <option value="3365">Nahr-an-Nil</option>
              <option value="3366">Shamal Bahr-al-Ghazal</option>
              <option value="3367">Shamal Darfur</option>
              <option value="3368">Shamal Kurdufan</option>
              <option value="3369">Sharq-al-Istiwa'iyah</option>
              <option value="3370">Sinnar</option>
              <option value="3371">Warab</option>
              <option value="3372">Wilayat al Khartum</option>
              <option value="3373">al-Bahr-al-Ahmar</option>
              <option value="3374">al-Buhayrat</option>
              <option value="3375">al-Jazirah</option>
              <option value="3376">al-Khartum</option>
              <option value="3377">al-Qadarif</option>
              <option value="3378">al-Wahdah</option>
              <option value="3379">an-Nil-al-Abyad</option>
              <option value="3380">an-Nil-al-Azraq</option>
              <option value="3381">ash-Shamaliyah</option>
              <option value="3382">Brokopondo</option>
              <option value="3383">Commewijne</option>
              <option value="3384">Coronie</option>
              <option value="3385">Marowijne</option>
              <option value="3386">Nickerie</option>
              <option value="3387">Para</option>
              <option value="3388">Paramaribo</option>
              <option value="3389">Saramacca</option>
              <option value="3390">Wanica</option>
              <option value="3391">Svalbard</option>
              <option value="3392">Hhohho</option>
              <option value="3393">Lubombo</option>
              <option value="3394">Manzini</option>
              <option value="3395">Shiselweni</option>
              <option value="3396">Alvsborgs Lan</option>
              <option value="3397">Angermanland</option>
              <option value="3398">Blekinge</option>
              <option value="3399">Bohuslan</option>
              <option value="3400">Dalarna</option>
              <option value="3401">Gavleborg</option>
              <option value="3402">Gaza</option>
              <option value="3403">Gotland</option>
              <option value="3404">Halland</option>
              <option value="3405">Jamtland</option>
              <option value="3406">Jonkoping</option>
              <option value="3407">Kalmar</option>
              <option value="3408">Kristianstads</option>
              <option value="3409">Kronoberg</option>
              <option value="3410">Norrbotten</option>
              <option value="3411">Orebro</option>
              <option value="3412">Ostergotland</option>
              <option value="3413">Saltsjo-Boo</option>
              <option value="3414">Skane</option>
              <option value="3415">Smaland</option>
              <option value="3416">Sodermanland</option>
              <option value="3417">Stockholm</option>
              <option value="3418">Uppsala</option>
              <option value="3419">Varmland</option>
              <option value="3420">Vasterbotten</option>
              <option value="3421">Vastergotland</option>
              <option value="3422">Vasternorrland</option>
              <option value="3423">Vastmanland</option>
              <option value="3424">Vastra Gotaland</option>
              <option value="3425">Aargau</option>
              <option value="3426">Appenzell Inner-Rhoden</option>
              <option value="3427">Appenzell-Ausser Rhoden</option>
              <option value="3428">Basel-Landschaft</option>
              <option value="3429">Basel-Stadt</option>
              <option value="3430">Bern</option>
              <option value="3431">Canton Ticino</option>
              <option value="3432">Fribourg</option>
              <option value="3433">Geneve</option>
              <option value="3434">Glarus</option>
              <option value="3435">Graubunden</option>
              <option value="3436">Heerbrugg</option>
              <option value="3437">Jura</option>
              <option value="3438">Kanton Aargau</option>
              <option value="3439">Luzern</option>
              <option value="3440">Morbio Inferiore</option>
              <option value="3441">Muhen</option>
              <option value="3442">Neuchatel</option>
              <option value="3443">Nidwalden</option>
              <option value="3444">Obwalden</option>
              <option value="3445">Sankt Gallen</option>
              <option value="3446">Schaffhausen</option>
              <option value="3447">Schwyz</option>
              <option value="3448">Solothurn</option>
              <option value="3449">Thurgau</option>
              <option value="3450">Ticino</option>
              <option value="3451">Uri</option>
              <option value="3452">Valais</option>
              <option value="3453">Vaud</option>
              <option value="3454">Vauffelin</option>
              <option value="3455">Zug</option>
              <option value="3456">Zurich</option>
              <option value="3457">Aleppo</option>
              <option value="3458">Dar'a</option>
              <option value="3459">Dayr-az-Zawr</option>
              <option value="3460">Dimashq</option>
              <option value="3461">Halab</option>
              <option value="3462">Hamah</option>
              <option value="3463">Hims</option>
              <option value="3464">Idlib</option>
              <option value="3465">Madinat Dimashq</option>
              <option value="3466">Tartus</option>
              <option value="3467">al-Hasakah</option>
              <option value="3468">al-Ladhiqiyah</option>
              <option value="3469">al-Qunaytirah</option>
              <option value="3470">ar-Raqqah</option>
              <option value="3471">as-Suwayda</option>
              <option value="3472">Changhwa</option>
              <option value="3473">Chiayi Hsien</option>
              <option value="3474">Chiayi Shih</option>
              <option value="3475">Eastern Taipei</option>
              <option value="3476">Hsinchu Hsien</option>
              <option value="3477">Hsinchu Shih</option>
              <option value="3478">Hualien</option>
              <option value="3479">Ilan</option>
              <option value="3480">Kaohsiung Hsien</option>
              <option value="3481">Kaohsiung Shih</option>
              <option value="3482">Keelung Shih</option>
              <option value="3483">Kinmen</option>
              <option value="3484">Miaoli</option>
              <option value="3485">Nantou</option>
              <option value="3486">Northern Taiwan</option>
              <option value="3487">Penghu</option>
              <option value="3488">Pingtung</option>
              <option value="3489">Taichung</option>
              <option value="3490">Taichung Hsien</option>
              <option value="3491">Taichung Shih</option>
              <option value="3492">Tainan Hsien</option>
              <option value="3493">Tainan Shih</option>
              <option value="3494">Taipei Hsien</option>
              <option value="3495">Taipei Shih / Taipei Hsien</option>
              <option value="3496">Taitung</option>
              <option value="3497">Taoyuan</option>
              <option value="3498">Yilan</option>
              <option value="3499">Yun-Lin Hsien</option>
              <option value="3500">Yunlin</option>
              <option value="3501">Dushanbe</option>
              <option value="3502">Gorno-Badakhshan</option>
              <option value="3503">Karotegin</option>
              <option value="3504">Khatlon</option>
              <option value="3505">Sughd</option>
              <option value="3506">Arusha</option>
              <option value="3507">Dar es Salaam</option>
              <option value="3508">Dodoma</option>
              <option value="3509">Iringa</option>
              <option value="3510">Kagera</option>
              <option value="3511">Kigoma</option>
              <option value="3512">Kilimanjaro</option>
              <option value="3513">Lindi</option>
              <option value="3514">Mara</option>
              <option value="3515">Mbeya</option>
              <option value="3516">Morogoro</option>
              <option value="3517">Mtwara</option>
              <option value="3518">Mwanza</option>
              <option value="3519">Pwani</option>
              <option value="3520">Rukwa</option>
              <option value="3521">Ruvuma</option>
              <option value="3522">Shinyanga</option>
              <option value="3523">Singida</option>
              <option value="3524">Tabora</option>
              <option value="3525">Tanga</option>
              <option value="3526">Zanzibar and Pemba</option>
              <option value="3527">Amnat Charoen</option>
              <option value="3528">Ang Thong</option>
              <option value="3529">Bangkok</option>
              <option value="3530">Buri Ram</option>
              <option value="3531">Chachoengsao</option>
              <option value="3532">Chai Nat</option>
              <option value="3533">Chaiyaphum</option>
              <option value="3534">Changwat Chaiyaphum</option>
              <option value="3535">Chanthaburi</option>
              <option value="3536">Chiang Mai</option>
              <option value="3537">Chiang Rai</option>
              <option value="3538">Chon Buri</option>
              <option value="3539">Chumphon</option>
              <option value="3540">Kalasin</option>
              <option value="3541">Kamphaeng Phet</option>
              <option value="3542">Kanchanaburi</option>
              <option value="3543">Khon Kaen</option>
              <option value="3544">Krabi</option>
              <option value="3545">Krung Thep</option>
              <option value="3546">Lampang</option>
              <option value="3547">Lamphun</option>
              <option value="3548">Loei</option>
              <option value="3549">Lop Buri</option>
              <option value="3550">Mae Hong Son</option>
              <option value="3551">Maha Sarakham</option>
              <option value="3552">Mukdahan</option>
              <option value="3553">Nakhon Nayok</option>
              <option value="3554">Nakhon Pathom</option>
              <option value="3555">Nakhon Phanom</option>
              <option value="3556">Nakhon Ratchasima</option>
              <option value="3557">Nakhon Sawan</option>
              <option value="3558">Nakhon Si Thammarat</option>
              <option value="3559">Nan</option>
              <option value="3560">Narathiwat</option>
              <option value="3561">Nong Bua Lam Phu</option>
              <option value="3562">Nong Khai</option>
              <option value="3563">Nonthaburi</option>
              <option value="3564">Pathum Thani</option>
              <option value="3565">Pattani</option>
              <option value="3566">Phangnga</option>
              <option value="3567">Phatthalung</option>
              <option value="3568">Phayao</option>
              <option value="3569">Phetchabun</option>
              <option value="3570">Phetchaburi</option>
              <option value="3571">Phichit</option>
              <option value="3572">Phitsanulok</option>
              <option value="3573">Phra Nakhon Si Ayutthaya</option>
              <option value="3574">Phrae</option>
              <option value="3575">Phuket</option>
              <option value="3576">Prachin Buri</option>
              <option value="3577">Prachuap Khiri Khan</option>
              <option value="3578">Ranong</option>
              <option value="3579">Ratchaburi</option>
              <option value="3580">Rayong</option>
              <option value="3581">Roi Et</option>
              <option value="3582">Sa Kaeo</option>
              <option value="3583">Sakon Nakhon</option>
              <option value="3584">Samut Prakan</option>
              <option value="3585">Samut Sakhon</option>
              <option value="3586">Samut Songkhran</option>
              <option value="3587">Saraburi</option>
              <option value="3588">Satun</option>
              <option value="3589">Si Sa Ket</option>
              <option value="3590">Sing Buri</option>
              <option value="3591">Songkhla</option>
              <option value="3592">Sukhothai</option>
              <option value="3593">Suphan Buri</option>
              <option value="3594">Surat Thani</option>
              <option value="3595">Surin</option>
              <option value="3596">Tak</option>
              <option value="3597">Trang</option>
              <option value="3598">Trat</option>
              <option value="3599">Ubon Ratchathani</option>
              <option value="3600">Udon Thani</option>
              <option value="3601">Uthai Thani</option>
              <option value="3602">Uttaradit</option>
              <option value="3603">Yala</option>
              <option value="3604">Yasothon</option>
              <option value="3605">Centre</option>
              <option value="3606">Kara</option>
              <option value="3607">Maritime</option>
              <option value="3608">Plateaux</option>
              <option value="3609">Savanes</option>
              <option value="3610">Atafu</option>
              <option value="3611">Fakaofo</option>
              <option value="3612">Nukunonu</option>
              <option value="3613">Eua</option>
              <option value="3614">Ha'apai</option>
              <option value="3615">Niuas</option>
              <option value="3616">Tongatapu</option>
              <option value="3617">Vava'u</option>
              <option value="3618">Arima-Tunapuna-Piarco</option>
              <option value="3619">Caroni</option>
              <option value="3620">Chaguanas</option>
              <option value="3621">Couva-Tabaquite-Talparo</option>
              <option value="3622">Diego Martin</option>
              <option value="3623">Glencoe</option>
              <option value="3624">Penal Debe</option>
              <option value="3625">Point Fortin</option>
              <option value="3626">Port of Spain</option>
              <option value="3627">Princes Town</option>
              <option value="3628">Saint George</option>
              <option value="3629">San Fernando</option>
              <option value="3630">San Juan</option>
              <option value="3631">Sangre Grande</option>
              <option value="3632">Siparia</option>
              <option value="3633">Tobago</option>
              <option value="3634">Aryanah</option>
              <option value="3635">Bajah</option>
              <option value="3636">Bin 'Arus</option>
              <option value="3637">Binzart</option>
              <option value="3638">Gouvernorat de Ariana</option>
              <option value="3639">Gouvernorat de Nabeul</option>
              <option value="3640">Gouvernorat de Sousse</option>
              <option value="3641">Hammamet Yasmine</option>
              <option value="3642">Jundubah</option>
              <option value="3643">Madaniyin</option>
              <option value="3644">Manubah</option>
              <option value="3645">Monastir</option>
              <option value="3646">Nabul</option>
              <option value="3647">Qabis</option>
              <option value="3648">Qafsah</option>
              <option value="3649">Qibili</option>
              <option value="3650">Safaqis</option>
              <option value="3651">Sfax</option>
              <option value="3652">Sidi Bu Zayd</option>
              <option value="3653">Silyanah</option>
              <option value="3654">Susah</option>
              <option value="3655">Tatawin</option>
              <option value="3656">Tawzar</option>
              <option value="3657">Tunis</option>
              <option value="3658">Zaghwan</option>
              <option value="3659">al-Kaf</option>
              <option value="3660">al-Mahdiyah</option>
              <option value="3661">al-Munastir</option>
              <option value="3662">al-Qasrayn</option>
              <option value="3663">al-Qayrawan</option>
              <option value="3664">Adana</option>
              <option value="3665">Adiyaman</option>
              <option value="3666">Afyon</option>
              <option value="3667">Agri</option>
              <option value="3668">Aksaray</option>
              <option value="3669">Amasya</option>
              <option value="3670">Ankara</option>
              <option value="3671">Antalya</option>
              <option value="3672">Ardahan</option>
              <option value="3673">Artvin</option>
              <option value="3674">Aydin</option>
              <option value="3675">Balikesir</option>
              <option value="3676">Bartin</option>
              <option value="3677">Batman</option>
              <option value="3678">Bayburt</option>
              <option value="3679">Bilecik</option>
              <option value="3680">Bingol</option>
              <option value="3681">Bitlis</option>
              <option value="3682">Bolu</option>
              <option value="3683">Burdur</option>
              <option value="3684">Bursa</option>
              <option value="3685">Canakkale</option>
              <option value="3686">Cankiri</option>
              <option value="3687">Corum</option>
              <option value="3688">Denizli</option>
              <option value="3689">Diyarbakir</option>
              <option value="3690">Duzce</option>
              <option value="3691">Edirne</option>
              <option value="3692">Elazig</option>
              <option value="3693">Erzincan</option>
              <option value="3694">Erzurum</option>
              <option value="3695">Eskisehir</option>
              <option value="3696">Gaziantep</option>
              <option value="3697">Giresun</option>
              <option value="3698">Gumushane</option>
              <option value="3699">Hakkari</option>
              <option value="3700">Hatay</option>
              <option value="3701">Icel</option>
              <option value="3702">Igdir</option>
              <option value="3703">Isparta</option>
              <option value="3704">Istanbul</option>
              <option value="3705">Izmir</option>
              <option value="3706">Kahramanmaras</option>
              <option value="3707">Karabuk</option>
              <option value="3708">Karaman</option>
              <option value="3709">Kars</option>
              <option value="3710">Karsiyaka</option>
              <option value="3711">Kastamonu</option>
              <option value="3712">Kayseri</option>
              <option value="3713">Kilis</option>
              <option value="3714">Kirikkale</option>
              <option value="3715">Kirklareli</option>
              <option value="3716">Kirsehir</option>
              <option value="3717">Kocaeli</option>
              <option value="3718">Konya</option>
              <option value="3719">Kutahya</option>
              <option value="3720">Lefkosa</option>
              <option value="3721">Malatya</option>
              <option value="3722">Manisa</option>
              <option value="3723">Mardin</option>
              <option value="3724">Mugla</option>
              <option value="3725">Mus</option>
              <option value="3726">Nevsehir</option>
              <option value="3727">Nigde</option>
              <option value="3728">Ordu</option>
              <option value="3729">Osmaniye</option>
              <option value="3730">Rize</option>
              <option value="3731">Sakarya</option>
              <option value="3732">Samsun</option>
              <option value="3733">Sanliurfa</option>
              <option value="3734">Siirt</option>
              <option value="3735">Sinop</option>
              <option value="3736">Sirnak</option>
              <option value="3737">Sivas</option>
              <option value="3738">Tekirdag</option>
              <option value="3739">Tokat</option>
              <option value="3740">Trabzon</option>
              <option value="3741">Tunceli</option>
              <option value="3742">Usak</option>
              <option value="3743">Van</option>
              <option value="3744">Yalova</option>
              <option value="3745">Yozgat</option>
              <option value="3746">Zonguldak</option>
              <option value="3747">Ahal</option>
              <option value="3748">Asgabat</option>
              <option value="3749">Balkan</option>
              <option value="3750">Dasoguz</option>
              <option value="3751">Lebap</option>
              <option value="3752">Mari</option>
              <option value="3753">Grand Turk</option>
              <option value="3754">South Caicos and East Caicos</option>
              <option value="3755">Funafuti</option>
              <option value="3756">Nanumanga</option>
              <option value="3757">Nanumea</option>
              <option value="3758">Niutao</option>
              <option value="3759">Nui</option>
              <option value="3760">Nukufetau</option>
              <option value="3761">Nukulaelae</option>
              <option value="3762">Vaitupu</option>
              <option value="3763">Central</option>
              <option value="3764">Eastern</option>
              <option value="3765">Northern</option>
              <option value="3766">Western</option>
              <option value="3767">Cherkas'ka</option>
              <option value="3768">Chernihivs'ka</option>
              <option value="3769">Chernivets'ka</option>
              <option value="3770">Crimea</option>
              <option value="3771">Dnipropetrovska</option>
              <option value="3772">Donets'ka</option>
              <option value="3773">Ivano-Frankivs'ka</option>
              <option value="3774">Kharkiv</option>
              <option value="3775">Kharkov</option>
              <option value="3776">Khersonska</option>
              <option value="3777">Khmel'nyts'ka</option>
              <option value="3778">Kirovohrad</option>
              <option value="3779">Krym</option>
              <option value="3780">Kyyiv</option>
              <option value="3781">Kyyivs'ka</option>
              <option value="3782">L'vivs'ka</option>
              <option value="3783">Luhans'ka</option>
              <option value="3784">Mykolayivs'ka</option>
              <option value="3785">Odes'ka</option>
              <option value="3786">Odessa</option>
              <option value="3787">Poltavs'ka</option>
              <option value="3788">Rivnens'ka</option>
              <option value="3789">Sevastopol'</option>
              <option value="3790">Sums'ka</option>
              <option value="3791">Ternopil's'ka</option>
              <option value="3792">Volyns'ka</option>
              <option value="3793">Vynnyts'ka</option>
              <option value="3794">Zakarpats'ka</option>
              <option value="3795">Zaporizhia</option>
              <option value="3796">Zhytomyrs'ka</option>
              <option value="3797">Abu Zabi</option>
              <option value="3798">Ajman</option>
              <option value="3799">Dubai</option>
              <option value="3800">Ras al-Khaymah</option>
              <option value="3801">Sharjah</option>
              <option value="3802">Sharjha</option>
              <option value="3803">Umm al Qaywayn</option>
              <option value="3804">al-Fujayrah</option>
              <option value="3805">ash-Shariqah</option>
              <option value="3806">Aberdeen</option>
              <option value="3807">Aberdeenshire</option>
              <option value="3808">Argyll</option>
              <option value="3809">Armagh</option>
              <option value="3810">Bedfordshire</option>
              <option value="3811">Belfast</option>
              <option value="3812">Berkshire</option>
              <option value="3813">Birmingham</option>
              <option value="3814">Brechin</option>
              <option value="3815">Bridgnorth</option>
              <option value="3816">Bristol</option>
              <option value="3817">Buckinghamshire</option>
              <option value="3818">Cambridge</option>
              <option value="3819">Cambridgeshire</option>
              <option value="3820">Channel Islands</option>
              <option value="3821">Cheshire</option>
              <option value="3822">Cleveland</option>
              <option value="3823">Co Fermanagh</option>
              <option value="3824">Conwy</option>
              <option value="3825">Cornwall</option>
              <option value="3826">Coventry</option>
              <option value="3827">Craven Arms</option>
              <option value="3828">Cumbria</option>
              <option value="3829">Denbighshire</option>
              <option value="3830">Derby</option>
              <option value="3831">Derbyshire</option>
              <option value="3832">Devon</option>
              <option value="3833">Dial Code Dungannon</option>
              <option value="3834">Didcot</option>
              <option value="3835">Dorset</option>
              <option value="3836">Dunbartonshire</option>
              <option value="3837">Durham</option>
              <option value="3838">East Dunbartonshire</option>
              <option value="3839">East Lothian</option>
              <option value="3840">East Midlands</option>
              <option value="3841">East Sussex</option>
              <option value="3842">East Yorkshire</option>
              <option value="3843">England</option>
              <option value="3844">Essex</option>
              <option value="3845">Fermanagh</option>
              <option value="3846">Fife</option>
              <option value="3847">Flintshire</option>
              <option value="3848">Fulham</option>
              <option value="3849">Gainsborough</option>
              <option value="3850">Glocestershire</option>
              <option value="3851">Gwent</option>
              <option value="3852">Hampshire</option>
              <option value="3853">Hants</option>
              <option value="3854">Herefordshire</option>
              <option value="3855">Hertfordshire</option>
              <option value="3856">Ireland</option>
              <option value="3857">Isle Of Man</option>
              <option value="3858">Isle of Wight</option>
              <option value="3859">Kenford</option>
              <option value="3860">Kent</option>
              <option value="3861">Kilmarnock</option>
              <option value="3862">Lanarkshire</option>
              <option value="3863">Lancashire</option>
              <option value="3864">Leicestershire</option>
              <option value="3865">Lincolnshire</option>
              <option value="3866">Llanymynech</option>
              <option value="3867">London</option>
              <option value="3868">Ludlow</option>
              <option value="3869">Manchester</option>
              <option value="3870">Mayfair</option>
              <option value="3871">Merseyside</option>
              <option value="3872">Mid Glamorgan</option>
              <option value="3873">Middlesex</option>
              <option value="3874">Mildenhall</option>
              <option value="3875">Monmouthshire</option>
              <option value="3876">Newton Stewart</option>
              <option value="3877">Norfolk</option>
              <option value="3878">North Humberside</option>
              <option value="3879">North Yorkshire</option>
              <option value="3880">Northamptonshire</option>
              <option value="3881">Northants</option>
              <option value="3882">Northern Ireland</option>
              <option value="3883">Northumberland</option>
              <option value="3884">Nottinghamshire</option>
              <option value="3885">Oxford</option>
              <option value="3886">Powys</option>
              <option value="3887">Roos-shire</option>
              <option value="3888">SUSSEX</option>
              <option value="3889">Sark</option>
              <option value="3890">Scotland</option>
              <option value="3891">Scottish Borders</option>
              <option value="3892">Shropshire</option>
              <option value="3893">Somerset</option>
              <option value="3894">South Glamorgan</option>
              <option value="3895">South Wales</option>
              <option value="3896">South Yorkshire</option>
              <option value="3897">Southwell</option>
              <option value="3898">Staffordshire</option>
              <option value="3899">Strabane</option>
              <option value="3900">Suffolk</option>
              <option value="3901">Surrey</option>
              <option value="3902">Sussex</option>
              <option value="3903">Twickenham</option>
              <option value="3904">Tyne and Wear</option>
              <option value="3905">Tyrone</option>
              <option value="3906">Utah</option>
              <option value="3907">Wales</option>
              <option value="3908">Warwickshire</option>
              <option value="3909">West Lothian</option>
              <option value="3910">West Midlands</option>
              <option value="3911">West Sussex</option>
              <option value="3912">West Yorkshire</option>
              <option value="3913">Whissendine</option>
              <option value="3914">Wiltshire</option>
              <option value="3915">Wokingham</option>
              <option value="3916">Worcestershire</option>
              <option value="3917">Wrexham</option>
              <option value="3918">Wurttemberg</option>
              <option value="3919">Yorkshire</option>
              <option value="3920">Alabama</option>
              <option value="3921">Alaska</option>
              <option value="3922">Arizona</option>
              <option value="3923">Arkansas</option>
              <option value="3924">Byram</option>
              <option value="3925">California</option>
              <option value="3926">Cokato</option>
              <option value="3927">Colorado</option>
              <option value="3928">Connecticut</option>
              <option value="3929">Delaware</option>
              <option value="3930">District of Columbia</option>
              <option value="3931">Florida</option>
              <option value="3932">Georgia</option>
              <option value="3933">Hawaii</option>
              <option value="3934">Idaho</option>
              <option value="3935">Illinois</option>
              <option value="3936">Indiana</option>
              <option value="3937">Iowa</option>
              <option value="3938">Kansas</option>
              <option value="3939">Kentucky</option>
              <option value="3940">Louisiana</option>
              <option value="3941">Lowa</option>
              <option value="3942">Maine</option>
              <option value="3943">Maryland</option>
              <option value="3944">Massachusetts</option>
              <option value="3945">Medfield</option>
              <option value="3946">Michigan</option>
              <option value="3947">Minnesota</option>
              <option value="3948">Mississippi</option>
              <option value="3949">Missouri</option>
              <option value="3950">Montana</option>
              <option value="3951">Nebraska</option>
              <option value="3952">Nevada</option>
              <option value="3953">New Hampshire</option>
              <option value="3954">New Jersey</option>
              <option value="3955">New Jersy</option>
              <option value="3956">New Mexico</option>
              <option value="3957">New York</option>
              <option value="3958">North Carolina</option>
              <option value="3959">North Dakota</option>
              <option value="3960">Ohio</option>
              <option value="3961">Oklahoma</option>
              <option value="3962">Ontario</option>
              <option value="3963">Oregon</option>
              <option value="3964">Pennsylvania</option>
              <option value="3965">Ramey</option>
              <option value="3966">Rhode Island</option>
              <option value="3967">South Carolina</option>
              <option value="3968">South Dakota</option>
              <option value="3969">Sublimity</option>
              <option value="3970">Tennessee</option>
              <option value="3971">Texas</option>
              <option value="3972">Trimble</option>
              <option value="3973">Utah</option>
              <option value="3974">Vermont</option>
              <option value="3975">Virginia</option>
              <option value="3976">Washington</option>
              <option value="3977">West Virginia</option>
              <option value="3978">Wisconsin</option>
              <option value="3979">Wyoming</option>
              <option value="3980">United States Minor Outlying I</option>
              <option value="3981">Artigas</option>
              <option value="3982">Canelones</option>
              <option value="3983">Cerro Largo</option>
              <option value="3984">Colonia</option>
              <option value="3985">Durazno</option>
              <option value="3986">FLorida</option>
              <option value="3987">Flores</option>
              <option value="3988">Lavalleja</option>
              <option value="3989">Maldonado</option>
              <option value="3990">Montevideo</option>
              <option value="3991">Paysandu</option>
              <option value="3992">Rio Negro</option>
              <option value="3993">Rivera</option>
              <option value="3994">Rocha</option>
              <option value="3995">Salto</option>
              <option value="3996">San Jose</option>
              <option value="3997">Soriano</option>
              <option value="3998">Tacuarembo</option>
              <option value="3999">Treinta y Tres</option>
              <option value="4000">Andijon</option>
              <option value="4001">Buhoro</option>
              <option value="4002">Buxoro Viloyati</option>
              <option value="4003">Cizah</option>
              <option value="4004">Fargona</option>
              <option value="4005">Horazm</option>
              <option value="4006">Kaskadar</option>
              <option value="4007">Korakalpogiston</option>
              <option value="4008">Namangan</option>
              <option value="4009">Navoi</option>
              <option value="4010">Samarkand</option>
              <option value="4011">Sirdare</option>
              <option value="4012">Surhondar</option>
              <option value="4013">Toskent</option>
              <option value="4014">Malampa</option>
              <option value="4015">Penama</option>
              <option value="4016">Sanma</option>
              <option value="4017">Shefa</option>
              <option value="4018">Tafea</option>
              <option value="4019">Torba</option>
              <option value="4020">Vatican City State (Holy See)</option>
              <option value="4021">Amazonas</option>
              <option value="4022">Anzoategui</option>
              <option value="4023">Apure</option>
              <option value="4024">Aragua</option>
              <option value="4025">Barinas</option>
              <option value="4026">Bolivar</option>
              <option value="4027">Carabobo</option>
              <option value="4028">Cojedes</option>
              <option value="4029">Delta Amacuro</option>
              <option value="4030">Distrito Federal</option>
              <option value="4031">Falcon</option>
              <option value="4032">Guarico</option>
              <option value="4033">Lara</option>
              <option value="4034">Merida</option>
              <option value="4035">Miranda</option>
              <option value="4036">Monagas</option>
              <option value="4037">Nueva Esparta</option>
              <option value="4038">Portuguesa</option>
              <option value="4039">Sucre</option>
              <option value="4040">Tachira</option>
              <option value="4041">Trujillo</option>
              <option value="4042">Vargas</option>
              <option value="4043">Yaracuy</option>
              <option value="4044">Zulia</option>
              <option value="4045">Bac Giang</option>
              <option value="4046">Binh Dinh</option>
              <option value="4047">Binh Duong</option>
              <option value="4048">Da Nang</option>
              <option value="4049">Dong Bang Song Cuu Long</option>
              <option value="4050">Dong Bang Song Hong</option>
              <option value="4051">Dong Nai</option>
              <option value="4052">Dong Nam Bo</option>
              <option value="4053">Duyen Hai Mien Trung</option>
              <option value="4054">Hanoi</option>
              <option value="4055">Hung Yen</option>
              <option value="4056">Khu Bon Cu</option>
              <option value="4057">Long An</option>
              <option value="4058">Mien Nui Va Trung Du</option>
              <option value="4059">Thai Nguyen</option>
              <option value="4060">Thanh Pho Ho Chi Minh</option>
              <option value="4061">Thu Do Ha Noi</option>
              <option value="4062">Tinh Can Tho</option>
              <option value="4063">Tinh Da Nang</option>
              <option value="4064">Tinh Gia Lai</option>
              <option value="4065">Anegada</option>
              <option value="4066">Jost van Dyke</option>
              <option value="4067">Tortola</option>
              <option value="4068">Saint Croix</option>
              <option value="4069">Saint John</option>
              <option value="4070">Saint Thomas</option>
              <option value="4071">Alo</option>
              <option value="4072">Singave</option>
              <option value="4073">Wallis</option>
              <option value="4074">Bu Jaydur</option>
              <option value="4075">Wad-adh-Dhahab</option>
              <option value="4076">al-'Ayun</option>
              <option value="4077">as-Samarah</option>
              <option value="4078">'Adan</option>
              <option value="4079">Abyan</option>
              <option value="4080">Dhamar</option>
              <option value="4081">Hadramaut</option>
              <option value="4082">Hajjah</option>
              <option value="4083">Hudaydah</option>
              <option value="4084">Ibb</option>
              <option value="4085">Lahij</option>
              <option value="4086">Ma'rib</option>
              <option value="4087">Madinat San'a</option>
              <option value="4088">Sa'dah</option>
              <option value="4089">Sana</option>
              <option value="4090">Shabwah</option>
              <option value="4091">Ta'izz</option>
              <option value="4092">al-Bayda</option>
              <option value="4093">al-Hudaydah</option>
              <option value="4094">al-Jawf</option>
              <option value="4095">al-Mahrah</option>
              <option value="4096">al-Mahwit</option>
              <option value="4103">Central</option>
              <option value="4104">Copperbelt</option>
              <option value="4105">Eastern</option>
              <option value="4106">Luapala</option>
              <option value="4107">Lusaka</option>
              <option value="4108">North-Western</option>
              <option value="4109">Northern</option>
              <option value="4110">Southern</option>
              <option value="4111">Western</option>
              <option value="4112">Bulawayo</option>
              <option value="4113">Harare</option>
              <option value="4114">Manicaland</option>
              <option value="4115">Mashonaland Central</option>
              <option value="4116">Mashonaland East</option>
              <option value="4117">Mashonaland West</option>
              <option value="4118">Masvingo</option>
              <option value="4119">Matabeleland North</option>
              <option value="4120">Matabeleland South</option>
              <option value="4121">Midlands</option>
            </Form.Select>
            <span className="validation-err text-danger">
              {formErros.state}
            </span>
          </Form.Group>
        </div>

        <div className="col-md-12">
          <Form.Group className="mb-3" controlId="zip">
            <Form.Label>
              Zipcode <span className="required-mark text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={formValues.zip}
              onChange={(event) =>
                setFormValues({ ...formValues, zip: event.target.value })
              }
            />
            <span className="validation-err text-danger">{formErros.zip}</span>
          </Form.Group>
        </div>

        <div className="col-md-12 d-flex">
          <Form.Check
            inline
            name="group1"
            type="checkbox"
            id={`inline-checkbox-1`}
            onChange={(event) =>
              setFormValues({
                ...formValues,
                terms_accepted: event.target.checked,
              })
            }
          />{" "}
          <label htmlFor="inline-checkbox-1">
            I have agreed to your <Link to="#">Jurisdiction</Link>,{" "}
            <Link to="#">Manual Feedback/ Ratings</Link> Policy
          </label>
          <div className="">
            <span className="validation-err text-danger">
              {formErros.terms_accepted}
            </span>
          </div>
        </div>

        {showAlert.show === true && (
          <DismissableAlert
            classes="mt-3"
            varient={showAlert.type}
            title={showAlert.title}
            desc={showAlert.desc}
          />
        )}

        <div className="col-md-12 text-center">
          <FilledPrimaryBtn
            classes="mt-4"
            label="Apply"
            type="submit"
            isLoading={showLoader}
          />
        </div>
      </div>
    </Form>
  );
}
