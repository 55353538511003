import { Helmet } from "react-helmet";

import BreadCrumb from "../../component/BreadCrumb";
import ScrollableHorizontalMenu from "../../component/ScrollableHorizontalMenu";
import UnderDevelopmentSection from "../../component/UnderDevelopmentSection";
import WatchCourseHeader from "./component/WatchCourseHeader";

import "./styles/WatchCoursePage.style.css";
import ShowLesson from "./component/ShowLesson";
import CourseContents from "./component/CourseContents";
import WatchCourseAdditionalDetails from "./component/WatchCourseAdditionalDetails";
import { useParams } from "react-router-dom";
import { useState } from "react";
import AxiosClient from "./../../client/AxiosClient";
import { API_GET_LESSON, API_WATCH_COURESE } from "../../ApiEndPoints";
import { useEffect } from "react";
import Loader from "../../component/Loader";
import { Axios } from "axios";

export default function WatchCoursePage() {
  const { course_slug } = useParams();
  const [courseData, setCourseData] = useState(null);

  const [metaInfo, setMetaInfo] = useState({
    title: "watching ...",
    keywords: "",
    description: "",
  });

  const [courseHeaderData, setCourseHeaderData] = useState({
    title: "",
    completion: "",
  });

  const [currentLessonIds, setCurrentLessonIds] = useState({
    courseId: "",
    chapterId: "",
    lessonId: "",
  });
  const [currentLessonData, setCurrentLessonData] = useState(null);
  const [lessonLoading, setLessonLoading] = useState(true);

  function reloadCourseContents() {}

  useEffect(() => {
    AxiosClient.get(API_WATCH_COURESE + course_slug)
      .then((response) => {
        const responseData = response.data;

        console.log("watching response", responseData);

        if (responseData.success === true) {
          setCourseHeaderData({
            title: responseData.data.title,
            completion: responseData.data.total_percentage,
          });

          setCourseData(responseData.data);
          console.log("current course data", responseData.data);
        }

        // setting current lesson initially

        setCurrentLessonIds({
          courseId: responseData.data.first_lesson.course_id,
          chapterId: responseData.data.first_lesson.chapter_id,
          lessonId: responseData.data.first_lesson.lesson_id,
        });
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  useEffect(() => {
    if (currentLessonIds.lessonId !== "") {
      console.log("current lesson ids:", currentLessonIds);
      getLesson();
    }
  }, [currentLessonIds]);

  function getLesson() {
    setLessonLoading(true);
    AxiosClient.get(
      `${API_GET_LESSON}${currentLessonIds.courseId}/${currentLessonIds.chapterId}/${currentLessonIds.lessonId}`
    )
      .then((response) => {
        setLessonLoading(false);

        console.log("lesson response: ", response.data.data);
        setCurrentLessonData(response.data.data);
      })
      .catch((error) => {
        console.error("Error while fetching lesson", error);
      })
      .finally(() => {
        setLessonLoading(false);
      });
  }

  const [reloadCourseContent, askToReloadContent] = useState(0);

  function setReloadCourseContent() {
    askToReloadContent(reloadCourseContent + 1);
  }

  return (
    <main>
      <Helmet>
        <title>Watching ... | {process.env.REACT_APP_NAME}</title>
        <meta
          name="keywords"
          content="online law courses, online law courses in india, law courses in india, management course, law course, civil services, finance course, cyber security course, earn free certificate, online job oriented courses, best online law courses, best online law courses in india"
        />
        <meta
          name="description"
          content="Take online management and law courses from top institution. Learn about business law to advance your education and career with IALM Academy Today!"
        />
      </Helmet>

      <section className="py-0">
        <WatchCourseHeader
          courseCompletion={courseHeaderData.completion}
          course_title={courseHeaderData.title}
        />
        <div className="container">
          <div className="row g-0">
            <div className="col-lg-8 bg-light">
              {lessonLoading ? (
                <div className="lesson-loader d-flex align-items-center justify-content-center">
                  <Loader />
                </div>
              ) : (
                <ShowLesson
                  lesson={currentLessonData}
                  setReloadCourseContent={setReloadCourseContent}
                />
              )}
            </div>
            <div className="col-lg-4 border border-dark">
              {courseData == null ? (
                <Loader />
              ) : (
                <CourseContents
                  course_id={courseData.id}
                  changeLesson={setCurrentLessonIds}
                  reloadCouter={reloadCourseContent}
                />
              )}
            </div>
            <div className="col-12">
              <div className="container">
                {courseData == null ? (
                  <Loader />
                ) : (
                  <WatchCourseAdditionalDetails course_id={courseData.id} />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section></section>
    </main>
  );
}
