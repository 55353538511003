import {
  faArrowRight,
  faGraduationCap,
  faRupee,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AxiosClient from "../client/AxiosClient";
import { API_RECOMMENDED_COURSES, API_SECTION_COURSES } from "../ApiEndPoints";
import CourseCardTile from "../component/CourseCardTile";
import Loader from "../component/Loader";
import { IconFilledPrimaryBtnLink } from "../component/FilledBtn";

export default function CoursesGrid3() {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  const serverUrl = process.env.REACT_APP_SERVER_BASE_URL;

  useEffect(() => {
    AxiosClient.get(API_SECTION_COURSES, {
      params: {
        where_field_name: "show_in_home_tuition_course",
        where_field_value: 1,
      },
    })
      .then((response) => {
        const listData = response.data.data;
        console.log(listData);
        setCourses(listData);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <section className="">
      <div className="container">
        <div className="card p-2 py-3 p-md-5 border-0 bg-lightaccent bg-style-1 rounded-4">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="sec-title-sm">Home Tuition Courses</h2>
              <p className="sec-short-desc text-center mb-4">
                Quality education delivered to your doorstep for effective
                learning at home.
              </p>
            </div>

            <div className="col-md-12">
              <div className="row">
                {loading && (
                  <div className="col-12 d-flex align-items-center justify-content-center">
                    <Loader classes="text-primary" />
                  </div>
                )}

                {!loading &&
                  courses.map((row, index) => {
                    return (
                      <div
                        className="col-md-6 col-lg-3"
                        key={`course-row-with-action-${index}`}
                      >
                        <CourseCardTile
                          title={row.title}
                          category={row.category_name}
                          thumbnail={serverUrl + row.thumbnail}
                          price={row.price}
                          instructorName={row.instructor_name}
                          discountPrice={row.discount_price}
                          link={`/courses/${row.slug}`}
                        />
                      </div>
                    );
                  })}

                <div className="col-12 text-center mt-3">
                  <Link to="/courses" className="btn btn-primary theme-btn">
                    View All
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
